import React, { useContext } from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons'
import { Formik } from 'formik';
import * as Yup from "yup"
import { Button } from '.';
import { useMutation, useQuery } from 'react-query';
import { fetchExpert, updateExpert } from '../services';

const PackageCostForm = ({ expert, open, onClose, onSuccess, ...props }) => {

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth='md' fullWidth={true}>
            <Formik
                initialValues={{
                    cost: expert.cost || '',
                    // cost_egp: expert.cost_egp || '',
                    // cost_sar: expert.cost_sar || '',
                    // cost_aed: expert.cost_aed || '',
                    // cost_kwd: expert.cost_kwd || '',
                }}
                validationSchema={Yup.object().shape({
                    cost: Yup.number().min(1, "Please specify a positive number")
                        .required("Please specify a cost"),
                    // cost_egp: Yup.number().min(1, "Please specify a positive number").required("Please specify a cost"),
                    // cost_sar: Yup.number().min(1, "Please specify a positive number").required("Please specify a cost"),
                    // cost_aed: Yup.number().min(1, "Please specify a positive number").required("Please specify a cost"),
                    // cost_kwd: Yup.number().min(1, "Please specify a positive number").required("Please specify a cost"),
                })}
                onSubmit={values => {
                    onSuccess({ expert, ...values })
                    onClose()
                }}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue
                    } = props
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle id="form-dialog-title">
                                <div className='is-flex fdr aic jcsb'>
                                    Package Cost
                                    <IconButton onClick={() => onClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div className='mt-15'>
                                    <Grid container spacing={5}>
                                        <Grid item lg={8}>
                                            <div className='mb-10'>
                                                <h3 className='has-text-bold'>Cost in {expert.currency_code}</h3>
                                                <input
                                                    type="number"
                                                    name="cost"
                                                    defaultValue={values.cost}
                                                    className="stllr-input"
                                                    placeholder={`${expert.currency_code}`}
                                                    onChange={(e) => (setFieldValue('cost', e.target.value))}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.cost && touched.cost && (
                                                    <span className="v-error">{errors.cost}</span>
                                                )}
                                            </div>

                                            {/* <div className='mb-10'>
                                                <h3 className='has-text-bold'>Cost in EGP</h3>
                                                <input
                                                    type="number"
                                                    name="cost_egp"
                                                    defaultValue={values.cost_egp}
                                                    className="stllr-input"
                                                    placeholder="egp"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.cost_egp && touched.cost_egp && (
                                                    <span className="v-error">{errors.cost_egp}</span>
                                                )}
                                            </div>

                                            <div className='mb-10'>
                                                <h3 className='has-text-bold'>Cost in SAR</h3>
                                                <input
                                                    type="number"
                                                    name="cost_sar"
                                                    defaultValue={values.cost_sar}
                                                    className="stllr-input"
                                                    placeholder="sar"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.cost_sar && touched.cost_sar && (
                                                    <span className="v-error">{errors.cost_sar}</span>
                                                )}
                                            </div>

                                            <div className='mb-10'>
                                                <h3 className='has-text-bold'>Cost in AED</h3>
                                                <input
                                                    type="number"
                                                    name="cost_aed"
                                                    defaultValue={values.cost_aed}
                                                    className="stllr-input"
                                                    placeholder="aed"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.cost_aed && touched.cost_aed && (
                                                    <span className="v-error">{errors.cost_aed}</span>
                                                )}
                                            </div>

                                            <div className='mb-10'>
                                                <h3 className='has-text-bold'>Cost in KWD</h3>
                                                <input
                                                    type="number"
                                                    name="cost_kwd"
                                                    defaultValue={values.cost_kwd}
                                                    className="stllr-input"
                                                    placeholder="kwd"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.cost_kwd && touched.cost_kwd && (
                                                    <span className="v-error">{errors.cost_kwd}</span>
                                                )}
                                            </div> */}
                                        </Grid>
                                    </Grid>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    text='Save'
                                    type='submit'
                                    className='primary btn-lg mt-20'
                                />
                            </DialogActions>
                        </form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export { PackageCostForm }