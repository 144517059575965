export const Button = ({...props}) => {
    return (
        <button
            {...props}
            className={`${props.className} stllr-btn`}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type}
        >
            {props.text}
        </button>
    )
}