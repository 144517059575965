import React from "react"
import { Container } from "material-ui-core"
import { Button } from "../components/button"
import { useHistory } from "react-router-dom"

function AddExpert() {
    const history = useHistory()

    return (
        <div className="content-wrapper">
            <h2>&nbsp;</h2>
            <Container max={"lg"}>
                <div className="content-box">
                    <div className="is-flex aic jcsb">
                        <h1 className="mb-35">Add new expert</h1>

                        <Button
                            text="ADD"
                            className="primary btn-md"
                            onClick={() => {
                                history.push("/add-expert")
                            }}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AddExpert