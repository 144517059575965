import React, { useState, useMemo } from "react"
import * as Yup from "yup"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "react-query"
import { Formik } from "formik"
import { Container, Grid, RadioGroup, FormControlLabel, Radio } from "@material-ui/core"
import { fetchPulses, fetchProjects, createPulse, fetchUser } from "../services"
import 'react-day-picker/lib/style.css';
import Select from "react-select"
import TableContainer from "../components/TableContainer"

function Pulses() {
    const { projectId } = useParams()
    const [months, setMonths] = useState([])

    const columns = useMemo(() => [
        {
            accessor: `month`,
            Header: `#Pulse`,
            Filter: false
        },
        {
            accessor: `q1`,
            Header: `Company Name`,
            Filter: false
        },
        {
            accessor: `q2`,
            Header: `Quality Satisfaction`,
            Filter: false
        },
        {
            accessor: `q3`,
            Header: `Communication Satisfaction`,
            Filter: false
        },
        {
            accessor: `q4`,
            Header: `Experts' response rate`,
            Filter: false
        },
        {
            accessor: `q5`,
            Header: `Are deadlines met?`,
            Filter: false
        },
        {
            accessor: `q6`,
            Header: `Project change`,
            Filter: false
        },
        {
            accessor: `q7`,
            Header: `Will be a client?`,
            Filter: false
        },
        {
            accessor: `q8`,
            Header: `Suggest Stllr?`,
            Filter: false
        },
    ]);

    const { data: pulses, isLoading: pulsesLoading } = useQuery("pulses", () => fetchPulses(projectId), {
        select: (data) => data.pulses
    })


    const { data: project, isLoading: projectLoading } = useQuery("project", () => fetchProjects(projectId), {
        refetchOnWindowFocus: false,
        select: (data) => data.projects[0],
        onSuccess: (data) => {
            const arr = []
            for (var i = 1; i <= data.duration * 2; i++) {
                console.log(i)
                arr.push({ value: `${i}`, label: `${i}` })
            }
            setMonths(arr)
        }
    })


    // const { data: user } = useQuery("user", fetchUser, {
    //     select: (data) => data.data,
    //     refetchOnWindowFocus: false,
    // })

    const mutation = useMutation(newPulse => createPulse(newPulse, projectId), {
        onSuccess: () => {
            alert("Pulse successfully added!")
            window.location.href = `${process.env.REACT_APP_STLLR_ADMIN_URL}pulses/${projectId}`
        },
        onError: error => {
            alert(error.data?.message)
        },
        refetchOnWindowFocus: false,
    })


    if (pulsesLoading || projectLoading) return <div>Loading...</div>

    return (
        <div className="content-wrapper mb-55">

            <Container spacing={1}>
                <Grid item lg={12}>
                    <Formik
                        initialValues={{ month: "", q1: "", q2: "", q3: "", q4: "", q5: "", q6: "", q7: "", q8: "" }}
                        validationSchema={Yup.object().shape({
                            // month: Yup.array().min(1, "Required"),
                            month: Yup.string().required("Required"),
                            q1: Yup.string().required("Required"),
                            q2: Yup.string().required("Required"),
                            q3: Yup.string().required("Required"),
                            q4: Yup.string().required("Required"),
                            q5: Yup.string().required("Required"),
                            q6: Yup.string().required("Required"),
                            q7: Yup.string().required("Required"),
                            q8: Yup.string().required("Required")
                        })}
                        onSubmit={(values, { setSubmitting, errors }) => {
                            setSubmitting(false)
                            mutation.mutate({ ...values })
                        }}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    touched,
                                    setFieldValue,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props

                                return (
                                    <form className="is-flex jcsb fdc" onSubmit={handleSubmit}>

                                        <div className="is-flex aic mt-0 mb-10">
                                            <h4 className="mt-15 mr-15 dimmed">PULSES</h4>
                                        </div>

                                        <div>

                                            <div className="group">
                                                <label htmlFor="month">Pulse</label>
                                                <div style={{ width: "49%" }}>
                                                    <Select
                                                        name="month"
                                                        // value={values.month}
                                                        value={months.find(b => b.value === values.month)}
                                                        options={months}
                                                        onChange={val => setFieldValue('month', val.value)}
                                                        classNamePrefix="select"
                                                    />
                                                </div>
                                                {errors.month && touched.month && (
                                                    <span className="v-error">{errors.month}</span>
                                                )}
                                            </div>
                                            <div className="group">
                                                <Grid container spacing={2}>
                                                    <Grid item lg={6}>
                                                        <label htmlFor="name">Company name</label>
                                                        <input type="text" name="q1" onChange={handleChange} className="stllr-input" placeholder="Your answer" />
                                                        {errors.q1 && touched.q1 ? (
                                                            <span className="v-error">{errors.q1}</span>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="group">
                                                <label htmlFor="quality">How satisfied are you with the quality of work?</label>
                                                <RadioGroup
                                                    onChange={(e) => setFieldValue("q2", e.target.value)}
                                                    name="q2"
                                                    value={values.q2}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="1"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="2"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio />}
                                                        label="3"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio />}
                                                        label="4"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio />}
                                                        label="5"
                                                    />
                                                    <FormControlLabel
                                                        value="6"
                                                        control={<Radio />}
                                                        label="6"
                                                    />
                                                    <FormControlLabel
                                                        value="7"
                                                        control={<Radio />}
                                                        label="7"
                                                    />
                                                    <FormControlLabel
                                                        value="8"
                                                        control={<Radio />}
                                                        label="8"
                                                    />
                                                    <FormControlLabel
                                                        value="9"
                                                        control={<Radio />}
                                                        label="9"
                                                    />
                                                    <FormControlLabel
                                                        value="10"
                                                        control={<Radio />}
                                                        label="10"
                                                    />
                                                </RadioGroup>
                                                {errors.q2 && touched.q2 && (
                                                    <span className="v-error">{errors.q2}</span>
                                                )}
                                            </div>

                                            <div className="group">
                                                <label htmlFor="name">How satisfied are you with the experts' communication skills?</label>
                                                <RadioGroup
                                                    onChange={(e) => setFieldValue("q3", e.target.value)}
                                                    name="q3"
                                                    value={values.q3}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="1"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="2"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio />}
                                                        label="3"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio />}
                                                        label="4"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio />}
                                                        label="5"
                                                    />
                                                    <FormControlLabel
                                                        value="6"
                                                        control={<Radio />}
                                                        label="6"
                                                    />
                                                    <FormControlLabel
                                                        value="7"
                                                        control={<Radio />}
                                                        label="7"
                                                    />
                                                    <FormControlLabel
                                                        value="8"
                                                        control={<Radio />}
                                                        label="8"
                                                    />
                                                    <FormControlLabel
                                                        value="9"
                                                        control={<Radio />}
                                                        label="9"
                                                    />
                                                    <FormControlLabel
                                                        value="10"
                                                        control={<Radio />}
                                                        label="10"
                                                    />
                                                </RadioGroup>
                                                {errors.q3 && touched.q3 && (
                                                    <span className="v-error">{errors.q3}</span>
                                                )}
                                            </div>

                                            <div className="group">
                                                <label htmlFor="name">How satisfied are you with the experts' response rate?</label>
                                                <RadioGroup
                                                    onChange={(e) => setFieldValue("q4", e.target.value)}
                                                    name="q4"
                                                    value={values.q4}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="1"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="2"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio />}
                                                        label="3"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio />}
                                                        label="4"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio />}
                                                        label="5"
                                                    />
                                                    <FormControlLabel
                                                        value="6"
                                                        control={<Radio />}
                                                        label="6"
                                                    />
                                                    <FormControlLabel
                                                        value="7"
                                                        control={<Radio />}
                                                        label="7"
                                                    />
                                                    <FormControlLabel
                                                        value="8"
                                                        control={<Radio />}
                                                        label="8"
                                                    />
                                                    <FormControlLabel
                                                        value="9"
                                                        control={<Radio />}
                                                        label="9"
                                                    />
                                                    <FormControlLabel
                                                        value="10"
                                                        control={<Radio />}
                                                        label="10"
                                                    />
                                                </RadioGroup>
                                                {errors.q4 && touched.q4 && (
                                                    <span className="v-error">{errors.q4}</span>
                                                )}
                                            </div>

                                            <div className="group">
                                                <label htmlFor="name">Are deadlines being met?</label>
                                                <RadioGroup
                                                    onChange={(e) => setFieldValue("q5", e.target.value)}
                                                    name="q5"
                                                    value={values.q5}
                                                >
                                                    <FormControlLabel
                                                        value="yes"
                                                        control={<Radio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        value="no"
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                    <FormControlLabel
                                                        value="sometimes"
                                                        control={<Radio />}
                                                        label="Sometimes"
                                                    />
                                                </RadioGroup>
                                                {errors.q5 && touched.q5 && (
                                                    <span className="v-error">{errors.q5}</span>
                                                )}
                                            </div>

                                            <div className="group">
                                                <Grid container spacing={2}>
                                                    <Grid item lg={6}>
                                                        <label htmlFor="expert_change">If you could change one thing about the experts, product or organization, what would it be?</label>
                                                        <input type="text" name="q6" onChange={handleChange} className="stllr-input" placeholder="Your answer" />
                                                        {errors.q6 && touched.q6 ? (
                                                            <span className="v-error">{errors.q6}</span>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="group">
                                                <label htmlFor="name">How likely are you to be a client 6 months from now?</label>
                                                <RadioGroup
                                                    onChange={(e) => setFieldValue("q7", e.target.value)}
                                                    name="q7"
                                                    value={values.q7}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="1"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="2"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio />}
                                                        label="3"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio />}
                                                        label="4"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio />}
                                                        label="5"
                                                    />
                                                    <FormControlLabel
                                                        value="6"
                                                        control={<Radio />}
                                                        label="6"
                                                    />
                                                    <FormControlLabel
                                                        value="7"
                                                        control={<Radio />}
                                                        label="7"
                                                    />
                                                    <FormControlLabel
                                                        value="8"
                                                        control={<Radio />}
                                                        label="8"
                                                    />
                                                    <FormControlLabel
                                                        value="9"
                                                        control={<Radio />}
                                                        label="9"
                                                    />
                                                    <FormControlLabel
                                                        value="10"
                                                        control={<Radio />}
                                                        label="10"
                                                    />
                                                </RadioGroup>
                                                {errors.q7 && touched.q7 && (
                                                    <span className="v-error">{errors.q7}</span>
                                                )}
                                            </div>

                                            <div className="group">
                                                <label htmlFor="name">How likely are you to suggest working with Stllr to your friend?</label>
                                                <RadioGroup
                                                    onChange={(e) => setFieldValue("q8", e.target.value)}
                                                    name="q8"
                                                    value={values.q8}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="1"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="2"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio />}
                                                        label="3"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio />}
                                                        label="4"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio />}
                                                        label="5"
                                                    />
                                                    <FormControlLabel
                                                        value="6"
                                                        control={<Radio />}
                                                        label="6"
                                                    />
                                                    <FormControlLabel
                                                        value="7"
                                                        control={<Radio />}
                                                        label="7"
                                                    />
                                                    <FormControlLabel
                                                        value="8"
                                                        control={<Radio />}
                                                        label="8"
                                                    />
                                                    <FormControlLabel
                                                        value="9"
                                                        control={<Radio />}
                                                        label="9"
                                                    />
                                                    <FormControlLabel
                                                        value="10"
                                                        control={<Radio />}
                                                        label="10"
                                                    />
                                                </RadioGroup>
                                                {errors.q8 && touched.q8 && (
                                                    <span className="v-error">{errors.q8}</span>
                                                )}
                                            </div>

                                            <input
                                                type="submit"
                                                className="stllr-btn primary btn-lg"
                                                // onClick={() => saveProject()}
                                                value="Submit"
                                            />
                                            {Object.keys(errors).length ? <span className="mt-10 v-error">Please check for wrong/missing fields</span> : ``}

                                            <div className="sep mb-18" />

                                            <TableContainer paging={null} columns={columns} hiddenColumns={[""]} data={pulses} hasFilters={false} />

                                        </div>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </Grid>
            </Container>
        </div>
    )
}

export default Pulses