import React from "react"
import { CustomModal } from "./modal"
import { Formik } from 'formik';
import * as Yup from "yup"
import moment from "moment";
import { Button } from "./button";
import { useMutation } from "react-query";
import { updateTransactionById } from "../services/expert";

function WithdrawalIssueForm({ transaction, onClose, onSuccess }) {
  const isOpen = Boolean(transaction)

  const { mutate, isLoading } = useMutation((data) => updateTransactionById({ id: transaction.id, ...data }), {
    onSuccess: () => {
      alert("Issue has been reported successfully!")
      onSuccess()
      onClose()
    },
    onError: e => {
      alert(e.data?.message)
    }
  })

  const handleOnSubmit = (values, { setSubmitting }) => {
    console.log(values)
    mutate({ issue: values.description })
    setSubmitting(false)
  }

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      className="commonCustomModal feedbackModal"
      body={transaction ? <Formik
        initialValues={{ issue: '' }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required('Please specify the issue in details')
        })}
        onSubmit={handleOnSubmit}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
          } = props
          return (
            <form onSubmit={handleSubmit}>
              <h2 className="mb-20">
                Report an issue for {transaction.expert_name}'s withdrawal
              </h2>
              <div className="is-flex fdr aic jcsb mb-50">
                <span>[{transaction.code}] <b>{transaction.amount} {transaction.currency_code}</b> - {moment(transaction.date_add).format('ll')}</span>
              </div>

              <div className='mb-10'>
                <h3 className='has-text-bold'>Details*</h3>
                <textarea
                  type="number"
                  name="description"
                  defaultValue={values.description}
                  className="stllr-input"
                  onChange={(e) => (setFieldValue('description', e.target.value))}
                  onBlur={handleBlur}
                />
                {errors.description && touched.description && (
                  <span className="v-error">{errors.description}</span>
                )}
              </div>

              <Button
                text='Submit'
                type='submit'
                className='primary btn-lg mt-20'
                disabled={isSubmitting || isLoading}
              />
            </form>
          )
        }}
      </Formik> : "Loading.."}
    />
  )
}

export { WithdrawalIssueForm }