import React, { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Collapse, Container, Grid, Paper, TableCell } from "@material-ui/core"
import {
    fetchProjectHistory,
    fetchProjects,
} from "../services"
import { Box, IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from "material-ui-core"
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import moment from "moment"
import ParseHtml from 'react-html-parser'

function ProjectHistory() {
    const { id: projectId } = useParams()

    const { data: project, isLoading } = useQuery("project", () => fetchProjects(projectId), {
        refetchOnWindowFocus: false,
        select: (data) => data.projects[0],
    })

    const { data: history, isHistoryLoading } = useQuery("history", () => fetchProjectHistory(projectId), {
        select: data => data.history,
    })

    if (isLoading || isHistoryLoading) return <div>Loading...</div>

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb">
                        <div className="is-flex aic">
                            <h2 className="mt-0 mb-5 mt-20">Project History</h2>
                            <h3 className="ml-10">"{project.name}"</h3>
                        </div>
                        <button onClick={() => window.location.href = `/edit-project/${projectId}`} className="stllr-btn btn-sm primary bordered">EDIT PROJECT</button>
                    </div>
                </Grid>
                <Grid item lg={12}>
                    <TableContainer className='mt-45 overflow-hidden' component={Paper} elevation={0}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>updater name</TableCell>
                                    <TableCell>updater role</TableCell>
                                    <TableCell>changes</TableCell>
                                    <TableCell>date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {history && history.map(record => (
                                    <Row record={record} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Container>
        </div>
    )
}

const Row = ({ record }) => {
    const [open, setOpen] = useState(false);
    const { updater_name, role_name, date_add, data } = record;
    const { services, milestones, ...project } = data;

    return (
    <React.Fragment>
        <TableRow onClick={() => setOpen(!open)}>
            <TableCell>
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? <UpIcon /> : <DownIcon />}
                </IconButton>
            </TableCell>
            <TableCell>{updater_name}</TableCell>
            <TableCell>{role_name}</TableCell>
            <TableCell>{/* Object.keys(updates).join(', ') */}</TableCell>
            <TableCell>{moment(date_add).format('lll')}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell className={`sub-cell ${!open && 'border-none'}`} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <Table size="small">
                            <TableBody>
                                {Object.keys(project).map(key => {
                                    if (key.includes('id')) return null
                                    return (
                                        <TableRow>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{project[key]}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <SubTable
                                    label={'Services'}
                                    titleKey='service_name'
                                    list={services}
                                />
                                <SubTable
                                    label={'Scope of work'}
                                    titleKey='title'
                                    list={milestones}
                                />
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>
    )
}

const SubTable = ({ label, titleKey, list }) => {
    const [open, setOpen] = useState(false);

    return !list?.length ? null : (
    <React.Fragment>
        <TableRow onClick={() => setOpen(!open)}>
            <TableCell colSpan={2}>
                <div className='is-flex fdr aic'>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <UpIcon /> : <DownIcon />}
                    </IconButton>
                    <div className='ml-10'>{label} ({list.length})</div>
                </div>
            </TableCell>
        </TableRow>
        <TableRow colSpan={2}>
            <TableCell className={`sub-cell ${!open && 'border-none'}`} colSpan={2}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <Table size="small">
                            <TableBody>
                                {list && list.map(record => (
                                    <SubRow record={record} title={record[titleKey]} />
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>
    )
}

const SubRow = ({ record, title }) => {
    const [open, setOpen] = useState(false);

    return (
    <React.Fragment>
        <TableRow onClick={() => setOpen(!open)}>
            <TableCell>
                <div className='is-flex fdr aic'>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <UpIcon /> : <DownIcon />}
                    </IconButton>
                    <div className='ml-10'>{title} - {record['expert_name']}</div>
                </div>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className={`sub-cell ${!open && 'border-none'}`}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <Table size="small">
                            <TableBody>
                                {Object.keys(record).map(key => {
                                    if (key.includes('id')) return null
                                    return (
                                        <TableRow>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{key === 'description' ? ParseHtml(record[key]) : record[key]}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>
    )
}

export default ProjectHistory