import React, { useEffect } from "react"
import { CustomModal } from "./modal"
import { useQuery } from "react-query"
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import {
    fetchBankAccount,
} from "../services"

function BankDetails({ expertId, transactionId, onClose }) {
    const isOpen = Boolean(transactionId || expertId)

    const { data, isLoading, error, refetch } = useQuery(['transaction-bank-detials', transactionId], () => fetchBankAccount({ transaction_id: transactionId, expert_id: expertId }), {
        select: data => data.bank_account,
        enabled: false,
    })

    useEffect(() => {
        if (transactionId || expertId) {
            refetch()
        }
    }, [transactionId, expertId])

    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={isLoading ? <div className="is-flex fdr aic jcc mt-50 mb-50">
                    <CircularProgress />
                </div>
                : error ? <div className="is-flex fdr aic jcc mt-50 mb-50">
                    Error occured
                </div>
                : !data ? null
                :<>
                <h2 className="fw6">
                    {transactionId ? "Withdrawal Bank Information" : "Expert Bank Information"}
                </h2>
                <TableContainer className='mt-45 overflow-hidden' component={Paper} elevation={0}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Account holder name</TableCell>
                                <TableCell>{data.holder_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Country</TableCell>
                                <TableCell>{data.country}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>City</TableCell>
                                <TableCell>{data.city}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Postal Code</TableCell>
                                <TableCell>{data.postal_code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Billing Address</TableCell>
                                <TableCell>{data.billing_address}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Account Number</TableCell>
                                <TableCell>{data.account_number}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>IBAN</TableCell>
                                <TableCell>{data.iban}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Swift Code</TableCell>
                                <TableCell>{data.swift_code}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>}
        />
    )
}

export { BankDetails }