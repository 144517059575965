import React, { useMemo, useState } from "react"
import { CircularProgress, Container, Grid } from "@material-ui/core"
import TableContainer from "../components/TableContainer"
import { useMutation, useQuery } from "react-query"
import Select from "react-select"
import DefaultFilter from "../components/DefaultFilter"
import SelectColumnFilter from "../components/SelectFilter"
import { useParams } from "react-router"
import { fetchProjectBillings, updateProjectBillingById } from "../services/project"
import moment from 'moment'

function ProjectPayments() {
    const { id: projectId } = useParams()

    const { data, isLoading, refetch } = useQuery(['requests'], () => fetchProjectBillings(projectId), {
        refetchOnWindowFocus: false
    })

    const { mutate: updateBill, isLoading: isUpdating } = useMutation(data => updateProjectBillingById(data), {
        onSuccess: () => {
            refetch()
            alert('successfully updated')
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const payment_status = [
        { label: 'Pending', value: process.env.REACT_APP_BILLING_PENDING_STATUS },
        { label: 'Received', value: process.env.REACT_APP_BILLING_RECEIVED_STATUS },
    ]

    const columns = useMemo(() => [
        {
            Header: 'Invoice number',
            accessor: `invoice`,
            disableFilters: true,
        },
        {
            Header: 'Amount',
            accessor: `amount`,
            disableFilters: true,
            Cell: ({ row }) => {
                const { amount } = row.original
                return '$' + amount
            }
        },
        {
            Header: 'Status',
            accessor: `status_name`,
            Filter: SelectColumnFilter,
            Cell: ({ row }) => {
                const { status_name, status_id, payment_method_id, id } = row.original
                return process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER == payment_method_id
                    && process.env.REACT_APP_BILLING_PENDING_STATUS == status_id ? (
                    <Select
                        defaultValue={payment_status.find(s => s.value == status_id)}
                        options={payment_status}
                        onChange={({ value }) => {
                            console.log('updatin to', value)
                            updateBill({ status_id: value, id })
                        }}
                        disabled={isUpdating}
                        isLoading={isUpdating}
                    />
                ) : (
                    status_name
                )
            }
        },
        {
            Header: 'Payment method',
            accessor: `payment_method_name`,
            disableFilters: true,
        },
        {
            Header: 'Received date',
            accessor: `date_received`,
            Cell: ({ row }) => {
                const { date_received } = row.original
                return date_received && moment(date_received).format('ll')
            },
            disableFilters: true,
        },
        {
            Header: 'Due date',
            accessor: `date_due`,
            Cell: ({ row }) => {
                const { date_due } = row.original
                return date_due && moment(date_due).format('ll')
            },
            disableFilters: true,
        },
    ])

    return (
        <Container spacing={1} maxWidth='lg'>
            <Grid container>
                {isLoading ? <CircularProgress /> : <Grid item lg={12}>
                    <TableContainer
                        columns={columns}
                        hiddenColumns={[]}
                        data={data.billings}
                        hasFilters={true}
                    />

                </Grid>}
            </Grid>
        </Container>
    )
}

export default ProjectPayments