import React from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom"

import Login from "./pages/Login"
import Home from "./pages/Home"
import { useAuth } from "./utils/Auth"
import Chat from "./pages/Chat";
import Brief from './pages/Brief';
import BriefBlocks from "./pages/copmany-brief"
import AddExpert from "./pages/add-expert"
import EditExpert from "./pages/edit-expert"
import ChatExpert from "./pages/chat-expert"
import ScoreExpert from "./pages/score-expert"
import ProjectHistory from "./pages/project-history"
import ProjectPayments from "./pages/project-payments"
import Projects from "./pages/all-projects"
import Companies from "./pages/Companies"
import Coupons from "./pages/all-coupons"
import Teams from "./pages/all-teams"
import Team from "./pages/Team"
import Groups from "./pages/all-groups"
import Pulses from "./pages/Pulses"
import AddProject from "./pages/new-project/index"
import AddUGCProject from "./pages/new-project/ugc"
import EditProject from "./pages/edit-project/index"
import EditUGCProject from "./pages/edit-project/ugc"
import UpcomingPayouts from "./pages/upcoming-payouts"
import WithdrawalRequests from "./pages/withdrawal-requests"
import ChatActivities from "./pages/chat-activities"
// import ViewProject from "./pages/view-project"
import { getToken } from "./utils"
import Header from "./components/Header"
import ExpertApplication from "./pages/expert-application"
import AccountantProjects from "./pages/AccountantProjects"
import MarketeerProjects from "./pages/MarketeerProjects"
import MarketeerExperts from "./pages/markteer-experts"
import UnscorredExperts from "./pages/UnscorredExperts"
import VetterScore from "./pages/VetterScore"
import VetterExpApplication from "./pages/VetterExpApplication"
import Warnings from "./pages/Warnings"
import Packages from "./pages/all-packages"
import AddTeam from "./pages/new-team"
import NewCoupon from "./pages/new-coupon"
import EditTeam from "./pages/edit-team"
import AddPackage from "./pages/new-package"
import EditPackage from "./pages/edit-package"
import PageActivities from "./pages/page-activities"
import AddProposal from "./pages/new-proposal"
import DemoRequests from "./pages/demo-requests"
import Ugc from "./pages/Ugc"
import ChatAdmin from "./pages/ChatAdmin"
import ProjectBrief from "./pages/project-brief"
import Subscriptions from "./pages/all-subscriptions"
import CompanyDrafts from "./pages/company-drafts"

const ProtectedRoute = ({component: Component, ...rest}) => {
    const isAuth = getToken()

    return (
        <Route
            {...rest}
            render={(props) => (
                isAuth ? <Component {...props} /> : <Redirect to="/login" />
            )}
        />
    )
}

const UnprotectedRoute = ({component: Component, ...rest}) => {
    const isAuth = getToken()

    return (
        <Route
            {...rest}
            render={(props) => (
                !isAuth ? <Component {...props} /> : <Redirect to="/" />
            )}
        />
    )
}

export const Routes = () => {
    const { auth } = useAuth()
    return (
        <Router>
            <div>
                {auth && <Header />}
                <Switch>
                    <ProtectedRoute path="/add-expert" component={AddExpert} />
                    <ProtectedRoute path="/edit-expert/:id" component={EditExpert} />
                    <ProtectedRoute path="/chat-expert/:id?" component={ChatExpert} />
                    <ProtectedRoute path="/score-expert/:id" component={ScoreExpert} />
                    <ProtectedRoute path="/company-brief/:companyId/brief/:blockId" component={Brief} />
                    <ProtectedRoute path="/company-brief/:companyId" component={BriefBlocks} />
                    <ProtectedRoute path="/project-brief/:projectId" component={ProjectBrief} />
                    <ProtectedRoute path="/project-history/:id" component={ProjectHistory} />
                    <ProtectedRoute path="/project-payments/:id" component={ProjectPayments} />
                    <ProtectedRoute path="/acc-projects" component={AccountantProjects} />
                    <ProtectedRoute path="/marketeer-projects" component={MarketeerProjects} />
                    <ProtectedRoute path="/marketeer-experts" component={MarketeerExperts} />
                    <ProtectedRoute path="/all-projects" component={Projects} />
                    <ProtectedRoute path="/all-companies" component={Companies} />
                    <ProtectedRoute path="/all-coupons" component={Coupons} />
                    <ProtectedRoute path="/all-subscriptions" component={Subscriptions} />
                    <ProtectedRoute path="/all-teams" component={Teams} />
                    <ProtectedRoute path="/team/:id" component={Team} />
                    <ProtectedRoute path="/all-groups" component={Groups} />
                    <ProtectedRoute path="/new-project/ugc" component={AddUGCProject} />
                    <ProtectedRoute path="/new-project" component={AddProject} />
                    <ProtectedRoute path="/new-proposal" component={AddProposal} />
                    <ProtectedRoute path="/upcoming-payouts" component={UpcomingPayouts} />
                    <ProtectedRoute path="/withdrawal-requests" component={WithdrawalRequests} />
                    <ProtectedRoute path="/pulses/:projectId" component={Pulses} />
                    <ProtectedRoute path="/edit-project/:projectId/ugc" component={EditUGCProject} />
                    <ProtectedRoute path="/edit-project/:projectId" component={EditProject} />
                    {/* <ProtectedRoute path="/view-project/:projectId" component={ViewProject} /> */}
                    <UnprotectedRoute path="/login" component={Login} />
                    <ProtectedRoute exact path="/" component={Ugc} />
                    <ProtectedRoute exact path="/chats/:chatId?" component={Chat} />
                    <ProtectedRoute exact path="/chats-admin/:chatId?" component={ChatAdmin} />
                    <ProtectedRoute path="/activities" component={ChatActivities} />
                    <ProtectedRoute path="/page-activities" component={PageActivities} />
                    <ProtectedRoute path="/demo-requests" component={DemoRequests} />
                    <ProtectedRoute path="/all-experts" component={Home} />
                    <ProtectedRoute path="/application/:id" component={ExpertApplication} />
                    <ProtectedRoute path="/unscorred-experts" component={UnscorredExperts} />
                    <ProtectedRoute path="/vetter-score-expert/:id" component={VetterScore} />
                    <ProtectedRoute path="/vetter-application/:id" component={VetterExpApplication} />
                    <ProtectedRoute path="/warnings/:id" component={Warnings} />
                    <ProtectedRoute path="/new-team" component={AddTeam} />
                    <ProtectedRoute path="/new-coupon" component={NewCoupon} />
                    <ProtectedRoute path="/edit-team/:id" component={EditTeam} />
                    <ProtectedRoute path="/all-packages" component={Packages} />
                    <ProtectedRoute path="/new-package" component={AddPackage} />
                    <ProtectedRoute path="/edit-package/:id" component={EditPackage} />
                    <ProtectedRoute path="/drafts/:id" component={CompanyDrafts} />
                    {/* <ProtectedRoute exact path="/summary/:name?/:id?" component={Brief} /> */}
                </Switch>
            </div>
        </Router>
    )
}