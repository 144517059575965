import React from "react"
import { useQuery, useMutation } from "react-query"
import {
    Grid,
    Container
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import {
    fetchUnscorredExperts,
} from "../services"
import { Formik } from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';


function VetterExpApplication() {
    const { id } = useParams()

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { data: expert, isLoading } = useQuery("expert", () => fetchUnscorredExperts(id), {
        select: data => data.experts,
        refetchOnWindowFocus: false,
    })

    if (isLoading) return <div>Loading...</div>

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb">
                        <h2 className="mt-0 mb-30 mt-20">Expert Application: {expert[0].name}</h2>
                    </div>

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <h2>Expertise</h2>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Portofolio Link:</h3>
                                    <h4>{expert[0].portfolio_link}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>LinkedIn profile:</h3>
                                    <h4>{expert[0].linkedin_url}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Campaign type(s) expertise:</h3>
                                    <h4>{expert[0].expertise.map(e => (<div className="service-chip">{e.service_name}</div>))}</h4>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}><h2>Tools</h2></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Mastered Tools:</h3>
                                    <h4>{expert[0].tools.map(t => (<div className="service-chip">{t.name}</div>))}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Industries:</h3>
                                    <h4>{expert[0].industries.map(i => (<div className="service-chip">{i.name}</div>))}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Industry focus:</h3>
                                    <h4>{expert[0].sectors.map(s => (<div className="service-chip">{s.name}</div>))}</h4>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <h2>Region</h2>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Languages:</h3>
                                    <h4>{expert[0].languages.map(l => (<div className="service-chip">{l.name}</div>))}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Countries:</h3>
                                    <h4>{expert[0].countries.map(c => (<div className="service-chip">{c.name}</div>))}</h4>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}><h2>Pricing</h2></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Job status</h3>
                                    <h4>{expert[0].job_status}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Preferred project duration(s)</h3>
                                    <h4>{expert[0].hiring_availability}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Average hourly rate</h3>
                                    <h4>${expert[0].hourly_rate}</h4>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}><h2>Resume</h2></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>

                                {expert[0].resume.map(r => (
                                    <>
                                        <div className="mb-30">
                                            <h5 className="boxSmallHeading">{r.company_name}</h5>
                                            <h5 className="subSmallText">{moment(r.start_year).format("ll")} - {r.end_year ? moment(r.end_year).format("ll") : "Till Now"}</h5>
                                            <h5 className="subSmallText">{r.role_title}</h5>
                                        </div>

                                    </>
                                ))}

                                <div className="mb-20">
                                    <h3>Portofolio Link:</h3>
                                    <h4>{expert[0].portfolio_link}</h4>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Container>
        </div>
    )
}

export default VetterExpApplication