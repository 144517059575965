import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_STLLR_API_URL

export const fetchAllCoupons = async () => {
  return request({
      url: `${BaseUrl}v1/coupons`,
  }, true)
}

export const createCoupon = async (data) => {
  return request({
      url: `${BaseUrl}v1/coupons`,
      method: 'POST',
      data,
  }, true)
}

export const updateCoupon = async (id, data) => {
  return request({
      url: `${BaseUrl}v1/coupons/${id}`,
      method: 'PUT',
      data,
  }, true)
}

export const deleteCoupon = async (id) => {
  return request({
      url: `${BaseUrl}v1/coupons/${id}`,
      method: 'DELETE',
  }, true)
}
