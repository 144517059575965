import React from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { FieldArray, Formik } from 'formik'
import * as Yup from "yup"
import Select from 'react-select';
import { Button } from './button';
import { Add } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { generateMilestones, generateProposalMilestones } from '../services';
import GenerateMilestonesForm from './GenerateMilestonesForm';

const MilestonesDialog = ({ open, onClose, onSubmit, data, readOnly = false }) => {
  const {  owner_id, duration } = data || {}

  const { mutate: generateNewMilestones, isLoading: isGenerating } = useMutation(data => generateProposalMilestones(data), {
    onError: error => alert(error.data?.message || "Cannot AI generate scope of work")
  })

  const handleGenerateMilestones = (values, { setFieldValue }) => {
    generateNewMilestones(
      {
        ...values,
        owner_id,
        duration,
      },
      {
        onSuccess: res => {
          if (res?.milestones) {
            console.log()
            setFieldValue('milestones', res.milestones)
          }
          else {
            alert('Error: Could not find scope of work')
          }
        }
      }
    )
  }

  const emptyMilestone = {
    title: '',
    description: '',
    start_date: '',
    end_date: '',
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth={true}>
      <DialogTitle>
        Auto generate scope of work
      </DialogTitle>
      <DialogContent>        
        <Formik
          initialValues={{ milestones: [] }}
          validationSchema={Yup.object().shape({
            milestones: Yup.array().of(Yup.object().shape({
              title: Yup.string().required("Please enter a valid scope of work title"),
              description: Yup.string().required("Please enter a valid scope of work description"),
            }))
          })}
        >
          {
            props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit
              } = props

              return (<form onSubmit={handleSubmit}>
                <GenerateMilestonesForm onSubmit={values => handleGenerateMilestones(values, { setFieldValue })} isSubmitting={isGenerating} />

                <FieldArray
                  name={`milestones`}
                  render={milestonesHelper => (
                    <div>
                      {values.milestones?.length === 0 ? null : values.milestones.map((milestone, milestoneIndex) => <>
                        <div className='sep' />
                        <Grid container spacing={1}>
                          {readOnly ? null : <Grid item lg={12}>
                            <div className='is-flex jcfe'>
                              <a className='v-error' href="#" onClick={e => {
                                console.log(e)
                                e.preventDefault()
                                milestonesHelper.remove(milestoneIndex)
                              }}>Remove</a>
                            </div>
                          </Grid>}
                          <Grid item lg={7}>
                            <input
                              type="text"
                              name={`milestones.${milestoneIndex}.title`}
                              value={milestone.title}
                              className="stllr-input"
                              placeholder="Enter scope of work title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={readOnly}
                            />
                            {errors.milestones?.[milestoneIndex]?.title && touched.milestones?.[milestoneIndex]?.title && (
                              <span className="v-error">{errors.milestones?.[milestoneIndex]?.title}</span>
                            )}

                            <textarea
                              name={`milestones.${milestoneIndex}.description`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={milestone.description}
                              className='stllr-input mt-15'
                              placeholder='Enter scope of work description'
                              style={{ resize: 'none', minHeight: 96 }}
                              disabled={readOnly}
                            />
                            {errors.milestones?.[milestoneIndex]?.description && touched.milestones?.[milestoneIndex]?.description && (
                              <span className="v-error">{errors.milestones?.[milestoneIndex]?.description}</span>
                            )}
                          </Grid>
                          <Grid item lg={5}>
                            {/* <Select
                              name={`milestones.${milestoneIndex}.service_id`}
                              options={services}
                              onChange={({ value }) => setFieldValue(`milestones.${milestoneIndex}.service_id`, value)}
                              defaultValue={services?.find(s => s.value === Number(milestone.service_id)) || null}
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                control: base => ({ ...base, height: 42, minHeight: 40 }),
                              }}
                              placeholder='Select scope of work service'
                              isDisabled={readOnly}
                            /> */}

                            <TextField
                              type="date"
                              size="small"
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={milestone.start_date}
                              // label={'Start Date'}
                              name={`milestones.${milestoneIndex}.start_date`}
                              fullWidth
                              style={{ marginTop: 15 }}
                              disabled={readOnly}
                            />
                            <TextField
                              type="date"
                              size='small'
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={milestone.end_date}
                              // label={'End Date'}
                              name={`milestones.${milestoneIndex}.end_date`}
                              fullWidth
                              style={{ marginTop: 15 }}
                              disabled={readOnly}
                            />
                          </Grid>
                        </Grid>
                      </>)}
                      <div className='sep' />
                      {readOnly ? null : <>
                        <Button
                          className="stllr-btn link btn-lg has-full-width fw6"
                          text={<div className='is-flex fdr'>
                            <Add style={{ color: "#181818" }} />
                            <div className='ml-10'>Add another scope of work</div>
                          </div>}
                          onClick={e => {
                            e.preventDefault()
                            console.log('add another scope of work')
                            milestonesHelper.push(emptyMilestone)
                          }}
                        />
                        <Button
                          type="submit"
                          className="stllr-btn primary btn-lg has-full-width fw6"
                          text={<div className='is-flex fdr'>
                            {/* <Add style={{ color: "#181818" }} /> */}
                            <div className='ml-10'>Submit scope of work</div>
                          </div>}
                          onClick={e => {
                            e.preventDefault()
                            console.log('submit scope of work')
                            onSubmit(values.milestones)
                            onClose()
                          }}
                        />
                      </>}
                    </div>
                  )}
                />
              </form>
              )
            }
          }
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default MilestonesDialog