import React, { useEffect, useState } from "react"
import { CircularProgress, Container, Grid, Divider } from "@material-ui/core"
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import { useMutation, useQuery } from "react-query"
import { fetchBriefBlocks } from "../services"
import { useParams } from "react-router"

const BriefBlocks = (props) => {
    const { companyId } = useParams()

    const { data: briefs, ...query } = useQuery(['brief-blocks'], () => fetchBriefBlocks(companyId), {
        select: res => res.briefs
    })

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb">
                        <div className="is-flex aic">
                            <h2 className="mt-0 mb-5 mt-20">Project owner's briefs</h2>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={12}>

                <Grid container spacing={3}>
                    {query.isLoading ? <CircularProgress /> : briefs?.map((brief, i) => (
                    <Grid item lg={4} style={{ marginBottom: 20 }}>
                        <a href={`/company-brief/${companyId}/brief/${brief.id}`}>
                        <div className="brief-block is-flex fdc jcfs" style={{ padding: 0 }}>
                            {i === 0 ? (
                            <div
                                className="is-flex fdc jcc"
                                style={{ padding: "5px 20px", backgroundColor: '#272AEE', flex: 1 }}
                            >
                                <div className="is-flex fdr jcfe">
                                <StatusChip isFilled={brief.is_filled} />
                                </div>
                                <div className="is-flex fdc aic jcsb">
                                <img className="icon horizontal-anim" src={process.env.REACT_APP_STLLR_STATIC_URL+brief.icon} />
                                <div className="title mt-20" style={{ color: '#FFFFFF' }}>
                                    {brief.title}
                                    <ArrowRightAlt style={{ transform: 'translate(4px, 6px)', fill: '#FFFFFF' }} />
                                </div>
                                </div>
                            </div>
                            ) : (
                            <div className="is-flex aifs jcsb fdr mb-5" style={{ padding: "20px 20px 5px" }}>
                                <div className="is-flex fdc aifs jcsb">
                                <img className="icon vertical-anim" src={process.env.REACT_APP_STLLR_STATIC_URL+brief.icon} />
                                <div className="title mt-15">
                                    {brief.title}
                                    <ArrowRightAlt style={{ transform: 'translate(4px, 6px)' }} />
                                </div>
                                </div>
                                <StatusChip isFilled={brief.is_filled} />
                            </div>
                            )}
                            <Divider />
                            {i === 0 ? (
                            <div className="is-flex fdr jcc aic" style={{ padding: 16 }}>
                                <span className="service-chip">Required To Start</span>
                            </div>
                            ) : (
                            <RequiredServices
                                style={{ padding: "0 20px 10px" }}
                                services={brief.services.map(s => s.name)}
                            />
                            )}
                        </div>
                        </a>
                    </Grid>
                    ))}
                </Grid>

                </Grid>
            </Container>
        </div>
    )
}


const StatusChip = ({ isFilled, ...props }) => {
    return (
        <span
            className="service-chip has-text-bold"
            style={{
                flexShrink: 0,
                backgroundColor: isFilled ? '#92F0D7' : '#FCDEC9',
                color: isFilled ? '#38763E' : '#C55619',
            }}
            {...props}
        >
            {isFilled ? 'Done' : 'In Progress'}
        </span>
    )
}

const RequiredServices = ({ services, ...props }) => {
    return (
        <div {...props}>
            <h5>Required for</h5>
            <div className="is-flex fdr fw">
                {services.map(service => (
                    <span className="service-chip">{service}</span>
                ))}
            </div>
        </div>
    )
}

export default BriefBlocks