import React, { useState } from 'react'
import { useMutation } from 'react-query'
import {
  initStripeSubscription,
  initStripeReplacePayment,
  initStripePayment,
  initStripBriefPayment,
} from '../services'
import { Button } from './index'

const RECEIPTS_EMAIL = 'receipts@stllr.network'

const PayButton = ({ project }) => {
  const [link, setLink] = useState()

  const { mutate: initOneTimePayment, error: oneTimePaymentError, isLoading: isInitializingOneTime } = useMutation(() => initStripePayment({
    product_title: project.name,
    project_id: project.id,
    tax_behavior: "exclusive",
    currency_id: project.currency_id,
    email: RECEIPTS_EMAIL
  }), {
    onSuccess: ({ data }) => {
      console.log({ url: data })
      setLink(data)
      navigator.clipboard.writeText(data)
    },
    onError: error => {
      alert(error.data?.message)
    }
  })

  const { mutate: initBriefPayment, error: briefPaymentError, isLoading: isInitializingBriefPayment } = useMutation(() => initStripBriefPayment({
    project_id: project.id,
    tax_behavior: "exclusive",
    currency_id: project.currency_id,
    email: RECEIPTS_EMAIL
  }), {
    onSuccess: ({ data }) => {
      console.log({ url: data })
      setLink(data)
      navigator.clipboard.writeText(data)
    },
    onError: error => {
      alert(error.data?.message)
    }
  })

  const { mutate: initPayment, error: paymentError, isLoading: isInitializing } = useMutation(() => initStripeSubscription({
    product_title: project.name,
    project_id: project.id,
    currency_id: project.currency_id,
    tax_behavior: "exclusive",
    email: RECEIPTS_EMAIL
  }), {
    onSuccess: ({ data }) => {
      console.log({ url: data })
      setLink(data)
      navigator.clipboard.writeText(data)
    },
    onError: error => {
      alert(error.data?.message)
    }
  })

  const { mutate: initReplacePayment } = useMutation(() => initStripeReplacePayment({
    product_title: project.name,
    project_id: project.id,
    tax_behavior: "exclusive",
    email: RECEIPTS_EMAIL
  }), {
    onSuccess: ({ data }) => {
      setLink(data)
      navigator.clipboard.writeText(data)
    }
  })

  const pay = () => {
    console.log('generate')
    setLink(null)
    if (project.has_videos) {
      initBriefPayment()
    }
    else if (project.fixed_price) {
      initOneTimePayment()
    }
    else if (project.cost_difference) {
      initReplacePayment();
    }
    else {
      initPayment();
    }
  }

  return project.is_bank_transfer ? null : (
    project.status_id == process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT ||
      project.status_id == process.env.REACT_APP_PROJECT_STATUS_PENDING_MILESTONES ? (
        <div className='is-flex fdc'>
          <Button
            onClick={pay}
            text={isInitializing || isInitializingOneTime || isInitializingBriefPayment ? 'Generating link...' : `Copy payment link`}
            className="stllr-btn btn-lg secondary bordered mb-5"
            disabled={isInitializing || isInitializingOneTime}
          />
          {link && <div style={{ background: '#ddd', maxWidth: 300, wordWrap: 'break-word', padding: 6 }}>{link}</div>}
        </div>
    ) : null
  )
}

export { PayButton }