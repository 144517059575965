import React from 'react';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Chip, TableFooter } from '@material-ui/core';
import { Close } from '@material-ui/icons'
import { Container } from 'material-ui-core';
import Select from "react-select"
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'

const TableContainerServerSide = ({
  columns,
  data,
  hiddenColumns,
  paging,
  notSticky = false,
  hasFilters,
  allowSorting,
  loading,
  pageCount: controlledPageCount,
  fetchData
}) => {
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0)
  const [currentFilters, setCurrentFilters] = React.useState({})
  const request = React.useRef('')
  const timer = React.useRef()
  const { getTableProps, headerGroups, rows, prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    setAllFilters,
    rows: filterResults,
    state: { pageIndex, pageSize, filters }, } = useTable({
      columns,
      data,
      paging,
      notSticky,
      disableSortBy: !allowSorting,
      pageCount: controlledPageCount,
      manualPagination: true,
      manualFilters: true,
      initialState: {
        pageIndex: currentPageIndex,
        hiddenColumns,
        // filters: hasFilters ? columns.map(c => ({ id: c.id || c.accessor, value: c.filterValue })).filter(e => e.value) : [],
      },
    },
      useFilters,
      useSortBy,
      usePagination,
    )

  const handleFetchData = (options) => {
    const reqStr = JSON.stringify(options)
    if (request.current !== reqStr) {
      request.current = reqStr
      fetchData(options)
    }
  }


  React.useEffect(() => {
    setCurrentPageIndex(pageIndex)
    if (fetchData) {
      handleFetchData({ pageIndex, pageSize, search: currentFilters })
    }
  }, [pageIndex, pageSize])

  React.useEffect(() => {
    setCurrentPageIndex(0)
    if (fetchData) {
      handleFetchData({ pageIndex: 0, pageSize, search: currentFilters })
    }
  }, [currentFilters])

  React.useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const params = filters.reduce((a, c) => ({ ...a, [c.id]: c.value }), {})
      if (fetchData) {
        setCurrentFilters(params)
      }
    }, 700);
  }, [filters])

  const pageSizeOptions = [
    { label: '10 per page', value: 10 },
    { label: '20 per page', value: 20 },
    { label: '30 per page', value: 30 }
  ]

  const [pageNumberOptions, setPageNumberOptions] = React.useState([])

  React.useEffect(() => {
    setPageNumberOptions(() => {
      const options = []
      for (let i = 0; i < pageCount; i++) {
        options.push({
          label: `Page ${i + 1}`,
          value: i,
        })
      }
      return options
    })
  }, [pageCount])

  return (
    <>
      <div className="is-flex mb-25 aic jcsb">
        {hasFilters ? <div className='is-flex fdc'>
          <div className='is-flex fdr aic mb-10 mt-20'>
            {filters.map(f => {
              const filterName = columns.find(c => c.accessor === f.id)?.Header || f.id
              return (
                <Chip
                  size="small"
                  className={`chip-blue button`}
                  style={{ margin: '0 10px 10px 0' }}
                  label={<div className='is-flex fdr aic'>
                    <div className='mr-5'>{filterName}: <span className='fw6'>{f.value}</span></div>
                    <Close style={{ width: 16, height: 16 }} />
                  </div>}
                  onClick={() => setAllFilters(filters.filter(e => e.id !== f.id))}
                />
              )
            })}
          </div>

          <div className="is-flex aic fw">
            {headerGroups.map(headerGroup => (
              <>
                {headerGroup.headers.map(column => (
                  <div className={column.canFilter ? 'mr-10' : ''}>{column.canFilter ? <h4 className="mb-5">{column.Header}</h4> : null} {column.canFilter ? column.render('Filter') : null}</div>
                ))}
              </>
            ))}
          </div>
        </div>
          :
          <div />
        }

        {paging && <div>
          {/* <h4 className="mb-5">&nbsp;</h4> */}
          <div className="is-flex aic">
            <button className="stllr-btn icon secondary mr-5" onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}

            <Select
              value={pageNumberOptions.find(e => e.value === pageIndex)}
              options={pageNumberOptions}
              onChange={({ value }) => gotoPage(value)}
              placeholder={"Page size"}
              className='mr-5'
              styles={{
                control: base => ({
                  ...base,
                  width: 150,
                  minWidth: 150,
                  height: 40,
                  minHeight: 40,
                })
              }}
            />

            <button className="stllr-btn icon secondary mr-5" onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}

            <Select
              value={pageSizeOptions.find(e => e.value === pageSize)}
              options={pageSizeOptions}
              onChange={({ value }) => setPageSize(value)}
              placeholder={"Page size"}
              styles={{
                control: base => ({
                  ...base,
                  width: 150,
                  minWidth: 150,
                  height: 40,
                  minHeight: 40,
                })
              }}
            />
          </div>
        </div>}
      </div>
      <MaUTable stickyHeader={!notSticky} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                column.showColumn == false ? null :
                  <TableCell {...column.getHeaderProps(column.isSortable ? column.getSortByToggleProps() : {})}>
                    <div className='is-flex fdr'>
                      <span style={{ userSelect: 'none' }}>
                        {column.render('Header')}
                      </span>
                      {/* Add a sort direction indicator */}
                      <span className='ml-20 is-flex'>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDropDown />
                            : <ArrowDropUp />
                          : null}
                      </span>
                    </div>
                  </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {loading ? <TableRow>
            <TableCell colSpan={columns?.length} align='center'>
              Loading..
            </TableCell>
          </TableRow> : page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    cell.column.showColumn == false ? null :
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>

        </TableFooter>
      </MaUTable>
    </>
  );
};

export default TableContainerServerSide;