import React, { useState, useMemo, useCallback } from "react"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router";
import { fetchDemoRequests, fetchPageActivities } from "../services"
import { Grid, Container, Avatar } from "@material-ui/core"
import TableContainerServerSide from "../components/TableContainerServerSide"
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import moment from "moment";
import TableContainer from "../components/TableContainer";

function DemoRequests() {
    const history = useHistory()
    const [total, setTotal] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [data, setData] = useState([])

    const columns = useMemo(() => [
        {
            accessor: 'first_name',
            Header: 'First Name',
        },
        {
            accessor: 'last_name',
            Header: 'Last Name',
        },
        {
            accessor: 'email',
            Header: 'Email',
        },
        {
            accessor: 'phone_number',
            Header: 'Phone Number',
        },
        {
            accessor: 'company_name',
            Header: 'Company Name',
        },
        {
            accessor: 'position',
            Header: 'Client Role',
        },
        {
            accessor: 'date_add',
            Header: 'Request Date',
            Cell: ({ row }) => {
                const { date_add } = row.original
                return moment(date_add).format('ll')
            },
        }
    ]);

  const { isLoading, refetch } = useQuery(['requests'], () => fetchDemoRequests(), {
        onSuccess: (data) => {
            console.log('ostoora', data)
            setData(data.requests || [])
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })

    return (
        <Container spacing={1} maxWidth='xl'>
            <Grid item lg={12}>
                <div className="is-flex aic jcsb">
                    <h2 className="mt-0 mb-5 mt-20">REST API demo requests (<i>{data.length}</i>)</h2>
                </div>
                <TableContainer
                    paging={true}
                    columns={columns}
                    hiddenColumns={[]}
                    data={data}
                    // hasFilters={true}
                />
            </Grid>
        </Container>
    )
}

export default DemoRequests