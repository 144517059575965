import React, { useState } from "react"
import { useQuery, useMutation } from "react-query"
import {
    Grid,
    Container
} from "@material-ui/core"
import Select from "react-select"
import { useParams } from "react-router-dom"
import {
    fetchAllExperts,
    updateExpert,
    submitWarning,
    fetchTiers
} from "../services"
import { Formik } from 'formik';
import * as Yup from "yup"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from "../components/button"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';


function Warnings() {
    const { id } = useParams()
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    },
        handleClose = () => {
            setOpen(false)
        }

    const { data: expert, isLoading, refetch } = useQuery("expert", () => fetchAllExperts(id), {
        select: data => data.experts,
        refetchOnWindowFocus: false,
    })

    const { mutate: addWarning, isLoading: isSubmittingWarning } = useMutation(data => submitWarning(data, id), {
        onSuccess: data => {
            if (data?.status) {
                alert("Warning sumbitted successfully")
                refetch()
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    if (isLoading || isSubmittingWarning) return <div>Loading...</div>

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb">
                        <h2 className="mb-20 mt-20">Expert Warnings</h2>
                    </div>

                    {expert[0].warnings && expert[0].warnings.length ?
                        <div className="mb-20">
                            {expert[0].warnings.map(e => (
                                // e.description
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    <ListItem>
                                        <ListItemText primary={`${e.description}`} secondary={`${moment(e.date_add).format('ll')}`} />
                                    </ListItem>
                                </List>
                            ))}


                        </div> : <div className="mb-20">EXPERT HAS NO WARNINGS</div>}

                    <Button onClick={handleClickOpen} className="stllr-btn primary btn-lg" text="Add warning" />

                    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                        <Formik
                            initialValues={{
                                description: ""
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false)
                                addWarning({ ...values, expert_email: expert[0].email, expert_name: expert[0].name })
                                handleClose()
                            }}
                            validationSchema={Yup.object().shape({
                                description: Yup.string().required("Please enter a valid scope of work description")
                            })}
                        >
                            {
                                props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        setFieldValue,
                                        handleBlur,
                                        handleSubmit
                                    } = props

                                    return (
                                        <form onSubmit={handleSubmit} style={{ width: "98%" }}>
                                            <DialogTitle id="form-dialog-title">Expert Warnings / Add New Warning</DialogTitle>
                                            <DialogContent>
                                                <label htmlFor="name">Description</label>
                                                <textarea
                                                    // disabled={user.email === process.env.REACT_APP_ADMIN_EMAIL ? false : true}
                                                    className="stllr-input"
                                                    style={{ resize: "none" }}
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button type="submit" className="stllr-btn primary" text="Submit" />
                                            </DialogActions>
                                        </form>
                                    )
                                }
                            }
                        </Formik>
                    </Dialog>
                </Grid>
            </Container>
        </div>
    )
}

export default Warnings