import { Container, Grid, CircularProgress } from "@material-ui/core"
import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Header, ChatHead } from "../components"
import { fetchBriefBlock, submitBriefBlock, updateBriefBlock } from "../services"
import BriefForm from "../components/BriefForm";

function Brief() {
    const { companyId, blockId } = useParams();

    const { data: brief, ...query } = useQuery("brief", () => fetchBriefBlock(blockId, companyId), {
        select: data => data.brief
    })

    const {mutate: submitBrief, isFetching: isSubmitting } = useMutation(answers => submitBriefBlock(blockId, answers, companyId), {
        onSuccess: () => {
            alert("Brief is submitted successfully")
            window.location.href = `${process.env.REACT_APP_STLLR_ADMIN_URL}company-brief/${companyId}`
        },
        onError: e => {
            alert(e.data?.message)
            console.log(e)
        }
    })

    const {mutate: updateBrief, isFetching: isUpdating } = useMutation(answers => updateBriefBlock(blockId, answers, companyId), {
        onSuccess: () => {
            alert("Brief is updated successfully")
            window.location.href = `${process.env.REACT_APP_STLLR_ADMIN_URL}company-brief/${companyId}`
        },
        onError: e => {
            alert(e.data?.message)
            console.log(e)
        }
    })

    const handleBriefFormSubmit = (answers) => {
        if (Object.keys(answers) < 1) {
            alert('The form is empty')
            return
        }

        if (brief.is_filled) {
            updateBrief(answers)
        }
        else {
            submitBrief(answers)
        }
    }

    if (query.isLoading) {
        return (
            <div className='is-flex aic jcc mt-45'>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb">
                        <div className="is-flex aic">
                            <h2 className="mt-0 mb-5 mt-20">Project owner's briefs</h2>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={12}>
                    <BriefForm
                        sections={brief.sections}
                        onSubmit={handleBriefFormSubmit}
                        isSubmitting={isSubmitting || isUpdating}
                    />
                </Grid>
            </Container>
        </div>

    )
}

export default Brief