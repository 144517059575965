import React, { useCallback, useMemo, useState } from "react"
import { Container, Grid, Chip } from "@material-ui/core"
import { useHistory } from "react-router";
import { DataGrid } from '@material-ui/data-grid';
import { useMutation, useQuery } from "react-query";
import { fetchAllExperts, fetchUnscorredExperts } from "../services"
import TableContainerServerSide from "../components/TableContainerServerSide"
import Select from "react-select"
import { Button } from "../components/button"
import { OpenInNew, StarOutline } from '@material-ui/icons';
import StarFilled from '@material-ui/icons/Star'
import { Rating } from '@mui/material'
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import moment from "moment";

function UnscorredExperts() {
  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [pageCount, setPageCount] = useState(1)
  const [experts, setExperts] = useState([])

  const columns = useMemo(() => [
    {
      Header: 'Name',
      id: 'name',
      accessor: ({ name, signin_method }) => {
        return `${name}${
          signin_method === 'google'
            ? ' (G)'
            : signin_method === 'linkedin'
              ? ' (L)'
              : ''
        }`
      },
      Filter: DefaultFilter,
      show: false
    },
    {
      Header: 'Email',
      accessor: `email`,
      Filter: DefaultFilter,
      show: false
    },
    {
      Header: 'Phone number',
      accessor: `phone_number`,
      Filter: DefaultFilter,
      show: false
    },
    {
      Header: 'Signed up on',
      id: 'date_add',
      accessor: ({ date_add }) => {
        return moment(date_add).format('LL')
      },
      Filter: DefaultFilter,
      FilterPlaceholder: 'YYYY-MM-DD',
      show: false
    },
    {
      Header: 'Services',
      id: 'services',
      accessor: ({ services }) => {
        return services.map(s => {
          return s.service_name
        })
      },
      Cell: ({ row }) => {
        let { services } = row.original

        return services.map(as => {
          return <div className="service-chip">{as.service_name}</div>
        })
      },
      Filter: SelectColumnFilter,
      FilterOptions: ['Account Management', 'Affiliate Marketing', 'Branding', 'Community Management', 'Content Marketing ', 'Conversion Rate Optimization', 'Data & Analytics ', 'Digital Design', 'Digital Design (Source files)', 'Full Stack Growth Marketing', 'Growth Hacking', 'Influencer Marketing', 'Lead Generation', 'Magento Expert', 'Motion Graphics & Animation', 'Paid Performance', 'Partnerships', 'Product Photography', 'SEO & Organic Growth', 'Shopify Expert', 'Social Media', 'Translation', 'UI & UX Design', 'UX & UI Writing', 'Video Marketing', 'Voice Marketing', 'Webflow Expert', 'WooCommerce Expert', 'Wordpress Expert'],
    },
    {
      Header: 'Industries',
      id: 'industries',
      accessor: ({ industries }) => {
        return industries.map(i => {
          return i.name
        })
      },
      Cell: ({ row }) => {
        let { industries } = row.original

        return industries.map(as => {
          return <div className="service-chip">{as.name}</div>
        })
      },
      Filter: DefaultFilter,
      minWidth: 700,
    },
    {
      Header: 'Sectors',
      id: 'sectors',
      accessor: ({ sectors }) => {
        return sectors.map(i => {
          return i.name
        })
      },
      Cell: ({ row }) => {
        let { sectors } = row.original

        return sectors.map(as => {
          return <div className="service-chip">{as.name}</div>
        })
      },
      Filter: DefaultFilter,
      minWidth: 700,
    },
    {
      Header: 'Country',
      id: 'country',
      accessor: 'country_name',
      Filter: DefaultFilter,
    },
    {
      Header: `Rating`,
      disableFilters: true,
      accessor: `avg_rating`,
      Cell: ({ row }) => {
        const { avg_rating, n_reviews } = row.original;
        return (
          <div className="is-flex fdr">
          <Rating
            // name="communication"
            defaultValue={avg_rating}
            icon={<StarFilled style={{ color: '#fcbb08' }} />}
            emptyIcon={<StarOutline style={{ color: '#f2b407' }} />}
            // onChange={(e, value) => setFieldValue(name, value)}
            // onChangeActive={(e, v) => setHover(v >= 0 ? v : null)}
            max={5}
            readOnly={true}
          />

          <div>({n_reviews})</div>
          </div>
        )
      }
    },
    {
      Header: `Price/hour`,
      Filter: DefaultFilter,
      accessor: `hourly_rate`,
      Cell: ({ row }) => {
        const { priceRange, hourly_rate } = row.original;
        return <span>${hourly_rate || priceRange}</span>
      }
    },
    {
      Header: "Tier",
      accessor: `tier_name`,
      Cell: ({ row }) => {
        const { tier_id, tier_name } = row.original
        return <Chip size={"small"} className={`chip${tier_id == 1 ? `-bronze` : tier_id == 2 ? `-silver` : `-gold`}`} label={tier_name} />
      },
      Filter: SelectColumnFilter,
      FilterOptions: ['Gold', 'Silver', 'Bronze'],
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      FilterOptions: ['Accepted', 'Rejected', 'Pending'],
      Cell: ({ row }) => {

        const values = row.original

        let statusChipColor = ``
        switch (Number(values.status_id)) {
          case Number(process.env.REACT_APP_EXPERT_PENDING_STATUS):
            statusChipColor = `-orange`
            break;

          case Number(process.env.REACT_APP_EXPERT_ACCEPTED_STATUS):
            statusChipColor = `-green`
            break;

          case Number(process.env.REACT_APP_EXPERT_REJECTED_STATUS):
            statusChipColor = `-red`
            break;

          case Number(process.env.REACT_APP_EXPERT_PENDING_DOCUMENTS_STATUS):
            statusChipColor = `-blue`
            break;

          default:
            statusChipColor = `-grey`
        }

        return <Chip size="small" className={`chip${statusChipColor}`} label={values.status} />
      }
    },
    {
      Header: 'Available',
      accessor: 'is_available_str',
      Filter: SelectColumnFilter,
      FilterOptions: ['Yes', 'No'],
      Cell: ({ cell }) => {
        return !cell.value ? 'N/A' : <Chip size="small" className={`chip-${cell.value.toLowerCase() === "yes" ? `green` : `red`}`} label={cell.value.toLowerCase() === "yes" ? `Yes` : `No`} />
      }
    },
    {
      Header: 'Type',
      accessor: 'type',
      Filter: SelectColumnFilter,
      FilterOptions: ['Expert', 'Vetter'],
    },
    {
      Header: "LinkedIn",
      disableFilters: true,
      accessor: ({ linkedin_url }) => {
        return <a href={`${linkedin_url}`} target="_blank" className="is-flex aic"><OpenInNew style={{ width: 15, marginRight: 5 }} /> Go</a>
      }
    }, {
      Header: "Actions",
      Filter: ``,
      Cell: ({ cell }) => {
        let { id } = cell.row.original
        return (
          <div className="is-flex aic">
            {/* <Button className="stllr-btn btn-sm primary bordered mr-5" text="DETAILS" /> */}
            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} href={`/vetter-score-expert/${id}`}>SCORE</a>
            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} href={`/vetter-application/${id}`}>VIEW APPLICATION</a>
          </div>
        )
      }
    }
  ])

  
  const { mutate: fetch, isLoading } = useMutation(params => fetchUnscorredExperts(null, params), {
    onSuccess: ({ experts }) => {
      setExperts(experts.data)
      setTotal(experts.total)
      if (experts.pageCount !== pageCount) {
        setPageCount(experts.pageCount)
      } 
    }
  })
  
  const handleRefetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      fetch({
        pageIndex,
        pageSize,
        search,
      })
    },
    []
  )

  return (
    // <div className="content-wrapper">
    <Container spacing={1} maxWidth='xl'>
      <Grid item lg={12}>
        {/* <div className="content-box"> */}
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Experts (<i>{total}</i>)</h2>
        </div>
        {/* <DataGrid
                              rows={experts}
                              columns={columns}
                              // autoPageSize
                              pageSize={25}
                              autoHeight
                          /> */}

        {/* <Container style={{ marginTop: 100 }}> */}
        <TableContainerServerSide
          name='experts'
          paging={true}
          columns={columns}
          hiddenColumns={[]}
          data={experts}
          hasFilters={true}
          loading={isLoading}
          pageCount={pageCount}
          fetchData={handleRefetchData}
        />
        {/* </Container> */}
        {/* </div> */}
      </Grid>
    </Container>
    // </div>
  )
}

export default UnscorredExperts