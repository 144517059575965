import React, { useEffect, useMemo } from "react"
import { CustomModal } from "./modal"
import { useQuery } from "react-query"
import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import {
    fetchExpertTransactions,
} from "../services"
import moment from 'moment'
import TableContainer from "../components/TableContainer"

function ExpertTransactions({ expertId, onClose }) {
    const isOpen = Boolean(expertId)

    const { data, isLoading, error, refetch } = useQuery(['transactions', expertId], () => fetchExpertTransactions(expertId), {
        select: data => data,
        enabled: false,
    })

    useEffect(() => {
        if (expertId) {
            refetch()
        }
    }, expertId)

    const transactionsColumns = useMemo(() => [
        {
            accessor: 'project_name',
            Header: 'Project',
        },
        {
            accessor: 'code',
            Header: 'Code',
        },
        {
            accessor: 'company_name',
            Header: 'Company',
        },
        {
            accessor: 'amount',
            Header: 'Amount',
            Cell: ({ row }) => {
                const { amount, currency_symbol } = row.original
                return currency_symbol + amount
            }
        },
        {
            accessor: 'date_add',
            Header: 'Date',
            Cell: ({ row }) => {
                const { date_add } = row.original
                return moment(date_add).format('ll')
            }
        }
    ]);

    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={isLoading ? <div className="is-flex fdr aic jcc mt-50 mb-50">
                <CircularProgress />
            </div>
                : error ? <div className="is-flex fdr aic jcc mt-50 mb-50">
                    Error occured
                </div>
                    : !data ? null
                        :
                        <div className="content-box mb-15">
                            <div className="mini-title mb-25">Transactions</div>
                            {console.log("DATA", data)}
                            {data && <TableContainer columns={transactionsColumns} data={data.transactions} hiddenColumns={[]} />}
                        </div>
                // <TableContainer className='mt-45 overflow-hidden' component={Paper} elevation={0}>
                //     <Table>
                //         <TableBody>
                //             <TableRow>
                //                 <TableCell>Account holder name</TableCell>
                //                 <TableCell>{data.holder_name}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>Country</TableCell>
                //                 <TableCell>{data.country}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>City</TableCell>
                //                 <TableCell>{data.city}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>Postal Code</TableCell>
                //                 <TableCell>{data.postal_code}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>Billing Address</TableCell>
                //                 <TableCell>{data.billing_address}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>Account Number</TableCell>
                //                 <TableCell>{data.account_number}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>IBAN</TableCell>
                //                 <TableCell>{data.iban}</TableCell>
                //             </TableRow>
                //             <TableRow>
                //                 <TableCell>Swift Code</TableCell>
                //                 <TableCell>{data.swift_code}</TableCell>
                //             </TableRow>
                //         </TableBody>
                //     </Table>
                // </TableContainer>
            }
        />
    )
}

export { ExpertTransactions }