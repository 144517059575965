import React, { useEffect, useState, useRef } from "react"
import * as Yup from "yup"
import { v4 as uuidv4 } from 'uuid'
import { useHistory, useLocation } from "react-router"
import { useQuery, QueryClient, useMutation } from "react-query"
import { Button, TeamMilestoneDialog, PackageCostForm } from "../components";
import { Formik, Field } from "formik"
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Avatar, IconButton, Divider, Dialog, DialogContent, Switch, TextField } from "@material-ui/core"
import { DataGrid } from '@material-ui/data-grid';
import { fetchServices, fetchExperts, fetchCustomers, createProject, fetchUser, fetchAllExperts, fetchTeams, fetchExpertsByEmail, createTeam, fetchExpert } from "../services"
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Select from "react-select"
import TableContainer from "../components/TableContainer"
import { Editor } from '@tinymce/tinymce-react'
import HtmlParser from 'react-html-parser';
import { Close as RemoveIcon } from "@material-ui/icons"
import qs from 'qs'
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete'
import Carousel from 'react-material-ui-carousel'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

function AddPackage() {
    const [experts, setExperts] = useState([])
    const [leaderOptions, setLeaderOptions] = useState([])
    const [email, setEmail] = useState('')
    const { search } = useLocation()
    const { new_expert, tab } = qs.parse(search, { ignoreQueryPrefix: true });
    const history = useHistory();
    const [services, setServices] = useState([])
    const [currentService, setCurrentService] = useState(null)
    const [openMilestoneForm, setOpenMilestoneForm] = useState(null)
    const [milestones, setMilestones] = useState([])
    const fileRef = useRef();
    const editorRef = useRef()
    const [open, setOpen] = useState(false)


    useEffect(() => {
        setLeaderOptions(() => experts.map(e => ({ label: e.name, value: e.id })))
        setServices(() => experts
            .map(e => e.services || [])
            .flat()
            .reduce((a, c) => a?.find(e => c && e?.service_id === c?.service_id) ? a : [...a, c], [])
            .map(s => ({
                label: s.service_name,
                value: s.service_id
            }))
        )
    }, [experts])

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            reject();
        };
    })

    const { data: expert } = useQuery(['expert'], () => fetchExpert(), {
        select: res => res.expert,
        onSuccess: expert => {
            handleAddExpert(expert)
            if (new_expert) {
                sendInvite(new_expert)
            }
        }
    })

    const { mutate: sendInvite, isLoading: isSending } = useMutation(data => fetchExpertsByEmail(data), {
        select: (data) => data.experts[0],
        onSuccess: (data) => {
            let expert = data.experts[0]
            if (expert) {
                handleAddExpert(expert)
            } else {
                alert("Expert doesn't exist")
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { mutate: buildTeam, isLoading: isSubmitting } = useMutation(data => createTeam(data), {
        onSuccess: (data) => {
            alert('Team created successfully')
            history.push(`/team/${data.team_id}`);
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { data: allServices, isLoading: isServicesLoading } = useQuery('allServices', () => fetchServices(), {
        select: (data) => data.services.map(e => ({ label: e.name, value: e.id }))
    })

    const constructMemberObject = expert => ({
        id: expert.id,
        name: expert.name,
        avatar: expert.avatar,
        tier: expert.tier_name,
        services: expert.services,
        email: expert.email,
        currency_id: expert.currency_id,
        currency_code: expert.currency_code,
    })

    const [durations] = useState(() => {
        let durationsArr = [];
        for (let i = 1; i <= 12; i++) {
            durationsArr.push({ label: i, value: i });
        }
        return durationsArr;
    })
    const otherInputRef = useRef({})

    const handleAddExpert = (expert) => {
        if (experts.find(e => e.email === expert.email)) {
            return
        }
        setExperts(experts => {
            experts.push(constructMemberObject(expert))
            leaderOptions.push({ label: expert.name, value: expert.id })
            return experts
        })
    }

    const handleUpdateHours = (expert, hours) => {
        setExperts(experts => experts.map(e => {
            if (e.email === expert.email) {
                e.hours = hours
            }
            return e
        }))
    }

    const handleUpdateCost = (data) => {
        setExperts(experts => experts.map(e => {
            if (e.email === data.expert.email) {
                e.cost = data.cost
                // e.cost_egp = data.cost_egp
                // e.cost_sar = data.cost_sar
                // e.cost_aed = data.cost_aed
                // e.cost_kwd = data.cost_kwd
            }
            return e
        }))
    }

    const handleRemoveExpert = (expert) => {
        setExperts(experts => experts.filter(e => e.email !== expert.email))
        setLeaderOptions(experts => experts.filter(e => e.value !== expert.id))
    }

    const handleOnInviteClick = (event) => {
        event.preventDefault()
        if (email) {
            sendInvite(email)
        }
    }

    const handleOnMilestoneSubmit = ({ title, description }) => {
        const newMilestone = {
            id: Math.random(),
            title,
            description,
            service_id: currentService.value,
            service_name: currentService.label,
        }

        setMilestones(milestones => [...milestones, newMilestone])
        setOpenMilestoneForm(false)
    }

    const removeMilestone = (milestoneId) => {
        setMilestones(milestones => milestones.filter(m => m.id !== milestoneId))
    }

    const getValidatedCovers = (files) => {
        return new Promise((resolve, reject) => {
            try {
                const covers = []
                const checkFile = (index) => {
                    if (index >= files.length) {
                        console.log(index, 'no more files to check')
                        return resolve(Array.from(covers))
                    }

                    console.log(files[index], 'checking')
                    covers[index] = files[index]
                    const img = document.createElement("img");
                    img.onload = function () {
                        if (img.width / img.height > 1.5) {
                            getBase64(covers[index])
                                .then(data => {
                                    covers[index].base64 = data;
                                    checkFile(index + 1)
                                })
                                .catch(e => {
                                    covers[index].error = true
                                    checkFile(index + 1)
                                });
                        } else {
                            covers[index].error = true
                            checkFile(index + 1)
                        }
                    }
                    img.onerror = function () {
                        covers[index].error = true
                        checkFile(index + 1)
                    }
                    img.src = URL.createObjectURL(covers[index])
                }
                checkFile(0)
            }
            catch (e) {
                reject(e)
            }
        })
    }

    return (
        <Container spacing={1} maxWidth='xl'>
            <div className="content-wrapper mb-55">
                <h3 className="mb-20 is-flex fw7">Add information about the package</h3>
                <Formik
                    initialValues={{
                        covers: [],
                        name: '',
                        duration: '',
                        days: 7,
                        currency_id: null,
                        units: 0,
                        description: '',
                        service_id: null,
                        info: '',
                        is_earnings_dispatched: 1,
                        leader_id: '',
                        is_package: 1
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            values.experts = experts
                            values.currency_id = experts[0].currency_id
                            values.milestones = milestones
                            const fd = new FormData();
                            values.covers.forEach(file => {
                                if (!file.error) {
                                    delete file.base64
                                    fd.append('cover', file)
                                }
                            })
                            fd.append('data', JSON.stringify(values));
                            setSubmitting(false)
                            if (experts.length < 1) {
                                alert("Minimum package should be of 1 member")
                            }
                            else {
                                buildTeam(fd)
                            }
                        }, 500)
                    }}
                    validate={values => {
                        const errors = {}
                        // if (experts.some(e => !e.hours || e.hours <= 0)) {
                        //     errors.experts = 'There are missing hours'
                        // }
                        if (experts.some(e => !e.cost || e.cost <= 0)) {
                            errors.experts = 'Cost is missing'
                        }
                        return errors
                    }}
                    validationSchema={Yup.object().shape({
                        covers: Yup.array()
                            // .min(1, "Please upload a at lease one cover photo")
                            .max(10, "Please upload a at max 10 cover photos"),
                        name: Yup.string()
                            .required("Please specify a name"),
                        // duration: Yup.string()
                        //     .required("Please specify a duration"),
                        days: Yup.number()
                            .required("Please specify days")
                            .max(30, 'Maximum is 30 days')
                            .min(1, 'Minimum is 1 day'),
                        units: Yup.number()
                            .required("Please specify number of deliverables")
                            .min(1, 'Minimum is 1 deliverable'),
                        description: Yup.string()
                            .required("Please add a description"),
                        is_earnings_dispatched: Yup.number(),
                        leader_id: Yup.string()
                            .required("Please select a team leader"),
                        service_id: Yup.mixed().required("Please specify a service")
                    })}
                >
                    {
                        props => {
                            const {
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                setFieldTouched,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-55" style={{ width: '50%' }}>
                                        {values.covers.length
                                            ? <>
                                                <div
                                                    style={{
                                                        backgroundColor: "#DCDDE0",
                                                        height: 300,
                                                        borderRadius: 8,
                                                        border: '1px solid #A9A9A9',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Carousel
                                                        height={300}
                                                        indicatorContainerProps={{
                                                            style: {
                                                                transform: 'TranslateY(-40px)',
                                                                position: 'absolute',
                                                                zIndex: 1,
                                                            }
                                                        }}
                                                        IndicatorIcon={values.covers.map((c, i) => (
                                                            <span className={`slider-indicator ${values.covers[i]?.error ? 'error' : null}`}>&#9632;</span>
                                                        ))}
                                                    >
                                                        {values.covers.map((cover, index) => (
                                                            <div
                                                                style={{
                                                                    backgroundImage: `url(${cover.base64 || cover.url})`,
                                                                    height: 300,
                                                                }}
                                                                className="carousel-item-img"
                                                            >
                                                                <Button
                                                                    text={`DELETE`}
                                                                    className="stllr-btn danger has-full-width has auto-hide"
                                                                    onClick={e => {
                                                                        e.preventDefault()
                                                                        setFieldValue('covers', values.covers.filter((_, i) => i !== index))
                                                                    }}
                                                                />
                                                                {cover.error && <div className='has-full-height is-flex fdr jcc aic'>
                                                                    <div className="has-text-centered">
                                                                        "{cover.name}" could not be loaded.<br />
                                                                        Error: {cover.error}
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                                <Button
                                                    text={`Upload more photo covers`}
                                                    className="stllr-btn primary has-full-width"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        fileRef.current.click()
                                                    }}
                                                /></>
                                            : <div
                                                className="is-flex fdc aic jcc"
                                                onClick={() => fileRef.current.click()}
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: "#DCDDE0",
                                                    height: 300,
                                                    borderRadius: 8,
                                                    border: '1px solid #A9A9A9',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <h3 className="has-text-centered">
                                                    Upload photo covers for package*
                                                </h3>
                                            </div>
                                        }
                                        <div className="mt-20">
                                            {values.covers.some(c => c.error) && (
                                                values.covers.filter(c => c.error).map(cover => (
                                                    <div className="v-warning">"{cover.name}" Could not be loaded</div>
                                                ))
                                            )}
                                            {errors.covers && touched.covers && (
                                                <span className="v-error">{errors.covers}</span>
                                            )}
                                        </div>
                                        <input
                                            ref={fileRef}
                                            type="file"
                                            name="cover"
                                            multiple="multiple"
                                            className="hidden"
                                            onChange={(event) => {
                                                const files = event.currentTarget.files;
                                                if (files?.length > 0) {
                                                    getValidatedCovers(files)
                                                        .then(covers => {
                                                            setFieldValue('covers', values.covers.concat(covers))
                                                        })
                                                        .catch(e => {
                                                            console.log(e)
                                                            alert('Someting went wrong')
                                                        })
                                                }
                                                setFieldTouched('covers', true)
                                            }}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Team Name* (example: launch new ecommerce website, grow your paid leads)</div>
                                            <input
                                                ref={otherInputRef}
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                className="stllr-input"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.name && touched.name && (
                                                <span className="v-error">{errors.name}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Payment Options*</div>
                                            <div className="is-flex fdr aic">
                                                <div className="mr-10">Dispatch Project’s Earnings</div>
                                                <Field
                                                    name='is_earnings_dispatched'
                                                    component={Switch}
                                                    color='primary'
                                                    value={true}
                                                    defaultChecked={values.is_earnings_dispatched}
                                                    onChange={e => setFieldValue('is_earnings_dispatched', Number(e.target.checked))}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {errors.is_earnings_dispatched && touched.is_earnings_dispatched && (
                                                <span className="v-error">{errors.is_earnings_dispatched}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Description*</div>
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINY_API_KEY}
                                                onInit={(evt, editor) => otherInputRef.current = editor}
                                                init={{
                                                    width: '100%',
                                                    height: 200,
                                                    menubar: false,
                                                    placeholder: 'Enter package description',
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount', 'textcolor'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' + ' forecolor backcolor' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                onEditorChange={value => setFieldValue('description', value)}
                                            />
                                            {/* <textarea
                                                ref={otherInputRef}
                                                type="text"
                                                name="description"
                                                value={values.description}
                                                className="stllr-input"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            /> */}
                                            {errors.description && touched.description && (
                                                <span className="v-error">{errors.description}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdc" style={{ width: "50%" }}>
                                        <div className="teams-title mb-10">Info</div>
                                        <h4 className="mb-20 is-flex dimmed">This field won't show to anyone, its for your own reference</h4>
                                        <Editor
                                            apiKey={process.env.REACT_APP_TINY_API_KEY}
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            init={{
                                                width: '100%',
                                                height: 200,
                                                menubar: false,
                                                placeholder: 'More info',
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                            onEditorChange={value => setFieldValue('info', value)}
                                        />
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Project duration (Months)*</div>
                                            <Select
                                                name="duration"
                                                options={durations}
                                                value={durations.find(b => b.value === values.duration)}
                                                onChange={val => setFieldValue('duration', val.value)}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                            {errors.duration && touched.duration && (
                                                <span className="v-error">{errors.duration}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Project duration (Days)*</div>
                                            <input
                                                name="days"
                                                type="number"
                                                value={values.days}
                                                onChange={handleChange}
                                                className="stllr-input"
                                            />
                                            {errors.days && touched.days && (
                                                <span className="v-error">{errors.days}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Number of deliverables*</div>
                                            <input
                                                name="units"
                                                type="number"
                                                value={values.units}
                                                onChange={handleChange}
                                                className="stllr-input"
                                            />
                                            {errors.units && touched.units && (
                                                <span className="v-error">{errors.units}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Package Service*</div>
                                            <Select
                                                name="service_id"
                                                value={allServices?.find(b => b.value === values.service_id)}
                                                options={allServices}
                                                onChange={val => setFieldValue('service_id', val.value)}
                                                classNamePrefix="select"
                                            />
                                            {errors.service_id && touched.service_id && (
                                                <span className="v-error">{errors.service_id}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex jcsb fdc" style={{ width: "50%" }}>
                                        <h3 className="mb-10 is-flex fw7">Members</h3>
                                        <div className="teams-title">Team Member Email</div>
                                        <div className="is-flex fdr">
                                            <input
                                                type="email"
                                                value={email}
                                                className="stllr-input mr-10"
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            <Button
                                                text="ADD"
                                                onClick={handleOnInviteClick}
                                                className="stllr-btn primary btn-lg"
                                                disabled={isSending}
                                            />
                                        </div>
                                        {errors.email && touched.email && (
                                            <span className="v-error">{errors.email}</span>
                                        )}
                                    </div>

                                    <Grid item lg={12}>
                                        <div style={{ padding: "0px 35px 16px 35px" }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Role</TableCell>
                                                        {/* <TableCell>Number of Hours</TableCell> */}
                                                        <TableCell>Cost</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {experts.map(e => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <div className='is-flex fdr aic'>
                                                                        <Avatar src={e.avatar} style={{ width: 30, height: 30 }} />
                                                                        <h3 style={{ flexShrink: 0 }} className="ml-10 mr-10">{e.name}</h3>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {e.tier ? <div className='is-flex fdr aic'>
                                                                        <div className={`tier-circle ${e.tier?.toLowerCase()}`}></div>
                                                                        <div style={{ marginLeft: 9 }}>{e.tier}</div>
                                                                    </div> : 'Pending'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {e.services ? e.services.map(service => (
                                                                        <span className="service-chip">{service.service_name}</span>
                                                                    )) : 'Pending'}
                                                                </TableCell>
                                                                {/* <TableCell>
                                                                <input
                                                                    type="number"
                                                                    placeholder="Hours"
                                                                    // value={e.hours || 0}
                                                                    className="stllr-input"
                                                                    onChange={event => handleUpdateHours(e, event.target.value)}
                                                                />
                                                            </TableCell> */}
                                                                <TableCell>
                                                                    {/* <input
                                                                    type="number"
                                                                    placeholder="Cost"
                                                                    // value={e.cost || 0}
                                                                    className="stllr-input"
                                                                    onChange={event => handleUpdateCost(e, event.target.value)}
                                                                /> */}

                                                                    <a style={{color: '#272AEE'}} onClick={() => setOpen(true)} className="is-flex aic has-max-width">Insert cost <OpenInNewRoundedIcon fontSize="small" className="ml-5" /></a>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        onClick={() => handleRemoveExpert(e)}
                                                                    >
                                                                        <RemoveIcon style={{ color: 'red' }} />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                            <PackageCostForm
                                                                expert={e}
                                                                open={open}
                                                                onClose={() => setOpen(false)}
                                                                onSuccess={(values) => handleUpdateCost(values)}
                                                            />
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        {errors.experts && (
                                            <span className="v-error">{errors.experts}</span>
                                        )}
                                        <div className="group" style={{ width: "30%" }}>
                                            <div className="teams-title mb-10">Team Leader</div>
                                            <Select
                                                name="duration"
                                                options={leaderOptions}
                                                value={experts.find(b => b.value === values.leader_id)}
                                                onChange={val => setFieldValue('leader_id', val.value)}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                            {errors.leader_id && touched.leader_id && (
                                                <span className="v-error">{errors.leader_id}</span>
                                            )}
                                        </div>
                                    </Grid>

                                    <div className="group is-flex jcsb fdc">
                                        <h3 className="mb-10 is-flex fw7">Deliverables</h3>
                                        <h5 className="mb-10 dimmed">Automatically add deliverables when you choose a service and edit them if needed </h5>
                                    </div>

                                    <div className="group is-flex fdr aic jcsb">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Service*</div>
                                            <Select
                                                options={services}
                                                value={services.find(s => s.value === currentService?.value)}
                                                onChange={val => setCurrentService(val)}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <button
                                            onClick={e => {
                                                e.preventDefault()
                                                if (!currentService) {
                                                    alert('You must select a service first')
                                                }
                                                else {
                                                    setOpenMilestoneForm(true)
                                                }
                                            }}
                                            className="stllr-btn link btn-lg"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <AddIcon style={{ fill: 'blue' }} className='mr-5' />
                                            Create a new deliverable
                                        </button>
                                    </div>

                                    <Grid item lg={12}>
                                        <Table>
                                            <TableBody>
                                                {milestones.length ? milestones.map(m => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className='is-flex fdr aic jcsb'>
                                                                <div style={{ width: '75%' }}>
                                                                    <h3 className="has-text-bold">{m.title}</h3>
                                                                    <p>{HtmlParser(m.description)}</p>
                                                                </div>
                                                                <div>
                                                                    <span className="service-chip">{m.service_name}</span>
                                                                </div>
                                                                <div>
                                                                    <IconButton onClick={() => removeMilestone(m.id)}>
                                                                        <DeleteIcon style={{ color: "#E02020" }} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )) : <div className="is-flex fdr aic jcc has-text-centered">
                                                    No deliverables added yet.
                                                </div>}
                                            </TableBody>
                                        </Table>
                                    </Grid>

                                    <div className="group is-flex aic jcfe fdr mt-45">
                                        <Button
                                            text="CREATE Package"
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="stllr-btn primary btn-lg"
                                        />
                                    </div>
                                </form>
                            )
                        }
                    }
                </Formik>
            </div>
            <TeamMilestoneDialog
                title="new deliverable"
                open={openMilestoneForm}
                onClose={() => setOpenMilestoneForm(false)}
                onSubmit={handleOnMilestoneSubmit}
            />
        </Container>
    )
}

export default AddPackage