import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from "react-query";
import {
  fetchAdminConversations,
  fetchProjectConversations,
  fetchProjects,
  toggleConversation
} from '../services';
import { ConversationsSidebar } from '../components/ConversationsSidebar';
import { Grid } from '@material-ui/core';
import { ChatBox } from "../components"
import { Container } from 'material-ui-core';
import slugify from 'slugify';
import { OfferDialogView } from '../components/OfferDialogView';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

const ChatAdmin = () => {
  // const { chatId } = useParams()
  const { chatId } = useParams();
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [currentChat, setCurrentChat] = useState()
  const [offerPayload, setOfferPayload] = useState(null)

  const { data: chats, isLoading: isChatsLoading, isError: chatsError, refetch: refetchChats } = useQuery(["chats", chatId], () => fetchAdminConversations(), {
    select: data => data.list,
    keepPreviousData: true,
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  const { mutate: toggleChat, isLoading: isToggling } = useMutation(({ chat_id, enabled }) => toggleConversation(chat_id, enabled), {
    onSuccess: () => {
      refetchChats()
    },
    onError: (e) => {
      alert(e.data?.message || 'Toggle failed!')
    }
  })

  React.useEffect(() => {
    if (chatId && chats?.length) {
      const chat = chats.find(ch => ch.uid === chatId)
      setCurrentChat(chat)
    }  else if (!chatId && chats?.length) {
      setCurrentChat(chats[0])
    }
  }, [chatId, chats])

  const handleOnConversationClicked = (chat) => {
    history.push(`/chats-admin/${chat.uid}`);
  }

  const handleChatToggle = (e) => {
    if (currentChat) {
      console.log({
        chat_id: currentChat?.id, 
        enabled: Boolean(e.target.checked)
      })
      toggleChat({
        chat_id: currentChat?.id, 
        enabled: Boolean(e.target.checked)
      })
    }
  }

  if (chatsError) return 'Error occured';

  if (isChatsLoading) return 'loading..';

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <Grid item lg={3}>
          <ConversationsSidebar
            search={search}
            searchPlaceholder={'Search by name'}
            onSearch={text => setSearch(text)}
            list={chats}
            activeProject={chatId || chats[0].uid}
            onConversationClicked={handleOnConversationClicked}
          />
        </Grid>

        <Grid item lg={9}>
          <div>
            <FormControlLabel 
              label="Disable/Enable (disable will hide conversation from creator's side)"
              control={
                <Switch
                  checked={currentChat?.is_enabled}
                  onChange={handleChatToggle}
                  color="primary"
                />
              } 
            />
            {isToggling ? 'toggling..' : null}
          </div>

          <div style={{ width: '100%', height: '86.5vh' }}>
            {currentChat && <ChatBox
              // projectId={!project.chat_id ? project.id : undefined}
              chatId={currentChat.id || chats[0].uid}
              userEmail={process.env.REACT_APP_ADMIN_EMAIL}
              members={currentChat.members}
              title={`${currentChat.name}`}
              actions={[]}
              onSendMessage={() => refetchChats()}
              onReceiveMessage={() => refetchChats()}
              disabled={!currentChat.is_enabled}
            />}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ChatAdmin;