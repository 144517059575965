import React, { useState, useMemo, useRef, useEffect } from "react"
import { useQuery, useMutation } from "react-query"
import { useParams, useHistory } from "react-router-dom"
import { Container, Grid } from "@material-ui/core"
import TableContainer from "../../components/TableContainer"
import {
  fetchUser,
  fetchProjects,
  updateProject,
  fetchExperts,
  fetchServices,
  fetchCustomers,
  fetchProjectServices,
  fetchProjectMilestones,
  deleteService,
  deleteMilestone,
  fetchAllExperts,
  fetchProjectStatus,
  pauseStripeRenewal,
  markAsFinished,
  fetchCurrency,
  releaseTransaction,
  fetchAdmins
} from "../../services"
import { Formik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { Button } from "../../components/button"
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import { Editor } from '@tinymce/tinymce-react'
import HtmlParser from "react-html-parser"
import { PayButton } from "../../components/PayButton"
import MilestonesDialog from "../../components/MilestonesDialog"
import { useAuth } from "../../utils/Auth"

function EditProject() {
  const history = useHistory()
  const { user } = useAuth()
  const editorRef = useRef(null)
  const formikRef = useRef(null)
  const [filteredServices, setFilteredServices] = useState([])
  const [refinedExperts, setRefinedExperts] = useState([])
  const [generateMilestones, setGenerateMilestones] = useState(null)

  const kStatus = [
    { value: 'Onboarding', label: 'Onboarding' },
    { value: 'Upselling', label: 'Upselling' },
    { value: 'Healthy', label: 'Healthy' },
    { value: 'At risk', label: 'At risk' }
  ]

  const servicesColumns = useMemo(() => [
    {
      Header: `Service`,
      accessor: `service_name`,
      disableFilters: true
    },
    {
      Header: `Expert`,
      accessor: `expert_name`,
      disableFilters: true
    },
    {
      Header: `Duration`,
      accessor: `duration`,
      disableFilters: true
    },
    {
      Header: `Hours`,
      // accessor: `hours`,
      Cell: ({ row }) => {
        const { hours } = row.original
        return `${Number(hours)?.toFixed(2)} Hour(s)`
      },
      disableFilters: true
    },
    {
      accessor: "cost",
      Header: "Cost",
      disableFilters: true,
      Cell: ({ row }) => {
        const { currency_symbol: symbol } = project
        let { cost } = row.original
        return `${symbol} ${Number(cost)?.toFixed(2)}`
      }
    },
    {
      // accessor: "cost",
      Header: "Payouts",
      disableFilters: true,
      Cell: ({ row }) => {
        const { currency_symbol: symbol } = project
        let { cost } = row.original
        return `${symbol} ${(cost * (1 - ((project.expert_commission || 0) / 100)))?.toFixed(2)}`
      }
    },
    {
      Header: `Total Cost`,
      accessor: `total`,
      disableFilters: true,
      Cell: ({ row }) => {
        const { currency_symbol: symbol } = project
        let { total } = row.original
        return `${symbol} ${Number(total)?.toFixed(2)}`
      }
    },
    {
      Header: `Actions`,
      Cell: ({ row }) => {
        const { project_id, id } = row.original
        return <button className="stllr-btn btn-sm danger" onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (window.confirm('Are you sure you want to perform this action? this action cannot be undone')) {
            // deleteService(id, project_id)
            // refetchProjectServices()
            setNewProjectServices(newProjectServices.filter(m => {
              return m.id != id
            }))
            setProjectExperts(e => e.filter((i, counter) => {
              return counter != row.id
            }))
          }
        }}>REMOVE</button>
      }
    }
  ])

  const milestonesColumns = useMemo(() => [
    {
      Header: `Title`,
      accessor: `title`,
      disableFilters: true
    },
    {
      Header: `Description`,
      accessor: `description`,
      disableFilters: true,
      Cell: ({ row }) => {
        return HtmlParser(row.original.description);
      }
    },
    {
      Header: `Expert`,
      accessor: `expert_name`,
      Cell: ({ row }) => {
        let { expert_id } = row.original

        // Get the service name equivalent to this service ID
        let expert = projectExperts.filter(ps => {
          return ps.value == expert_id
        })

        return (expert.length ? expert[0].label : "")
      },
      disableFilters: true
    },
    {
      Header: `Service`,
      accessor: `service_name`,
      disableFilters: true
    },
    {
      Header: `Starts on`,
      accessor: `start_date`,
      disableFilters: true
    },
    {
      Header: `Ends on`,
      accessor: `end_date`,
      disableFilters: true
    },
    {
      Header: `Actions`,
      Cell: ({ row }) => {
        const { id, project_id } = row.original
        return <button className="stllr-btn btn-sm danger" onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (window.confirm('Are you sure you want to perform this action? This action cannot be undone?')) {
            // deleteMilestone(id, project_id)
            // refetchProjectMilestones()
            setNewProjectMilestones(newProjectMilestones.filter(m => {
              return m.id != id
            }))
          }
        }}>REMOVE</button>
      },
      disableFilters: true
    }
  ])

  const unreleasedColumns = useMemo(() => [
    {
      Header: `Expert name`,
      accessor: `name`,
      disableFilters: true
    },
    {
      Header: `Amount`,
      accessor: `amount`,
      disableFilters: true
    },
    {
      Header: `Currency`,
      accessor: `code`,
      disableFilters: true
    },
    {
      Header: `Actions`,
      Cell: ({ row }) => {
        const { id } = row.original
        return <button className="stllr-btn btn-sm danger" onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (window.confirm('Are you sure you want to perform this action? This action cannot be undone?')) {
            releasePayment(id)
          }
        }}>RELEASE PAYMENT</button>
      },
      disableFilters: true
    }
  ])

  const [selectedService, setSelectedService] = useState(null)
  const [selectedExpert, setSelectedExpert] = useState(null)
  const [duration, setDuration] = useState(null)
  const [milestone, setMilestone] = useState(null)
  const [newProjectServices, setNewProjectServices] = useState([])
  const [newProjectMilestones, setNewProjectMilestones] = useState([])
  const [hours, setHours] = useState(null)
  const [cost, setCost] = useState(null)
  const [payout, setPayout] = useState(null)
  const [projectExperts, setProjectExperts] = useState([])
  const [experts, setExperts] = useState([]);
  const [currency, setCurrency] = useState({ code: 'usd', symbol: '$', rate: 1 })

  const { data: admins } = useQuery("admins", fetchAdmins, {
    select: (data) => data.admins.map(e => ({ label: e.name, value: e.id })),
    refetchOnWindowFocus: false,
  })


  const { isLoading: usersLoading, data: customers } = useQuery("customers", fetchCustomers, {
    select: (data) => data.customers.map(c => {
      return { label: `${c.name} - ${c.email}`, value: c.id }
    }),
    refetchOnWindowFocus: false,
  })

  const { projectId } = useParams()

  const { data: project, isLoading, refetch, isFetching } = useQuery("project", () => fetchProjects(projectId), {
    refetchOnWindowFocus: false,
    select: (data) => data.projects[0],
    onSuccess: project => {
      refetchCurrency(project.currency_id)
      if (project.is_duration_parallel) {
        setDuration(project.duration)
      }
    }
  })

  // const { data: user } = useQuery("user", fetchUser, {
  //     select: (data) => data.data,
  //     refetchOnWindowFocus: false,
  // })

  const { mutate: refetchCurrency } = useMutation((currency_id) => fetchCurrency({ id: currency_id }, true), {
    onSuccess: res => {
      setCurrency(res.currencies[0])
    },
    refetchOnWindowFocus: false,
  })

  const { data: serviceExperts, isLoading: expertsLoading } = useQuery(["experts", selectedService, project?.currency_id], () => fetchExperts(selectedService, project?.currency_id), {
    refetchOnWindowFocus: false,
    select: (data) => data.experts,
    onSuccess: experts => {
      setExperts(experts.map(e => {
        return {
          label: `${e.name} - ${e.hourly_rate_formatted}/h`,
          value: e.expert_id
        }
      }))
    }
  })
  // Pre-fetch data
  const { isLoading: servicesLoading, data: services } = useQuery("services", fetchServices, {
    refetchOnWindowFocus: false,
    select: (data) => data.services.map(s => {
      return { label: s.name, value: s.id }
    })
  })

  const { data: projectServices = [], isLoading: projectServicesLoading, refetch: refetchProjectServices } = useQuery(["project_services", projectId], () => fetchProjectServices(projectId), {
    refetchOnWindowFocus: false,
    select: (data) => data.services,
    onSuccess: (data) => {
      setNewProjectServices(data)
      let servicesExperts = []

      if (data) {
        data.map(s => {
          servicesExperts.push({ label: s.expert_name, value: s.expert_id })
        })

        // Filter project services and populate it to milestone service dropdown
        let fs = data.reduce((acc, current) => {
          const x = acc.find(item => item.service_id === current.service_id);
          if (!x) { return acc.concat([current]) } return acc;
        }, [])

        setProjectExperts(servicesExperts)
        setFilteredServices(fs)
      }
    }

  })

  const { data: projectMilestones = [], isLoading: projectMilestonesLoading, refetch: refetchProjectMilestones } = useQuery(["project_milestones", projectId], () => fetchProjectMilestones(projectId), {
    refetchOnWindowFocus: false,
    select: (data) => data.milestones,
    onSuccess: (data) => {
      setNewProjectMilestones(data.map(m => ({
        ...m,
        service_name: services?.find(s => s.value == m.service_id)?.label,
      })))
    }
  })

  // const { isLoading: usersLoading, data: customers } = useQuery("customers", fetchCustomers, {
  //     refetchOnWindowFocus: false,
  //     select: (data) => data.customers.map(c => {
  //         return { label: `${c.name} - ${c.email}`, value: c.id }
  //     })
  // })

  // const { data: allExperts, isLoading: allExpertsLoading } = useQuery("allexperts", () => fetchAllExperts(null), {
  //     refetchOnWindowFocus: false,
  //     select: data => data.experts.map(e => {
  //         return { label: e.name, value: e.expert_id }
  //     }),
  //     refetchOnWindowFocus: false,
  // })

  const { isLoading: projectStatusLoading, data: projectStatusOptions } = useQuery("project_status", fetchProjectStatus, {
    refetchOnWindowFocus: false,
    select: (data) => data.status.map(s => {
      return { label: s.name, value: s.id }
    })
  })

  const mutation = useMutation(updatedProject => updateProject(updatedProject, projectId), {
    onSuccess: () => {
      alert("Project successfully updated !")
      refetch()
      // window.location.href = `${process.env.REACT_APP_STLLR_ADMIN_URL}all-projects`
    },
    onError: (error) => {
      alert(error.data?.message)
    }
  })

  const { mutate: puaseRenewal, isPausing } = useMutation(() => pauseStripeRenewal(project.id), {
    onSuccess: () => {
      alert('Project auto renewal has been paused successfully !');
      window.location.href = `/all-projects`
    },
    onError: error => {
      alert(error.data?.message);
    }
  })

  const { mutate: closeProject, isLoading: isClosing } = useMutation(() => markAsFinished(project.id), {
    onSuccess: () => {
      alert('Project has been marked as finished successfully');
      refetch()
      // window.location.href = `/all-projects`
    },
    onError: error => {
      alert(error.data?.message);
    }
  })

  const { mutate: releasePayment, isReleasing } = useMutation((transaction_id) => releaseTransaction(transaction_id), {
    onSuccess: () => {
      alert('Payment is released successfully!');
      window.location.reload()
    },
    onError: error => {
      alert(error.data?.message);
    }
  })

  const handleCloseProject = () => {
    let message = 'Are you sure you want to mark this project as done?'
    message += project.is_payins_cash ? '' : ' experts earnigs will be deposited to their wallet if you do.'
    if (window.confirm(message)) {
      closeProject()
    }
  }

  const handleServiceChange = (serviceId) => {
    setSelectedService(serviceId)
  }

  const handleExpertChange = (expertId) => {
    const expert = serviceExperts.find(e => e.expert_id === expertId);
    expert.hourly_rate_converted *= currency.rate
    setSelectedExpert(expert)
  }

  const updateServices = (event) => {
    event.preventDefault()
    if (!selectedExpert) {
      alert('Expert is not selected')
      return;
    }
    const { expert_id, hourly_rate_converted } = selectedExpert;

    if ([hours, cost, payout].every(e => !e)) {
      alert('You must enter one of these: Cost, Hours or Payout')
      return;
    }

    console.log({ hours, cost, payout })
    if ([hours, cost, payout].filter(Boolean).length > 1) {
      alert('You have to enter ONLY one of these: Cost, Hours or Payout')
      return;
    }

    let newHourlyRate = hourly_rate_converted,
      newHours,
      newCost,
      newPayout;

    if (hours) {
      newHours = hours
      newCost = newHourlyRate * newHours
      newPayout = newCost - (newCost * 0.2)
    }
    else if (cost) {
      newCost = cost
      newHours = newCost / newHourlyRate
      newPayout = newCost - (newCost * 0.2)
    }
    else if (payout) {
      newPayout = payout
      newCost = newPayout / (1 - 0.2)
      newHours = newCost / newHourlyRate
    }

    const newTotal = duration * newCost

    let newService = {
      id: newProjectServices.length,
      service_id: selectedService,
      expert_id: expert_id,
      duration: duration,
      days: project.days,
      hours: newHours,
      cost: newCost,
      payout: newPayout,
      total: newTotal
    }

    // Get expert and service
    let service = services.filter(obj => {
      return obj.value === newService.service_id
    })

    let expert = experts.filter(obj => {
      return obj.value === newService.expert_id
    })

    let exists = projectExperts.some((el) => {
      return el.value === expert[0].value
    }),
      results = experts.filter(ex => {
        return ex.value === expert[0].value
      })

    if (!exists) setProjectExperts([...projectExperts, { label: results[0].label, value: results[0].value }])

    newService.service_name = service[0].label
    newService.expert_name = expert[0].label

    // setNewProjectServices([...projectServices, newService])

    let curratedServices = [...newProjectServices, newService]

    // Filter duplicates from curratedServices array and publish it to the milestone service dropdown
    let fs = curratedServices.reduce((acc, current) => {
      const x = acc.find(item => item.service_id === current.service_id);
      if (!x) { return acc.concat([current]) } return acc;
    }, []);

    setNewProjectServices(curratedServices)
    setFilteredServices(fs)
  }

  useEffect(() => {
    handleCommissionChange(newProjectServices)
  }, [newProjectServices])

  const handleCommissionChange = (services, payinValue) => {
    if (!formikRef?.current || !formikRef?.current?.setFieldValue) {
      return
    }
    const { values, setFieldValue } = formikRef.current

    const totalCost = services.reduce((a, c) => a + Number(c['cost']) * c['duration'], 0)
    const totalPayout = services.reduce((a, c) => a + Number(c['payout']) * c['duration'], 0)
    const totalPrice = Number(payinValue || values.payin)
    if (totalPrice > 0 && totalPayout > 0) {
      if (totalPayout > 0) {
        const actual_commission = ((totalPrice - totalPayout) / totalPrice) * 100
        setFieldValue('actual_commission', actual_commission > 0 ? actual_commission?.toFixed(2) : 0)
      }
      else {
        setFieldValue('actual_commission', 0)
      }

      if (totalCost > 0) {
        const commission = ((totalPrice / totalCost) - 1) * 100
        setFieldValue('commission', commission > 0 ? commission?.toFixed(2) : 0)
      }
      else {
        setFieldValue('commission', 0)
      }
    }
    else {
      setFieldValue('commission', 0)
      setFieldValue('actual_commission', 0)
    }
  }

  const updateMilestones = (event) => {
    event.preventDefault();

    let newM = {
      id: newProjectMilestones.length + 1,
      ...milestone,
    }

    // Check if the same milestone title is assigned for the same expert
    let exists = projectMilestones.some((m) => {
      return m.expert_id === newM.expert_id && m.title === newM.title
    })

    /*if (exists) alert("A milestone with the same exact name has been already assigned to this expert")
    else setNewProjectMilestones([...newProjectMilestones, newM])*/
    setNewProjectMilestones([...newProjectMilestones, newM])
  }

  const handleMilestoneServiceChange = (serviceId, serviceName) => {

    let array = newProjectServices.filter(fs => {
      return serviceId == fs.service_id
    }).map(ref => {
      return { label: ref.expert_name, value: ref.expert_id }
    })

    // Get expert of this selected service
    setRefinedExperts(array)

    setMilestone({ ...milestone, service_id: serviceId, service_name: serviceName })
  }

  const getMilestoneExpertName = () => {
    if (milestone?.expert_id) {
      const expert = refinedExperts.find(e => e.value === milestone?.expert_id)
      if (expert) {
        return expert?.label
      }
    }
  }

  const handleOpenGenerateMilestones = (values) => {
    const expert_id = milestone?.expert_id
    const owner_id = project.owner_id
    const duration = values.is_duration_parallel ? (
      Math.max(...newProjectServices.map(s => parseInt(s.duration)))
    ) : (
      newProjectServices.reduce((a, c) => a + parseInt(c.duration), 0)
    )

    if (!expert_id) {
      alert('Select an expert first')
      return
    }

    const expert_name = getMilestoneExpertName()

    setGenerateMilestones({
      expert_name,
      expert_id,
      owner_id,
      services,
      duration,
    })
  }

  const handleOnSubmitGeneratedMilestones = (milestones) => {
    if (milestones && Array.isArray(milestones)) {
      const mappedMilestones = milestones.map((m, i) => ({
        id: `${Math.round(Math.random() * 999999)}`,
        expert_id: milestone.expert_id,
        title: m.title,
        description: m.description,
        start_date: m.start_date,
        end_date: m.end_date,
        service_id: m.service_id,
        service_name: services.find(s => s.value == m.service_id)?.label
      }))
      console.log({ projectMilestones, mappedMilestones, milestones })
      setNewProjectMilestones([
        ...projectMilestones,
        ...mappedMilestones,
      ])
    }
  }

  if (isLoading || isFetching) return <div>Loading...</div>

  const minCommission = project.creator_role_id == process.env.REACT_APP_ADMIN_ROLE_ID ? 30 : 20

  return (
    <div className="content-wrapper mb-55">
      <Container spacing={1}>
        <Grid item lg={12}>
          <div className="is-flex aic jcsb">
            <div className='is-flex aic'>
              <h2 className="mt-0 mb-5 mt-20">Edit project</h2>
              <button onClick={() => window.location.href = `/project-history/${projectId}`} className="stllr-btn btn-sm primary bordered ml-10">VIEW HISTORY</button>
            </div>
            {project.status_id == process.env.REACT_APP_PROJECT_STATUS_ONGOING && (
              <div className="is-flex fdc aic jcc">
                <Button
                  text={`Mark This Project As Done`}
                  className="stllr-btn primary has-full-width mb-30"
                  onClick={() => handleCloseProject()}
                  disabled={isClosing}
                />
                {project.payment_method_id == process.env.REACT_APP_PAYMENT_METHOD_STRIPE ? <Button
                  text='PAUSE RENEWAL'
                  className='btn-lg secondary bordered'
                  disabled={isPausing}
                  onClick={() => {
                    const ok = window.confirm('Are you sure you want to pause this project?');
                    if (ok) puaseRenewal()
                  }}
                /> : null}
              </div>
            )}
            <PayButton project={project} />
          </div>

          <Formik
            innerRef={formikRef}
            initialValues={{
              status_id: project.status_id,
              name: project.name,
              commission: project.commission - 20,
              actual_commission: project.actual_commission,
              payin: project.price,
              goal: project.goal,
              is_duration_parallel: Boolean(project.is_duration_parallel),
              k_status: project.k_status,
              k_description: project.k_description,
              moderator_user_id: project.moderator_user_id,
              date_ongoing: project.date_ongoing,
              account_manager_id: project.account_manager_id,
              is_account_manager_admin: true,
            }}
            validationSchema={Yup.object().shape({
              status_id: Yup.number().required("Please select a valid project status"),
              account_manager_id: Yup.number("Please select a valid project status"),
              name: Yup.string().required("Please spacify a name for this project"),
              goal: Yup.string().required("Please specify a goal for this project"),
              // commission: Yup.number().required("Please specify a valid project commission").min(10, 'Please update project Payins & Payouts to make comission more that 10%'),
              actual_commission: Yup.number().min(
                minCommission,
                `Please update project Payins & Payouts to make comission more than ${minCommission}%`
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              let formattedMilestones = newProjectMilestones.map(m => {
                return {
                  ...m,
                  start_date: m.start_date ? moment(m.start_date).format("YYYY-MM-DD") : null,
                  end_date: m.end_date ? moment(m.end_date).format("YYYY-MM-DD") : null,
                }
              })

              delete formattedMilestones.raw_end_date
              delete formattedMilestones.raw_start_date

              const project_duration = values.is_duration_parallel ? (
                Math.max(...newProjectServices.map(s => parseInt(s.duration)))
              ) : (
                newProjectServices.reduce((a, c) => a + parseInt(c.duration), 0)
              )

              if (project.status_id === Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING)) {
                if (project_duration !== project.duration) {
                  alert(`Project duration must not be changed. You changed duration from ${project.duration} to ${project_duration}`)
                  return
                }

              }

              // if (project_duration < 2 && newProjectServices.length > 1) {
              //     alert('Project duration should not be less than 2 months for teams')
              //     return
              // }

              const commission = Number(values.commission) + 20

              mutation.mutate({ ...values, commission, newProjectServices, formattedMilestones })
            }}
          >
            {
              props => {
                const {
                  values,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props

                return (
                  <form className="is-flex jcsb fdc" onSubmit={handleSubmit}>
                    <div>
                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">PROJECT INFORMATION</h4>
                      </div>

                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="name">Project status</label>
                            <Select
                              options={projectStatusOptions?.map(s => {
                                if (project.payment_method_id == process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER
                                  && s.value == process.env.REACT_APP_PROJECT_STATUS_FINISHED) {
                                  s.isDisabled = true
                                }
                                return s
                              })}
                              isLoading={projectStatusLoading}
                              name="status_id"
                              onChange={({ value }) => setFieldValue("status_id", value)}
                              value={projectStatusOptions?.find(s => {
                                return s.value === values.status_id
                              })}
                            />
                            {errors.status_id && touched.status_id ? (
                              <span className="v-error">{errors.status_id}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="name">Project name</label>
                            <input type="text" name="name" value={values.name} onChange={handleChange} className="stllr-input" placeholder="Enter project name" />
                            {errors.name && touched.name ? (
                              <span className="v-error">{errors.name}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="owner_id">Project owner</label>
                            <Select
                              name="owner_id"
                              id="owner_id"
                              isDisabled
                              value={{ label: project.owner_email }}
                              className="has-full-width"
                              onChange={(option) => setFieldValue("owner_id", option.value)}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 40,
                                  minHeight: 40
                                })
                              }}
                            />
                            {errors.owner_id && touched.owner_id ? (
                              <span className="v-error">{errors.owner_id}</span>
                            ) : null}
                          </Grid>

                          <Grid item lg={2}>
                            <label htmlFor="name">Commission %</label>
                            <input disabled={true} type="number" name="actual_commission" onChange={handleChange} className="stllr-input" placeholder="Enter project commssion" min="10" max="80" value={values.actual_commission} />
                            {errors.actual_commission && touched.actual_commission ? (
                              <span className="v-error">{errors.actual_commission}</span>
                            ) : null}
                            <label><sub>Including the 20% independent's fees</sub></label>
                          </Grid>

                          <Grid item lg={3}>
                            <label htmlFor="name">Pay-In</label>
                            <input
                              type="number"
                              name="payin"
                              value={values.payin}
                              onChange={e => {
                                setFieldValue('payin', e.target.value)
                                handleCommissionChange(newProjectServices, e.target.value)
                              }}
                              className="stllr-input"
                              placeholder="Enter project price"
                            />
                            <label><sub>the client's total budget</sub></label>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="name">Project goal</label>
                            <textarea
                              name="goal"
                              value={values.goal}
                              onChange={handleChange}
                              className="stllr-input"
                              placeholder="Enter project goal"
                            ></textarea>
                            {errors.goal && touched.goal ? (
                              <span className="v-error">{errors.goal}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="group">
                        <Grid container spacing={2}>
                          {project.status_id == process.env.REACT_APP_PROJECT_STATUS_ONGOING &&
                            <Grid item lg={3}>
                              <label htmlFor="name">Project start date</label>
                              <DayPickerInput
                                value={moment(values.date_ongoing).format("Y-MM-DD")}
                                onDayChange={(date) => setFieldValue("date_ongoing", moment(date).format("Y-MM-DD"))}
                              />
                            </Grid>
                          }
                          <Grid item lg={3}>
                            <label>
                              <input
                                type="checkbox"
                                name="is_account_manager_admin"
                                checked={values.is_account_manager_admin}
                                onChange={e => setFieldValue('is_account_manager_admin', e.target.checked)}
                              />{' '}
                              Admin account manager?
                            </label>
                            <label htmlFor="name">Account Manager</label>
                            {values.is_account_manager_admin ? (
                              <Select
                                options={admins}
                                name="account_manager_id"
                                onChange={({ value }) => setFieldValue("account_manager_id", value)}
                                value={admins && admins.find(s => {
                                  return s.value === values.account_manager_id
                                })}
                              />
                            ) : (
                              <Select
                                options={customers}
                                name="account_manager_id"
                                onChange={({ value }) => setFieldValue("account_manager_id", value)}
                                value={customers && customers.find(s => {
                                  return s.value === values.account_manager_id
                                })}
                              />
                            )}
                            {errors.account_manager_id && touched.account_manager_id ? (
                              <span className="v-error">{errors.account_manager_id}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {user?.email === process.env.REACT_APP_ADMIN_EMAIL ?
                        <div className="group">
                          <Grid container direction="row" spacing={2}>
                            <Grid item lg={3}>
                              <label htmlFor="name">K Status</label>
                              <Select
                                // isDisabled={user.email === process.env.REACT_APP_ADMIN_EMAIL ? false : true}
                                options={kStatus}
                                defaultValue={{ label: project.k_status, value: project.k_status }}
                                name="k_status"
                                id="k_status"
                                className="has-full-width"
                                onChange={(option) => setFieldValue("k_status", option.value)}
                                styles={{
                                  control: base => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40
                                  })
                                }}
                              />
                            </Grid>

                            <Grid item lg={3}>
                              <label htmlFor="name">K Description</label>
                              <textarea
                                // disabled={user.email === process.env.REACT_APP_ADMIN_EMAIL ? false : true}
                                className="stllr-input"
                                style={{ resize: "none" }}
                                name="k_description"
                                value={values.k_description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        :
                        null
                      }

                      <div className="sep mb-18" />

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label>Project Currency</label>
                            <Select
                              placeholder={`${project.currency_name} - ${project.currency_code}`}
                              isDisabled={true}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>

                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">PROJECT LEAD</h4>
                      </div>

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Assigned Lead</label>
                            <Select
                              options={projectExperts}
                              placeholder="Project Lead"
                              // value={projectExperts.filter(c => {
                              //     return c.value === project.moderator_user_id
                              // })}
                              name="moderator_user_id"
                              onChange={({ value }) => setFieldValue("moderator_user_id", value)}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                          </Grid>

                          {/* <Grid item lg={2}>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            className="stllr-btn bordered-primary"
                                                            text="Save"
                                                            onClick={({ value }) => setModeratorUserId(value)}
                                                        />
                                                    </Grid> */}
                        </Grid>
                      </div>

                      <div className="sep mb-18" />

                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">PROJECT SERVICES</h4>

                        {/* <div className="round-plus" style={{cursor: "pointer"}} onClick={() => addNewServiceRow()}>
                                                    <img src={`${process.env.REACT_APP_STLLR_STATIC_URL}add-circle-icon.svg`} height="20" alt="Add service" />
                                                </div> */}
                      </div>

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Service</label>
                            <Select
                              options={services}
                              isLoading={servicesLoading}
                              placeholder="Select service"
                              onChange={({ value }) => handleServiceChange(value)}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                          </Grid>

                          <Grid item lg={3}>
                            <label htmlFor="name">Assgined Expert</label>
                            <Select
                              options={experts}
                              placeholder="Assign to expert"
                              onChange={({ value }) => handleExpertChange(value)}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                          </Grid>

                          <Grid item lg={1}>
                            <label htmlFor="name">Duration</label>
                            <input
                              type="number"
                              name="duration"
                              onChange={(e) => setDuration(e.target.value)}
                              className="stllr-input"
                              placeholder="Months"
                              value={duration}
                              max={project.status_id === Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING)
                                && values.is_duration_parallel ? project.duration : Infinity}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>

                          <Grid item lg={2}>
                            <label htmlFor="name">Hours per month</label>
                            <input type="number" name="hours" onChange={(e) => setHours(e.target.value)} className="stllr-input" placeholder="Hours" />
                          </Grid>

                          <Grid item lg={2}>
                            <label htmlFor="name">Cost per month</label>
                            <input type="number" name="cost" onChange={(e) => setCost(e.target.value)} className="stllr-input" placeholder="Cost" />
                          </Grid>

                          <Grid item lg={2}>
                            <label htmlFor="name">Payout per month</label>
                            <input type="number" name="payout" onChange={(e) => setPayout(e.target.value)} className="stllr-input" placeholder="Payout" />
                          </Grid>

                          <Grid item lg={1}>
                            <label>&nbsp;</label>
                            <Button
                              className="stllr-btn bordered primary"
                              text="Save"
                              onClick={(e) => updateServices(e)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={6} />
                          <Grid item lg={6}>
                            <label>
                              <input type="checkbox" name="is_duration_parallel" checked={values.is_duration_parallel} onChange={e => {
                                const checked = e.target.checked
                                setFieldValue('is_duration_parallel', checked ? 1 : 0)
                                if (checked) setDuration(project.duration)
                              }} />
                              {' '}
                              Will experts be working in parallel ?
                            </label>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="mb-25">
                        <TableContainer notSticky={true} hiddenColumns={[]} paging={true} columns={servicesColumns} data={newProjectServices} />
                      </div>

                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">PROJECT SCOPE OF WORK</h4>
                      </div>

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Scope of work</label>
                            <input type="text" onChange={(e) => setMilestone({ ...milestone, title: e.target.value })} className="stllr-input" placeholder="Milestone Title" />
                          </Grid>

                          <Grid item lg={2}>
                            <label htmlFor="service">Service</label>
                            <Select
                              options={filteredServices.map(fs => {
                                return { label: fs.service_name, value: fs.service_id }
                              })}
                              onChange={({ value, label }) => handleMilestoneServiceChange(value, label)}
                            />
                          </Grid>

                          <Grid item lg={2}>
                            <label>Expert</label>
                            <Select
                              // options={projectExperts}
                              options={refinedExperts}
                              onChange={({ value: expert_id }) => setMilestone({ ...milestone, expert_id })}
                            />
                          </Grid>

                          <Grid item lg={2}>
                            <label htmlFor="name">Start date</label>
                            <DayPickerInput onDayChange={(date) => setMilestone({ ...milestone, start_date: moment(date).format("Y-MM-DD") })} />
                          </Grid>

                          <Grid item lg={2}>
                            <label htmlFor="name">End date</label>
                            <DayPickerInput onDayChange={(date) => setMilestone({ ...milestone, end_date: moment(date).format("Y-MM-DD") })} />
                          </Grid>

                          <Grid item lg={1}>
                            <label>&nbsp;</label>
                            <Button
                              className="stllr-btn bordered primary"
                              text="Save"
                              onClick={(e) => updateMilestones(e)}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item lg={11}>
                            <Editor
                              apiKey={process.env.REACT_APP_TINY_API_KEY}
                              onInit={(evt, editor) => editorRef.current = editor}
                              init={{
                                width: '100%',
                                height: 250,
                                menubar: false,
                                content_style: 'div { background-color: red }',
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                  'bold italic backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                  'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              }}
                              onEditorChange={value => setMilestone({ ...milestone, description: value })}
                            />
                          </Grid>
                          <Grid item lg={1}>
                            <Button
                              className="stllr-btn colorful fw6"
                              style={{ width: 'auto' }}
                              text="AI Generate"
                              onClick={e => {
                                console.log(values)
                                e.preventDefault()
                                handleOpenGenerateMilestones(values)
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>

                      <div className="mb-50">
                        <TableContainer notSticky={true} hiddenColumns={[]} paging={true} columns={milestonesColumns} data={newProjectMilestones} />
                      </div>

                      {project.unreleased_payments.length ?
                        <div className="mb-25">
                          <h2 className="mb-20 dimmed">Unreleased Payments</h2>
                          <TableContainer notSticky={true} hiddenColumns={[]} paging={true} columns={unreleasedColumns} data={project.unreleased_payments} />
                        </div>
                        : null}
                    </div>

                    <input
                      type="submit"
                      className="stllr-btn primary btn-lg"
                      value="Update project"
                    />

                    {Object.keys(errors).length ? <span className="mt-10 v-error">Please check for wrong/missing fields [{Object.keys(errors).join(', ')}]</span> : ``}

                    <MilestonesDialog
                      open={Boolean(generateMilestones)}
                      onClose={() => setGenerateMilestones(null)}
                      data={generateMilestones}
                      onSubmit={handleOnSubmitGeneratedMilestones}
                    />
                  </form>
                )
              }
            }
          </Formik>
        </Grid>
      </Container>
    </div>
  )
}

export default EditProject