
const getVideoMetadata = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const url = URL.createObjectURL(file);
      const video = document.createElement("video");
      video.src = url;
      video.addEventListener("loadedmetadata", function () {
        resolve({
          width: this.videoWidth,
          height: this.videoHeight,
        })
      })
    }
    catch (e) {
      reject(e)
    }
  })
}

export default getVideoMetadata