import React from "react"
import { CustomModal } from "./modal"
import { Formik } from 'formik';
import * as Yup from "yup"
import moment from "moment";
import { Button } from "./button";
import { useMutation } from "react-query";
import { deleteTransactionById } from "../services/expert";

function WithdrawalMissingForm({ info, onClose, onSuccess }) {
    const isOpen = Boolean(info)

    const { mutate, isLoading } = useMutation((data) => deleteTransactionById({ id: info.transactionId, ...data }), {
        onSuccess: () => {
            alert("Email has been sent successfully!")
            onSuccess()
            onClose()
        },
        onError: e => {
            alert(e.data?.message)
        }
    })

    const handleOnSubmit = (values, { setSubmitting }) => {
        console.log({ description: values.description, expert_id: info.expertId })
        mutate({ description: values.description, expert_id: info.expertId })
        setSubmitting(false)
    }

    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={info ? <Formik
                initialValues={{ description: '' }}
                validationSchema={Yup.object().shape({
                    description: Yup.string().required('Please specify the issue in details')
                })}
                onSubmit={handleOnSubmit}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue
                    } = props
                    return (
                        <form onSubmit={handleSubmit}>
                            <h2 className="mb-20">
                                Send an email with the withdrawal request issue to the creator
                            </h2>

                            <div className='mb-10'>
                                <h3 className='has-text-bold'>Details*</h3>
                                <textarea
                                    type="text"
                                    name="description"
                                    defaultValue={values.description}
                                    className="stllr-input"
                                    onChange={(e) => (setFieldValue('description', e.target.value))}
                                    onBlur={handleBlur}
                                />
                                {errors.description && touched.description && (
                                    <span className="v-error">{errors.description}</span>
                                )}
                            </div>

                            <Button
                                text='Submit'
                                type='submit'
                                className='primary btn-lg mt-20'
                                disabled={isSubmitting || isLoading}
                            />
                        </form>
                    )
                }}
            </Formik> : "Loading.."}
        />
    )
}

export { WithdrawalMissingForm }