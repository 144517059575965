import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { addBriefRecommendations, fetchBriefRecommendations, fetchTopForNiche, removeBriefRecommendations } from '../services'
import { Formik } from 'formik'
import * as Yup from "yup"
import Select from "react-select"
import { Grid } from 'material-ui-core'
import { CustomModal } from './modal'

const VideoRecommendationsModal = ({ isOpen, onClose, onSubmit, video, expertOptions }) => {

    const { data: recommendations, isLoading, refetch } = useQuery("recommendations", () => fetchBriefRecommendations(video?.id), {
        enabled: !!video?.id,
        select: (data) => data.recommendations,
        refetchOnWindowFocus: true,
    })

    const { data: nicheTop, isLoading: nicheTopLoading } = useQuery("nicheTop", () => fetchTopForNiche({ niche_id: video?.brief.niche.value }), {
        enabled: !!video?.id,
        select: (data) => {
            const recommendationIds = recommendations?.map(r => r.id) || [];
            return data.experts.filter(e => !recommendationIds.includes(e.expert_id));
        },
        refetchOnWindowFocus: true,
    })

    const { mutate: addRecommendation, isAdding } = useMutation(data => addBriefRecommendations(video?.id, data), {
        select: (data) => data.recommendations,
        onSuccess: () => {
            alert('Creator added to recommended successfully')
            refetch()
        },
    })

    const { mutate: removeCreator, isRemoving } = useMutation(data => removeBriefRecommendations(video?.id, data), {
        select: (data) => data.recommendations,
        onSuccess: () => {
            alert('Creator removed from recommended successfully')
            refetch()
        },
    })

    const handleOnRemove = (expert_id) => {
        if (window.confirm('Are you sure you want to remove this creator from recommended list?')) {
            removeCreator({ expert_id })
        }
    }

    //   return videos?.every(v => v.expert_id !== null) ? (
    //     <p>Brief no longer have open positions to recommend.</p>
    //   ) : (

    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={
                <div>
                    <h2 className='mb-20'>Below list represent creators who can view this brief and apply to it</h2>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            expert_id: null,
                        }}
                        validationSchema={Yup.object().shape({
                            expert_id: Yup.number().nullable().required("Please select a creator"),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            addRecommendation({ expert_id: values.expert_id });
                            setSubmitting(false);
                            resetForm();
                        }}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                setFieldValue,
                                errors,
                                isSubmitting,
                                handleSubmit
                            } = props;

                            return (
                                <form onSubmit={handleSubmit}>
                                    {nicheTopLoading ? null : nicheTop?.length ? (
                                        <div>
                                            <h3 className='mb-20'>Top recommendations depending on niche</h3>
                                            {nicheTop.map((e, i) => (
                                                <div>
                                                    <div key={i} className='mb-10 is-flex aic'>
                                                        <label className='mr-10'>{e.email}</label>
                                                        <button
                                                            className='stllr-btn primary'
                                                            disabled={isSubmitting || isAdding}
                                                            onClick={(event) => { event.preventDefault(); addRecommendation({ expert_id: e.expert_id }) }}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="sep mb-18" />
                                        </div>
                                    ) : null}

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item lg={6}>
                                            <Select
                                                options={expertOptions}
                                                name={`expert_id`}
                                                placeholder="Select Creator"
                                                value={values.expert_id && expertOptions.find(e => e.value === values.expert_id)}
                                                onChange={({ value }) => setFieldValue('expert_id', value)}
                                            />
                                            {errors.expert_id && touched.expert_id ? (
                                                <span className="v-error">{errors.expert_id}</span>
                                            ) : null}
                                        </Grid>
                                        <Grid item lg={6}>
                                            <input
                                                type="submit"
                                                className="stllr-btn primary btn-lg ml-20"
                                                disabled={isSubmitting || isAdding}
                                                value="Recommend Creator"
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    </Formik>

                    <div className="sep mb-18" />

                    {isLoading ? "Loading.." : recommendations?.length ? (
                        recommendations.map((expert) => (
                            <div key={expert.id}>
                                <div className='is-flex fdr jcsb'>
                                    <div className='is-flex fdr aic'>
                                        <div className='mr-20'>{expert.name} ({expert.uid})</div>
                                        <div className='is-flex aic'>
                                            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} target="_blank" href={`${process.env.REACT_APP_STLLR_URL}independents/${expert.uid}`}>VIEW PROFILE</a>
                                            {expert.tiktok_url &&
                                                <div className="d-flex align-items-center">
                                                    <a href={expert.tiktok_url} target='_blank' rel='noreferrer' className="ml-10">
                                                        TikTok
                                                    </a>
                                                </div>
                                            }
                                            {expert.instagram_url &&
                                                <a href={expert.instagram_url} target='_blank' rel='noreferrer' className="ml-10">
                                                    Instagram
                                                </a>

                                            }
                                            {expert.youtube_url &&
                                                <a href={expert.youtube_url} target='_blank' rel='noreferrer' className="ml-10">
                                                    YouTube
                                                </a>
                                            }
                                            {expert.behance_url &&
                                                <a href={expert.behance_url} target='_blank' rel='noreferrer' className="ml-10">
                                                    Behance
                                                </a>
                                            }
                                            {expert.soundcloud_url &&
                                                <a href={expert.soundcloud_url} target='_blank' rel='noreferrer' className="ml-10">
                                                    Soundcloud
                                                </a>
                                            }
                                            {expert.imdb_url &&
                                                <a href={expert.imdb_url} target='_blank' rel='noreferrer' className="ml-10">
                                                    IMDB
                                                </a>
                                            }
                                            {expert.dribbble_url &&
                                                <a href={expert.dribbble_url} target='_blank' rel='noreferrer' className="ml-10">
                                                    Dribbble
                                                </a>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className="stllr-btn btn-sm danger"
                                            style={{ padding: "0px 8px" }}
                                            onClick={() => handleOnRemove(expert.id)}
                                            disabled={isRemoving}
                                        >
                                            REMOVE
                                        </button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))
                    ) : 'No recommended creators'}
                </div>
            }
        />
    );

    //   )
}

export default VideoRecommendationsModal