import React from 'react';
import './styles.css';
import PropType from 'prop-types';
import { Avatar, Switch } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ConversationsSidebar = ({
  onConversationClicked,
  list,
  activeProject,
  checked,
  showChecked,
  search,
  searchPlaceholder,
  onSearch,
  handleChange,
  ...props
}) => {

  return (
    <div className='conversations-sidebar' style={{ height: '86vh' }} {...props}>
    <div className='is-flex fdc' style={{ height: '100%' }}>
      <div className='conversations-header'>
        {/* Conversation header. */}

        {showChecked ? <FormGroup>
          <FormControlLabel 
            label="Show mismatch chats"
            control={
            <Switch
              checked={checked}
              onChange={handleChange}
              color="primary"
            />
            } 
            />
        </FormGroup> : false}

        <input
          value={search}
          placeholder={searchPlaceholder || 'Search by company, owner or project'}
          className="stllr-input"
          onChange={e => onSearch(e.target.value)}
        />

      </div>
      <div className='conversations-list custmScroll'>
        {list.filter(e => {
          const mismatchFilter = showChecked ? e.is_chat_enabled != checked : true
          const regex = new RegExp(search, 'i')
          const searchFilter = search  === '' ? true : Boolean(
            (e?.owner_name ? e.owner_name.search(regex) > -1 : false) || 
            (e?.company_name ? e.company_name.search(regex) > -1 : false) || 
            (e?.name ? e.name.search(regex) > -1 : false)
          )
          return mismatchFilter && searchFilter
        }).map((item, index) => (
          <div
            key={index}
            className={`conversations-item ${item.uid == activeProject ? 'active' : null}`}
            onClick={() => onConversationClicked(item)}
          >
            <div className='is-flex fdr aic mb-10'>
              <Avatar alt={item.owner_name} src={item.avatar} className='conversations-item-avatar' />
              <div className='conversations-item-name'>
                {/* {item.company_name}
                <br/> */}
                {item.company?.name} {item.company ? 'and' : null} {item.expert?.name}
              </div>
            </div>
            {/* <div className={`service-chip conversations-item-status ${item.project_status === "Awaiting payment" ? `danger` : ``}`}>
              {item.project_status}
            </div> */}
            {item.name ? <div className='service-chip conversations-item-project-name'>
              {item.name}
            </div> : <div />}
            {/* {item.id == activeProject ?
              "" :
              <div>{item.unread_messages < 1 ? "" :
                <div className='notification'>
                  +{item.unread_messages}
                </div>
              }</div>
            } */}
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

ConversationsSidebar.defaultProps = {
  onConversationClicked: () => true,
  list: [],
}

ConversationsSidebar.propTypes = {

  /**
   * function called when an item of the list is clicked
   * it passes item object as parameter
   * 
   * (itemObject) => {
   *  // do something
   * }
   */
  onConversationClicked: PropType.func,

  /**
   * the list of object represents conversations
   * 
   */
  list: PropType.array,

}

export default ConversationsSidebar;