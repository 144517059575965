import React from "react"
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Avatar } from "@material-ui/core"
import { fetchTeams, editTeam } from "../services"
import { useQuery, useMutation } from "react-query";
import { useParams } from 'react-router';
import { Link } from "react-router-dom"
import { CircularProgress, Breadcrumbs, Link as MuiLink } from '@material-ui/core';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button } from "../components/button";
import HtmlParser from "react-html-parser";

function Team() {
    const { id: teamId } = useParams()

    const { data: team, isLoading, refetch, isFetching: isTeamLoading } = useQuery("team", () => fetchTeams(teamId), {
        select: (data) => data.teams,
    })

    const { mutate: updateAvailability, isLoading: isSubmitting } = useMutation(data => editTeam({ data }, teamId), {
        onSuccess: (data) => {
            // alert("Availability Successfully updated")
            refetch()
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    if (isLoading || isSubmitting) {
        return (
            <div className='is-flex aic jcc mt-45 mb-45'>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Container maxWidth="lg" className="mt-20" style={{ padding: 0 }}>
            <div className="is-flex jcsb mb-20">
                <Grid item lg={8}>
                    <div className="is-flex fdc">
                        <div className='mb-30'>
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                <MuiLink component={Link} to={`/all-teams`}>Teams Search</MuiLink>
                                <MuiLink color="inherit" className='fw7'>{team.name}</MuiLink>
                            </Breadcrumbs>
                        </div>
                        <div className="mb-45">
                            <h3 className="mb-10 is-flex fw7">{team.name}</h3>
                            <h4>{HtmlParser(team.description)}</h4>
                            <div className="is-flex">
                                {team.services.map(e => {
                                    return <div className="service-chip">{e.name}</div>
                                })}
                            </div>
                            {team.info &&
                                <div>
                                    <p>{HtmlParser(team.info)}</p>
                                </div>
                            }
                        </div>

                        <div>
                            <h3 className="mb-5 is-flex fw7">Project Experts</h3>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Expert name</TableCell>
                                        <TableCell><div className="is-flex jcc">Hourly rate</div></TableCell>
                                        <TableCell><div className="is-flex jcc">Average hours per month</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {team.experts.map(e => (
                                        <TableRow>
                                            <TableCell>
                                                <div className='is-flex fdr'>
                                                    <Avatar src={e.avatar || null} style={{ width: 30, height: 30 }} />
                                                    <div className="is-flex fdc">
                                                        <div className="is-flex fdr aic">
                                                            <h3 style={{ flexShrink: 0, color: "#272AEE" }} className="ml-10 mr-5 fw6">{e.name || e.email}</h3>
                                                            {e.finishedProjectsCount[0].total > 0 && <CheckCircleSharpIcon className="listSvgcheck mr-5" />}
                                                            {e.is_creator === 1 &&
                                                                <Tooltip title="Manager" arrow>
                                                                    <img src={`${process.env.REACT_APP_STLLR_STATIC_URL}blue-flag.svg`} alt="" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                        {e.tier_name ? <div className='is-flex fdr aic mb-5'>
                                                            <div className={`tier-circle ${e.tier_name?.toLowerCase()}`}></div>
                                                            <div style={{ marginLeft: 9 }}>{e.tier_name}</div>
                                                        </div> : ''}
                                                        {e.services ? e.services.map(service => (
                                                            <span className="service-chip">{service.service_name}</span>
                                                        )) : 'Pending'}
                                                    </div>
                                                </div>
                                                {e.avgResponseTime && <h3 className="is-flex fdr aic mt-10">
                                                    <AccessTimeIcon style={{ width: 20, height: 20, marginRight: 5 }} />
                                                    Average Response Time: {Math.ceil(e.avgResponseTime / 3600)} hour{Math.ceil(e.avgResponseTime / 3600) > 1 && 's'}
                                                </h3>}
                                            </TableCell>

                                            <TableCell>
                                                <div className="is-flex jcc">
                                                    <h3>{`${e.hourly_rate ? `$${e.hourly_rate}` : 'Pending'}`}</h3>
                                                </div>
                                            </TableCell>


                                            <TableCell>
                                                <div className="is-flex jcc">
                                                    <h3>{e.hours} hrs</h3>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </Grid>


                <Grid item lg={3} >
                    <div className="">
                        <div className="is-flex fdc aife" style={{ marginBottom: 175 }}>
                            <div className="is-flex fdr mb-10">
                                <h2 className="fw6">${(team.cost / team.duration).toFixed(0)}<sup className="fw4">/Mo</sup></h2>
                            </div>
                            <div className="is-flex fdr aic jcc mb-10">
                                <PersonOutlineIcon className="dimmed" />
                                <h4 className="mr-10">{team.experts.length} Experts </h4> <span className="mr-10">|</span>
                                <AccessTimeIcon className="dimmed" />
                                <h4 className="">{team.duration} Months</h4>
                            </div>

                            <div>
                                <Button
                                    text={team.is_package ? 'Edit Package' : 'Edit Team'}
                                    onClick={() => {
                                        team.is_package ?
                                            window.location.href = `/edit-package/${team.id}`
                                            :
                                            window.location.href = `/edit-team/${team.id}`
                                    }}
                                    className="stllr-btn primary btn-lg"
                                />
                            </div>
                            {isTeamLoading ? null :
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch
                                            defaultChecked={team.is_available}
                                            onChange={e => {
                                                updateAvailability(JSON.stringify({ is_available: e.target.checked }))
                                            }}
                                        />}
                                        label="Team Availability" />
                                </FormGroup>
                            }
                        </div>

                        <div className="mb-20" style={{
                            border: "1px solid #EDECEC",
                            borderRadius: "8px",
                            maxHeight: "250px",
                            width: "275px",
                            padding: "20px"
                        }}>
                            <h3 className="is-flex aifs fw6 mb-20">Team Summary</h3>
                            <div className="is-flex jcsb bottom-border mb-15">
                                <h4>Experts</h4>
                                <h4>{team.experts.length}</h4>
                            </div>

                            <div className="is-flex jcsb bottom-border mb-15">
                                <h4>Minimum Duration</h4>
                                <h4 className="is-flex fdr">{team.duration} &nbsp; <h4 className="dimmed">Months</h4></h4>
                            </div>

                            {team.is_package && team.days ? <div className="is-flex jcsb bottom-border mb-15">
                                <h4>Days</h4>
                                <h4 className="is-flex fdr">{team.days} &nbsp; <h4 className="dimmed">Days</h4></h4>
                            </div> : null}

                            <div className="is-flex jcsb">
                                <h3>Mothly Rate</h3>
                                <h3 className="fw6">${(team.cost / team.duration).toFixed(0)}<sup className="fw4">/Mo</sup></h3>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>

            <Grid container spacing={3}>
                <Grid item lg={4}>
                    <h3 className="mb-20 fw7">
                        Languages
                    </h3>
                    <div className="is-flex fdr fw">
                        {team.languages.map(e => (
                            <div className="service-chip">
                                {e.name}
                            </div>
                        ))}
                    </div>
                </Grid>

                <Grid item lg={4}>
                    <h3 className="mb-20 fw7">
                        Tools
                    </h3>
                    <div className="is-flex fdr fw">
                        {team.tools.map(e => (
                            <div className="service-chip">
                                {e.name}
                            </div>
                        ))}
                    </div>
                </Grid>

                <Grid item lg={4}>
                    <h3 className="mb-20 fw7">
                        Industries
                    </h3>
                    <div className="is-flex fdr fw">
                        {team.sectors.map(e => (
                            <div className="service-chip">
                                {e.name}
                            </div>
                        ))}
                    </div>
                </Grid>

                <Grid item lg={12}>
                    <div className="group is-flex jcsb fdc mt-50">
                        <h3 className="mb-10 is-flex fw7">Scope of work</h3>
                    </div>

                    <Table>
                        <TableBody>
                            {team.milestones?.length ? team.milestones.map(m => (
                                <TableRow>
                                    <TableCell>
                                        <div className='is-flex fdr aic jcsb'>
                                            <div style={{ width: '75%' }}>
                                                <h3 className="has-text-bold">{m.title}</h3>
                                                <p>{HtmlParser(m.description)}</p>
                                            </div>
                                            <div>
                                                <span className="service-chip">{m.service_name}</span>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )) : <div className="is-flex fdr aic jcc has-text-centered">
                                No scope of work added yet.
                            </div>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Team