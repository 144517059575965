import React from "react"
import {  useFormik } from "formik"
import * as Yup from "yup"
import { Button } from "../components"
import TextField from '@material-ui/core/TextField';
import TipsAndUpdatesRounded from '@mui/icons-material/TipsAndUpdatesRounded'

const GenerateMilestonesForm = ({ onSubmit, isSubmitting: isLoading }) => {

  const {
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues: {
      description: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      onSubmit(values)
    },
    validationSchema: Yup.object().shape({
      description: Yup.string()
        .required("Please enter description")
        .max(200, 'please make description shorter than 200')
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div>
      <div className="is-flex fdr aic mb-20">
        <TextField
          type="text"
          name="description"
          variant="outlined"
          label="Describe what you’ll work on..."
          fullWidth
          size="small"
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Button
          type="submit"
          onClick={handleSubmit}
          className="stllr-btn colorful ml-10"
          style={{ width: 'auto', paddingLeft: 15, paddingRight: 15 }}
          text={<div className="is-flex fdr aic">
            <TipsAndUpdatesRounded fontSize="small" />
            <span className="ml-10 fw7">Generate</span>
          </div>}
          disabled={isSubmitting || isLoading}
        />
      </div>
      {isLoading ?
        <div className="is-flex aic jcc fdc">
          <h3 className="semi-dimmed">Cooking your scope, hang tight!</h3>
        </div>
        : null}
      {errors.description && touched.description && (
        <span className="v-error">{errors.description}</span>
      )}
    </div>
  )
}

export default GenerateMilestonesForm