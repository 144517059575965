import React, { useMemo } from "react"
import { Container, Grid, Chip } from "@material-ui/core"
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { fetchGroups, fetchTeams } from "../services"
import TableContainer from "../components/TableContainer"
import DefaultFilter from "../components/DefaultFilter"
import moment from "moment";

function Groups() {
  const columns = useMemo(() => [
    {
      Header: 'Project Name',
      accessor: `project_name`,
    },
    {
      Header: 'Experts',
      accessor: `experts`,
    },
    {
      Header: "Actions",
      Filter: ``,
      Cell: ({ cell }) => {
        let { id } = cell.row.original
        return (
          <div className="is-flex aic">
            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc mr-5" style={{ padding: "0px 8px" }} href={`/team/${id}`}>VIEW</a>
            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc" style={{ padding: "0px 8px" }} href={`/edit-team/${id}`}>Edit</a>
          </div>
        )
      }
    }
  ])
  const { data: groups, isLoading } = useQuery("groups", () => fetchGroups(), {
    select: res => console.log('ellooo', res)
  })

  if (isLoading) return <div>Loading...</div>

  return (
    <Container spacing={1} maxWidth='lg'>
      <Grid item lg={12}>
        hi baby
        {/* <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Teams (<i>{teams.length}</i>)</h2>
          <a className="stllr-btn primary btn-lg" href="/new-team">Create Team</a>
        </div>
        <TableContainer paging={true} columns={columns} hiddenColumns={[]} data={teams} /> */}
      </Grid>
    </Container>
  )
}

export default Groups