import React from "react"
import { useQuery, useMutation } from "react-query"
import {
    Grid,
    Container,
    Avatar,
    Badge
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import {
    fetchAllExperts,
} from "../services"
import { Formik } from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import ReactToPdf from "react-to-pdf";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';


const steps = [
    {
        label: 'Technical Interview',
    },
    {
        label: 'Personal Interview',
    }
];

function ExpertApplication() {
    const ref = React.createRef()
    const { id } = useParams()
    const [activeStep, setActiveStep] = React.useState(0);
    const [expanded, setExpanded] = React.useState(false);


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { data: expert, isLoading } = useQuery("expert", () => fetchAllExperts(id), {
        select: data => data.experts,
        onSuccess: e => {
            if (e[0].personal_skills !== null) {
                setActiveStep(2)
            } else if (e[0].technical_skills !== null) {
                setActiveStep(1)
            }
        },
        refetchOnWindowFocus: false,
    })

    if (isLoading) return <div>Loading...</div>

    return (
        <div className="content-wrapper mb-55">

            <Container spacing={1} ref={ref}>
                <div className="is-flex aic jcsb">
                    <h2 className="mt-0 mb-30 mt-20">Expert Application: {expert[0].name}</h2>
                </div>

                <Grid container spacing={2}>
                    <Grid item lg={10} className='is-flex fdr profileImgSection'>
                        <div className='is-flex fdr  mb-30'>
                            <div className="imgDiv">
                                <Avatar
                                    alt={`${expert[0].name}`}
                                    src={`${expert[0].avatar}`}
                                    className="imgBigSize"
                                />
                            </div>

                            <div className="is-flex aifs jcfs fdc ml-20">

                                <div className="txtBadge is-flex aic jcfs mb-10">
                                    <h3>{expert[0].name}</h3>
                                </div>


                                <div className="locationDiv txtBadge is-flex aifs jcsb mt-20 mb-30">
                                    <Badge color="secondary" variant="dot" />
                                    <h5 style={{ marginLeft: "15px" }}>{expert[0].tier_name} Tier</h5>
                                    <RoomOutlinedIcon fontSize="small" />
                                    <h5>{expert[0].city_name}, {expert[0].country_name}</h5>
                                </div>

                                <h2 className="has-max-width">
                                    ${expert[0].hourly_rate ? expert[0].hourly_rate : expert[0].priceRange} /HOUR
                                </h2>

                            </div>
                        </div>
                    </Grid>

                    <Grid item lg={2}>
                        <ReactToPdf targetRef={ref} filename={`${expert[0].name} profile.pdf`} x={.5} scale={0.8}>
                            {({ toPdf }) => (
                                <button className="stllr-btn btn-lg primary mb-40" onClick={toPdf}>Export as PDF</button>
                            )}
                        </ReactToPdf>

                        <div>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel>
                                            {step.label}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </Grid>

                    <Grid item lg={6}>
                        <div className="mb-40">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <h2 className="fw7 mb-10">Bio</h2>
                            </Typography>
                            <Typography>
                                <h5>{expert[0].bio}</h5>
                            </Typography>
                        </div>

                        <div className="mb-40">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <h2 className="fw7 mb-10">Expertise</h2>
                            </Typography>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Portofolio Link:</h3>
                                    <h5>{expert[0].portfolio_link}</h5>
                                </div>

                                <div className="mb-20">
                                    <h3>Social profiles:</h3>
                                    <h5>{expert[0].linkedin_url}</h5>
                                    <h5>{expert[0].youtube_url}</h5>
                                    <h5>{expert[0].behance_url}</h5>
                                    <h5>{expert[0].dribbble_url}</h5>
                                    <h5>{expert[0].instagram_url}</h5>
                                    <h5>{expert[0].tiktok_url}</h5>
                                    <h5>{expert[0].imdb_url}</h5>
                                    <h5>{expert[0].soundcloud_url}</h5>
                                    <h5>{expert[0].twitter_url}</h5>
                                </div>

                                <div className="mb-20">
                                    <h3>Campaign type(s) expertise:</h3>
                                    <h4>{expert[0].expertise.map(e => (<div className="service-chip">{e.service_name}</div>))}</h4>
                                </div>
                            </Typography>
                        </div>

                        <div className="mb-40">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}><h2 className="fw7 mb-10">Tools</h2></Typography>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Mastered Tools:</h3>
                                    <h4>{expert[0].tools.map(t => (<div className="service-chip">{t.name}</div>))}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Industries:</h3>
                                    <h4>{expert[0].industries.map(i => (<div className="service-chip">{i.name}</div>))}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Industry focus:</h3>
                                    <h4>{expert[0].sectors.map(s => (<div className="service-chip">{s.name}</div>))}</h4>
                                </div>
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item lg={3}>
                        <div className="mb-40">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <h2 className="fw7 mb-10">Region</h2>
                            </Typography>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Languages:</h3>
                                    <h4>{expert[0].languages.map(l => (<div className="service-chip">{l.name}</div>))}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Countries:</h3>
                                    <h4>{expert[0].countries.map(c => (<div className="service-chip">{c.name}</div>))}</h4>
                                </div>
                            </Typography>
                        </div>

                        <div className="mb-40">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}><h2 className="fw7 mb-10">Pricing</h2></Typography>
                            <Typography>
                                <div className="mb-20">
                                    <h3>Job status</h3>
                                    <h4>{expert[0].job_status}</h4>
                                </div>

                                <div className="mb-20">
                                    <h3>Preferred project duration(s)</h3>
                                    <h4>{expert[0].hiring_availability}</h4>
                                </div>
                            </Typography>
                        </div>

                        <div className="mb-40">
                            <Typography sx={{ width: '33%', flexShrink: 0 }}><h2 className="fw7 mb-10">Resume</h2></Typography>
                            <Typography>
                                <h5>{expert[0].resume_url}</h5>

                                {expert[0]?.resume?.length ?
                                    expert[0].resume.map(r => (
                                        <>
                                            <div className="mb-20">
                                                <h5 className="boxSmallHeading">{r.company_name}</h5>
                                                <h5 className="subSmallText">{moment(r.start_year).format("ll")} - {r.end_year ? moment(r.end_year).format("ll") : "Till Now"}</h5>
                                                <h5 className="subSmallText">{r.role_title}</h5>
                                            </div>

                                        </>
                                    ))
                                    : null}
                            </Typography>
                        </div>

                        <div className="mb-40">
                            <Typography sx={{}}><h2 className="fw7 mb-10">Work samples</h2></Typography>
                            <Typography>

                                {expert[0].works.map(w => (
                                    <>
                                        <div className="mb-20" style={{ borderBottom: '1px solid #CFCFCF' }}>
                                            {w.title && <h5 className="boxSmallHeading">Title: {w.title}</h5>}
                                            {w.description && <h5 className="boxSmallHeading">Description: {w.description}</h5>}
                                            {w.image && <h5 className="boxSmallHeading">Image Link: <a href={w.image} target="_blank">{w.image}</a></h5>}
                                            {w.url && <h5 className="boxSmallHeading">Attached URL: <a href={w.url} target="_blank">{w.url}</a></h5>}
                                            {w.file_name && <h5 className="boxSmallHeading">Attached file name: {w.file_name}</h5>}
                                            {w.file_url && <h5 className="boxSmallHeading">Attached file url: <a href={w.file_url} target="_blank">{w.file_url}</a></h5>}
                                        </div>

                                    </>
                                ))}
                            </Typography>
                        </div>

                        <div className="mb-40">
                            <Typography sx={{}}><h2 className="fw7 mb-10">Shipping address</h2></Typography>
                            <Typography>
                                {expert[0].shipping_address}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default ExpertApplication