import React from "react";
import { Routes} from "./routes"
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-quill/dist/quill.snow.css';

function App() {
  const queryClient = new QueryClient()
  
  return (
    <QueryClientProvider client={queryClient}>
      <Routes />
    </QueryClientProvider>
  );
}

export default App;
