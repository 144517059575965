import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_STLLR_API_URL

export const fetchExpertConversations = async () => {
    return request({
        url: `${BaseUrl}v1/message/conversations`,
        params: { type: 'experts' }
    }, true)
}

export const fetchProjectConversations = async () => {
    return request({
        url: `${BaseUrl}v1/message/conversations`,
        method: "GET",
    }, true)
}

export const fetchAdminConversations = async () => {
    return request({
        url: `${BaseUrl}v1/message/conversations`,
        method: "GET",
        params: { is_admin: true }
    }, true)
}

export const toggleConversation = async (chat_id, is_enabled) => {
    return request({
        url: `${BaseUrl}v1/chat/${chat_id}/toggle`,
        method: "POST",
        data: { is_enabled }
    }, true)
}