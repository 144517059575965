import React, { useMemo, useState } from "react"
import { Container, Grid, Chip, Card } from "@material-ui/core"
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { fetchTeams } from "../services"
import TableContainer from "../components/TableContainer"
import DefaultFilter from "../components/DefaultFilter"
import moment from "moment";

function Teams() {
  const columns = useMemo(() => [
    {
      Header: 'Package Name',
      accessor: `name`,
    },
    {
      Header: 'Experts',
      accessor: `experts_count`,
    },
    {
      Header: 'Ready experts',
      accessor: `ready_experts`,
    },
    {
      Header: 'Minimum Duration',
      accessor: `duration`,
    },
    {
      Header: 'Cost per Month',
      Cell: ({ cell }) => {
        const { cost, duration } = cell.row.original
        return `$${(cost / duration).toFixed(2)}`
      }
    },
    {
      Header: 'Date Created',
      accessor: 'date_add',
      Cell: ({ cell }) => {
        let { date_add } = cell.row.original
        return moment(date_add).format('ll')
      }
    },
    {
      Header: "Actions",
      Filter: ``,
      Cell: ({ cell }) => {
        let { id } = cell.row.original
        return (
          <div className="is-flex aic">
            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc mr-5" style={{ padding: "0px 8px" }} href={`/team/${id}`}>VIEW</a>
            <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc" style={{ padding: "0px 8px" }} href={`/edit-package/${id}`}>Edit</a>
          </div>
        )
      }
    }
  ])
  const { data: data, isLoading } = useQuery("teams", () => fetchTeams(null, "?is_package=1"), {
    select: data => data,
  })

  if (isLoading) return <div>Loading...</div>

  return (
    <Container spacing={1} maxWidth='lg'>
      <CatBox
        label='Sold packages'
        value={data.totalSoldPackages ? data.totalSoldPackages : 'Not Authorised'}
      />
      <Grid item lg={12}>
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Packages (<i>{data.teams.length}</i>)</h2>
          <a className="stllr-btn primary btn-lg" href="/new-package">Create Package</a>
        </div>
        <TableContainer paging={true} columns={columns} hiddenColumns={[]} data={data.teams} />
      </Grid>
    </Container>
  )
}

const CatBox = ({ label, value, ...props }) => {
  return (
    <Card
      {...props}
      className={`payouts-box`}
    >
      {label}: <span>{value}</span>
    </Card>
  )
}

export default Teams