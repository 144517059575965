import React from "react"
import { CustomModal } from "./modal"
import { useMutation } from "react-query"
import { updateTransactionById } from "../services"
import { Button } from "./button"

function WithdrawalIssueDetails({ transaction, onClose, onSuccess }) {
    const isOpen = Boolean(transaction)

    const { mutate, isLoading } = useMutation((data) => updateTransactionById({ id: transaction.id, ...data }), {
        onSuccess: () => {
            alert("Issue has been closed successfully!")
            onSuccess()
            onClose()
        },
        onError: e => {
            alert(e.data?.message)
        }
    })

    const handleCloseIssue = () => {
        mutate({ issue: null })
    }

    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={transaction ? <div>
                <h2 className="fw6">
                    {"Issue details"}
                </h2>
                <p className="mt-45">
                    {transaction.issue}
                </p>
                
                <div className="is-flex fdr aic jcfe">
                    <Button
                        text='Close issue'
                        onClick={handleCloseIssue}
                        className='secondary bordered btn-lg mt-20'
                        disabled={isLoading}
                    />
                </div>
            </div> : "Loading.."}
        />
    )
}

export { WithdrawalIssueDetails }