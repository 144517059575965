import './ChatBox.css'
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Avatar, LinearProgress, Divider, CircularProgress } from "@material-ui/core"
import { io } from "socket.io-client";
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { MentionsInput, Mention } from 'react-mentions'
import { Button, OverlappingAvatars, NewMeetingModal } from "../index"
import { Star, StarOutline, Close } from '@material-ui/icons'
import { Add } from "@mui/icons-material"
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from '@mui/material/Tooltip';
import HtmlParser from 'react-html-parser';
import rehypeRaw from "rehype-raw";

const ChatContext = React.createContext({})

export const ChatBox = (props) => {
    const {
        chatId,
        projectId,
        teamId,
        expertId,
        userEmail,
        targetCalendarEmail,
        title,
        members = [],
        actions = [],
        onSendMessage = () => null,
        onReceiveMessage = () => null,
        inputContentComponent,
        messageOptionsComponent = () => null,
        containerStyles,
        disabled,
        meetingButton,
    } = props
    const [socket, setSocket] = useState(null)
    const [status, setStatus] = useState(null)
    const [messages, setMessages] = useState([])
    const [reads, setReads] = useState([])
    const [files, setFiles] = useState([])
    const [allColors] = useState(["blue", "green", "red", "purple", "white", "yellow", "pink", "orange"])
    const messagesEndRef = useRef(null)
    const [repliedTo, setRepliedTo] = useState(null)
    const inputRef = useRef(null)

    const [chatColors] = useState(() => {
        let obj = {}
        members.map((e, i) => obj[e.email] = allColors[i])
        return obj
    })

    const addTimestamp = (filename) => {
        const arr = filename.split('.');
        arr[arr.length - 2] += '_' + Date.now()
        return arr.join('.')
    }

    const readAsBinaryString = (data) => new Promise((resolve, reject) => {
        const FReader = new FileReader();
        FReader.readAsBinaryString(data)
        FReader.onload = event => resolve(event.target.result)
        FReader.onerror = error => reject(error)
    })

    const sendFileChunks = async (file) => {
        const step = 500e3 // 500 kb
        let position = 0;
        let n = 1
        while (position < file.size) {
            const chunk = file.data.slice(position, step * n)
            const data = await readAsBinaryString(chunk)
            socket.emit('send_file_data', file.name, data);
            position += step
            n++
        }
    }

    const sendMessage = (message, selectedFile) => {
        if (disabled) {
            alert('Chat is disabled!')
            return
        }
        else if (selectedFile) {
            const name = addTimestamp(selectedFile.name)
            const file = {
                name: name,
                originalName: selectedFile.name,
                type: selectedFile.type,
                size: selectedFile.size,
                data: selectedFile,
                message: message,
                progress: 0,
            }
            setFiles(files => [...files, file])
            socket.emit('send_file', file)
            onSendMessage(message)
            sendFileChunks(file)
        } else if (message) {
            const messageData = {
                type_id: process.env.REACT_APP_TEXT_MESSAGE_TYPE_ID,
                content: `${message}`,
                reply_message_id: repliedTo ? repliedTo.id : null
            }
            socket.emit("send_message", messageData);
            onSendMessage(message)
        } else {
            alert("Please do not try to send an empty message")
        }
    }

    const starMessage = (messageId) => {
        socket.emit('star_message', messageId)
    }

    const unstarMessage = (messageId) => {
        socket.emit('unstar_message', messageId)
    }

    const storeReply = (message) => {
        setRepliedTo(message)
    }

    const unStoreReply = () => {
        setRepliedTo(null)
    }

    const filterMessages = (filters) => {
        socket.emit('filter_messages', filters)
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }, 100)
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages, files]);

    useEffect(() => {
        setStatus('Connecting')
        const query = {
            type: process.env.REACT_APP_SOCKET_CONNECTION_CHAT_TYPE,
            userEmail: userEmail
        }
        if (chatId) query.chat = chatId
        else if (projectId) query.project = projectId
        else if (teamId) query.team = teamId
        else if (expertId) query.expert = expertId
        const s = io(`${process.env.REACT_APP_STLLR_API_URL}`, { query });
        setSocket(s)
    }, [chatId, projectId, teamId, expertId])

    useEffect(() => {
        if (socket !== null) {

            socket.on("connect", handleConnect);
            socket.on("disconnect", handleDisconnect);
            socket.on("init", handleInit);
            socket.on("init_reads", handleInitReads);

            socket.on("receive_message", handleRecieveMessage);
            socket.on("update_reads", handleUpdateReads);
            socket.on("file_error", handleFileError);
            socket.on("file_progress", handleFileProgress);
            socket.on("file_uploaded", handleFileUploaded);


            return () => {
                socket.disconnect()
                socket.off("connect", handleConnect);
                socket.off("init", handleInit);
                socket.off("init_reads", handleInitReads);
                socket.off("recieve_message", handleRecieveMessage)
                socket.off("update_reads", handleUpdateReads);
                socket.off("file_error", handleFileError);
                socket.off("file_progress", handleFileProgress);
                socket.off("file_uploaded", handleFileUploaded);
            }

        }
    }, [socket]);

    const handleDisconnect = () => {
        setStatus('Error Occurred!')
    }

    const handleConnect = () => {
        setStatus('Loading')
    }

    const handleFileUploaded = (fileName) => {
        setFiles(files => files.filter(file => file.name !== fileName))
    }
    const handleFileProgress = (fileName, percent) => {
        setFiles(files => files.map(file => {
            if (file.name === fileName) file.progress = percent
            return file
        }))
    }

    const handleFileError = (fileName) => {
        setFiles(files => files.map(file => {
            if (file.name === fileName) file.error = true
            return file
        }))
    }

    const handleInitReads = (reads) => {
        setReads(reads)
    }

    const handleUpdateReads = (reads, { chat, project, team, expert }) => {
        if ((chat && chat === chatId) || (project && project == projectId) || (team && team == teamId) || (expert && expert == expertId)) {
            setReads(reads.filter(r => r.email !== userEmail))
        }
    }

    const handleRecieveMessage = (message, { chat, project, team, expert }) => {
        if (((chat && chat == chatId) || project && project == projectId) || (expert && expert == expertId)) {
            setMessages((messages) => [...messages, message])
            onReceiveMessage(message.content)
            socket.emit("read_messages", {
                chat: chatId,
                project: projectId,
                expert: expertId,
            })
        } else {
            onReceiveMessage(message.content)
        }
    }

    const handleInit = (data) => {
        setMessages(data)
        setStatus(null)
    }

    return (
        <ChatContext.Provider value={{
            ...props,
            socket,
            reads,
            chatColors,
            files, setFiles,
            sendMessage,
            starMessage,
            unstarMessage,
            filterMessages,
            inputContentComponent,
            messageOptionsComponent,
            storeReply,
            repliedTo,
            unStoreReply,
            inputRef
        }}>
            <div className="is-flex fdc" style={{ height: "86vh", position: 'relative',  ...containerStyles }}>
                <div className="is-flex fdc messageBox" style={{ overflowX: 'none' }}>
                    <Header />
                    <Filters />
                    <div className='scrollable-chat' style={{ overflowX: 'none' }}>
                        <div className="is-flex aic jcc mb-30 mt-20">
                            <div className="has-text-centered chatNotice">
                                <h5>
                                    Stllr is not responsible for any conversations between existing
                                    individuals outside our platform and our quality team monitors all chats.. For more information, please read our terms & conditions.
                                </h5>
                            </div>
                        </div>
                        {!messages.length ? null : messages.map((message, index, messagesList) => {
                            // pass first name only
                            message.name = message.name?.split(' ')?.[0] || message.name;

                            const lastMessage = messagesList[index - 1]?.date_add.split('T')[0]
                            const currentMessage = message.date_add.split('T')[0]
                            const diffInDays = !lastMessage ? 1 : moment(currentMessage).startOf('day').diff(moment(lastMessage).startOf('day'), 'days');

                            let dateDivider = null

                            if (diffInDays > 0) {
                                const todayDiff = moment(new Date().toDateString()).startOf('day').diff(moment(currentMessage).startOf('day'), 'days');
                                if (todayDiff == 0) {
                                    dateDivider = "TODAY"
                                } else if (todayDiff == 1) {
                                    dateDivider = "YESTERDAY"
                                } else {
                                    dateDivider = moment(currentMessage).format("dddd, D MMM")
                                }
                            }

                            return (
                                <>
                                    {dateDivider && <div className='sep-w-text is-flex aic jcc mb-20 mt-10' style={{ height: "0px" }}>
                                        <span>{dateDivider}</span>
                                    </div>
                                    }
                                    <Message
                                        key={index}
                                        message={message}
                                        userColor={chatColors[message.sender]}
                                        isMine={userEmail === message.sender}
                                        isMentioned={message.mentions.includes(userEmail)}
                                    />
                                    <ReadersAvatars readers={reads.filter(r => r.message_id === message.id)} />
                                </>
                            )
                        })}

                        <FilesInProgress />

                        <div ref={messagesEndRef} />
                    </div>

                    {status && <div className='is-flex jcc mt-10 mb-10'>{status}</div>}

                    <ChatInput />
                </div>
                <div className='backdrop is-flex fdr aic jcc' style={{ display: status ? 'flex' : 'none' }}>
                    {status === 'Connecting' || status === 'Loading' ? <CircularProgress /> : null}
                </div>
            </div>
        </ChatContext.Provider>
    )
}

const Header = () => {
    const { title, members } = useContext(ChatContext)

    const avatars = members.map(e => ({
        alt: e.name,
        url: e.avatar || "/static/images/avatar/1.jpg"
    }))

    return (
        <div className="is-flex jcc fdc" style={{ paddingLeft: "10px" }}>
            <div className='is-flex fdr jcsb aic mt-20' style={{ padding: '0 10px 20px' }}>
                <h3 className="fw7">{title}</h3>
                <OverlappingAvatars avatars={avatars} />
            </div>
        </div>
    )
}

const Filters = () => {
    const [filters, setFilters] = useState([
        { key: 'starred', label: 'Starred Messages' },
        { key: 'url', label: 'URLs' },
        { key: 'file', label: 'Files' },
    ])
    const { filterMessages } = useContext(ChatContext)

    const handleFilterChange = key => {
        // toggle filter selected
        setFilters(filters => filters.map(f => f.key === key ? { ...f, isActive: !f.isActive } : f))
    }

    useEffect(() => {
        if (!filters.every(f => f.isActive === undefined)) {
            filterMessages(filters.filter(f => f.isActive).map(f => f.key))
        }
    }, [filters])

    return (
        <div className="is-flex jcc fdc" style={{ paddingLeft: "10px" }}>
            <div className='is-flex fdr aic mb-10' style={{ padding: '0 10px 0px' }}>
                <h3 className="fw7 mr-10" style={{ fontSize: 16 }}>Filter by:</h3>
                {filters.map(filter => (
                    <span className={`filter-chip is-flex fdr aic mr-10 ${filter.isActive && 'active'}`} onClick={() => handleFilterChange(filter.key)}>
                        {filter.key == "starred" ? <StarBorderIcon /> :
                            (filter.key == "url" ? <AttachmentIcon /> :
                                filter.key == "file" && <FolderRoundedIcon />)
                        }
                        {filter.isActive && <Close style={{ height: 15 }} />}
                    </span>
                ))}
            </div>

            <Divider />
        </div>
    )
}

const Message = (props) => {
    const { messageOptionsComponent } = useContext(ChatContext)
    const { message, userColor, isMentioned, isMine, onButtonClick } = props
    const { type_id, metadata, name, avatar, date_add, reply_message_id, reply_name, reply_content } = message
    let { content } = message
    // fix new lines
    content = content.replace(/(\r\n|\n|\r)/gm, `\r\n\r\n`);

    return (
        <div
            className={`is-flex ${isMine ? "fdrr" : "fdr"} aifs message-row`}
            style={{
                backgroundColor: isMentioned ? 'rgba(255, 200, 60, 0.25)' : null,
                padding: 10
            }}
        >
            <Avatar
                alt={name}
                src={avatar || "/static/images/avatar/1.jpg"}
                style={{ width: 25, height: 25, background: `${userColor}` }}
                className={`${isMine ? 'ml-10' : 'mr-10'}`}
            />

            <div className={`is-flex fdc ${isMine ? 'aife' : 'aifs'}`} style={{ maxWidth: '55%' }}>
                <div className='messageContent'>
                    <div>
                        <ReplyToMessage isReply={Boolean(reply_message_id)} name={reply_name} content={reply_content} />
                        <div className='is-flex fdr aic jcsb'>
                            <p className='fw7 mt-0' style={{ padding: 8, margin: 0 }}>
                                {name.split(' ')[0]}
                            </p>
                            <div className='is-flex fdr aic ml-20 message-options' style={{ padding: 4 }}>
                                {messageOptionsComponent(message)}
                                <StarButton
                                    messageId={message.id}
                                    isMessageStarred={message.is_starred}
                                />

                                <ReplyButton
                                    message={message}
                                />
                            </div>
                        </div>
                        {type_id == process.env.REACT_APP_TEXT_MESSAGE_TYPE_ID ? (
                            // Text Message only
                            <MessageText>{content}</MessageText>
                        ) : type_id == process.env.REACT_APP_FILE_MESSAGE_TYPE_ID ? (
                            // Message with file
                            <div className='is-flex fdc'>
                                <MessageText>{content}</MessageText>
                                {metadata && <MessageFile metadata={metadata} />}
                            </div>
                        ) : type_id == process.env.REACT_APP_BUTTON_MESSAGE_TYPE_ID ? (
                            // Message with button
                            <div className='is-flex fdc'>
                                <MessageText>{content}</MessageText>
                                {metadata && Array.isArray(metadata) ? <MessageButtonArray metadata={metadata} onClick={onButtonClick} /> : <MessageButton metadata={metadata} onClick={onButtonClick} />}
                            </div>
                        ) : null}
                    </div>
                </div>
                <p style={{ fontSize: 10, color: '#C4C4C4' }}>
                    {moment(date_add).format("LT")}
                </p>
            </div>
        </div>
    )
}

const ReadersAvatars = ({ readers, ...props }) => {
    const { chatColors } = useContext(ChatContext)

    return (
        <div className='is-flex fdr jcfe mb-10' {...props}>
            {readers.map(r => (
                <Avatar
                    alt={r.name}
                    src={r.avatar || "/static/images/avatar/1.jpg"}
                    style={{ width: 15, height: 15, background: `${chatColors[r.email]}` }}
                    className='mr-5'
                />
            ))}
        </div>
    )
}

const EventButton = ({ ...props }) => {
    const { userEmail, members, sendMessage } = useContext(ChatContext)
    const [open, setOpen] = useState(false);

    var gapi = window.gapi
    var CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
    var API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const addGoogleCalenderEvent = (form) => {
        const { summary, attendees: invitedMembers = [], start, end } = form
        const attendees = invitedMembers.map(({ email }) => ({ email }))
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

        gapi.load('client:auth2', () => {
            console.log('loaded client')

            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })

            gapi.client.load('calendar', 'v3', (e) => console.log('bam!', e))

            gapi.auth2.getAuthInstance().signIn()
                .then(() => {
                    console.log('signed in')

                    var event = {
                        'summary': summary,
                        'start': {
                            'dateTime': start,
                            'timeZone': timeZone,
                        },
                        'end': {
                            'dateTime': end,
                            'timeZone': timeZone,
                        },
                        'attendees': [
                            { 'email': userEmail },
                            ...attendees
                        ],
                        'conferenceData': {
                            'createRequest': {
                                'requestId': `${Date.now()}${Math.round(Math.random() * 10000)}`,
                                'conferenceSolutionKey': {
                                    'type': 'hangoutsMeet'
                                }
                            }
                        },
                        'reminders': {
                            'useDefault': false,
                            'overrides': [
                                { 'method': 'email', 'minutes': 24 * 60 },
                                { 'method': 'popup', 'minutes': 10 }
                            ]
                        }
                    }
                    var request = gapi.client.calendar.events.insert({
                        'calendarId': 'primary',
                        'resource': event,
                        'conferenceDataVersion': 1,
                    })

                    request.execute(event => {
                        setOpen(false)

                        sendMessage(`Event date: ${moment(event.start.dateTime).format('LLLL') + " " + event.start.timeZone} \r\n` +
                        `Event duration: 1 hour \r\n` +
                        `Meeting link: https://meet.google.com/${event.conferenceData.conferenceId} \r\n` +
                        `Attendees: ${invitedMembers.map(m => `\r\n @{{${m.email}##${m.name}}}`)}`)

                        window.open(event.htmlLink)
                    })
                })
                .catch(e => console.log('ERROR', e))
        })
    }

    const onFormSubmit = (form) => {
        addGoogleCalenderEvent(form)
    }

    return (
        <>
            <div class="image-upload" onClick={() => setOpen(true)}>
                <label className='fw5' style={{ cursor: "pointer" }}>
                    <img style={{ top: "3px" }} className="mr-5 pR" src={process.env.PUBLIC_URL + '/calendar-icon.svg'} />
                    Schedule a meeting
                </label>
            </div>
            <NewMeetingModal open={open} handleClose={() => setOpen(false)} onSubmit={onFormSubmit} members={members} />
        </>
    )
}

const ReplyToMessage = ({ isReply, name, content }) => {

    return !isReply ? null : (
        <div className='messageReply'>
            <div>
                <div className='is-flex fdr aic jcsb'>
                    <p className='fw7 mt-0' style={{ padding: 0, margin: 0 }}>
                        {name.split(' ')[0]}
                    </p>
                </div>
                <div className='is-flex fdc'>
                    <MessageText>{content || '*File*'}</MessageText>
                </div>
            </div>
        </div>
    )
}

const StarButton = ({ messageId, isMessageStarred, ...props }) => {
    const [isStarred, setIsStarred] = useState()
    const { starMessage, unstarMessage } = useContext(ChatContext)

    const handleToggleStarred = () => {
        if (isStarred) {
            unstarMessage(messageId)
        } else {
            starMessage(messageId)
        }
        setIsStarred(s => s == 0 ? 1 : 0)
    }

    useEffect(() => {
        setIsStarred(isMessageStarred)
    }, [isMessageStarred])

    return isStarred ? (
        <Star onClick={handleToggleStarred} />
    ) : (
        <StarOutline onClick={handleToggleStarred} />
    )
}

const ReplyButton = ({ message, ...props }) => {
    const { storeReply, inputRef } = useContext(ChatContext)

    const handleOnClick = () => {
        storeReply(message)
        inputRef.current.focus()
    }
    return (
        <Tooltip title="Reply" arrow>
            <ReplyIcon onClick={handleOnClick} />
        </Tooltip>
    )
}

const MessageText = ({ children, ...props }) => {

    // render paragraph with 0 margin
    const renderP = ({ node, ...props }) => (<p {...props} style={{ margin: 0 }} />)

    // render link. also mention if exists
    const renderA = ({ node, ...props }) => {
        if (!props.href) {
            return <span
                {...props}
                style={{
                    color: 'blue',
                    cursor: 'default'
                }}
            />
        }
        return <a {...props} />
    }

    return !children ? null : (
        <div {...props} style={{ padding: 8 }}>
            <ReactMarkdown
                components={{
                    p: renderP,
                    a: renderA,
                }}
                remarkPlugins={[gfm]}
                rehypePlugins={[rehypeRaw]}
                linkTarget='_blank'
            >
                {children}
            </ReactMarkdown>
        </div>
    )
}

const MessageFile = ({ metadata }) => {
    const { url, name, type, size } = metadata

    const getReadableSize = (bytes) => {
        if (bytes < 1e3) {
            return bytes + ' B'
        }
        else if (bytes < 1e6) {
            return Math.round(bytes / 1e3) + ' KB'
        }
        else {
            return Math.round(bytes / 1e6) + ' MB'
        }
    }

    const getReadableType = (type) => {
        const typeSplit = type.split("/")[1]
        return (typeSplit || type).toUpperCase()
    }

    return (
        <a className='disableHover' href={url} target="_blank" download={name} style={{ textDecoration: "none", padding: 8 }}>
            <div className='is-flex fdc aic' style={{ cursor: "pointer" }}>
                <div className='is-flex aic jcc has-full-width' style={{ height: "100px" }}>
                    <img style={{ width: "70px", height: "60px" }} src={process.env.PUBLIC_URL + '/attachment.svg'} />
                </div>
                <div className='sent-file mt-5'>
                    <h4 style={{ lineHeight: 'normal' }}>{name}</h4>
                    <div className='is-flex fdr jcsb'>
                        <h5>{getReadableType(type)}</h5>
                        <h5>{getReadableSize(size)}</h5>
                    </div>
                </div>
            </div>
        </a>
    )
}

const MessageButton = ({ metadata }) => {
    const { text, variant, action: key, payload } = metadata
    const { actions } = useContext(ChatContext)
    const action = actions.find(a => a.name === key)
    const show = action?.show === undefined ? true : action.show

    const isFunction = (func) => !!(func && func.constructor && func.call && func.apply)

    return action && show && isFunction(action.handler) ? (
        <div>
            <Button
                text={text}
                className={`stllr-btn ${variant || 'primary'} btn-lg bold has-full-width`}
                style={{ borderRadius: '0 0 4px 4px' }}
                onClick={() => action.handler(key, payload)}
            />
        </div>
    ) : null
}

const MessageButtonArray = ({ metadata }) => {
    return <div className='is-flex fdr'>
        {metadata.map(m => (
            <MessageButton metadata={m} />
        ))}
    </div>

}

const FilesInProgress = () => {
    const { files, setFiles } = useContext(ChatContext)

    const handleRemoveFromList = (e, name) => {
        e.preventDefault()
        setFiles(files => files.filter(f => f.name !== name))
    }

    return (
        <div>
            {files.map(file => (
                <div style={{ padding: 10 }}>
                    <p>
                        <span className='has-text-bold'>
                            {file.originalName}{' '}
                        </span>
                        is uploading ({file.progress}%)
                        {file.progress === 100 && ' - Processing your file.. '}
                        {file.error && (
                            <span className='v-error'>
                                {' Error occured. '}
                                <a onClick={e => handleRemoveFromList(e, file.name)} style={{ color: 'red' }}>
                                    [X]
                                </a>
                            </span>
                        )}
                    </p>
                    <LinearProgress variant='determinate' value={file.progress} />
                </div>
            ))}
        </div>
    )
}

const SelectedFile = ({ file }) => {

    const getReadableSize = (bytes) => {
        if (bytes < 1e3) {
            return bytes + ' B'
        }
        else if (bytes < 1e6) {
            return Math.round(bytes / 1e3) + ' KB'
        }
        else {
            return Math.round(bytes / 1e6) + ' MB'
        }
    }

    const getReadableType = (type) => {
        const typeSplit = type.split("/")[1]
        return (typeSplit || type).toUpperCase()
    }

    return !file ? null : (
        <div className='attached-file'>
            <div className='is-flex fdr'>
                <img className='mr-15' src={process.env.PUBLIC_URL + '/attachment.svg'} />
                <div>
                    <h3>
                        {file.name}
                    </h3>
                    <h5>
                        {getReadableType(file.type)}
                    </h5>
                </div>
            </div>
            <h5>{getReadableSize(file.size)}</h5>
        </div>
    )
}

const ReplyMessage = () => {

    const { repliedTo, unStoreReply, userEmail } = useContext(ChatContext)
    // console.log(repliedTo.content)

    return !repliedTo ? null : (
        <div className='reply-box'>
            <div className='is-flex fdr jcsb'>
                <div>
                    <h3>
                        Replying to {userEmail === repliedTo.sender ? "yourself" : repliedTo.name}
                    </h3>
                    <h5>
                        <MessageText>{repliedTo.content ? repliedTo.content : repliedTo.type_id === process.env.REACT_APP_BUTTON_MESSAGE_TYPE_ID ? "Button" : "File"}</MessageText>
                    </h5>
                </div>
            </div>
            <div>
                <Close onClick={unStoreReply} style={{ cursor: "pointer" }} />
            </div>
        </div>
    )
}

const ChatInput = () => {
    const { members, sendMessage: onSend, inputContentComponent, unStoreReply, inputRef, disabled } = useContext(ChatContext)
    const [text, setText] = useState('')
    const [file, setFile] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const mentions = members.map(e => ({
        id: e.email,
        display: e.name,
        avatar: e.avatar
    }))

    const sendMessage = () => {
        if (file) {
            onSend(text, file)
        }
        else if (text) {
            onSend(text)
        }
        else {
            alert("Please do not try to send an empty message")
        }
        setText('')
        setFile(null)
        unStoreReply()
    }

    const handleselectedFile = (event) => {
        const file = event.target.files[0]
        if (file.size > 5e6) {
            alert('File size can not exceed 5MB')
        }
        else {
            setFile(file)
        }
        event.target.value = null
    }

    const renderMentionSuggesions = (entry, search, highlightedDisplay, index, focused) => {
        const { display, avatar } = entry
        return (
            <div className='is-flex fdr aic'>
                <Avatar src={avatar} style={{ width: 20, height: 20 }} />
                <span className='ml-10'>{display}</span>
            </div>
        )
    }

    const displayMention = (id, display) => {
        return `@${display}`
    }

    const onInputKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            sendMessage()
        }
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <ReplyMessage />
            <SelectedFile file={file} />
            <div className="is-flex fdc jcsb" style={{ padding: "5px" }}>
                {inputContentComponent?.length ? <div className='offer-chat-bar'>
                    {inputContentComponent}
                </div> : null}
                <div className='is-flex fdr aic chat-input'>
                    <MentionsInput
                        autoFocus={true}
                        singleLine={false}
                        allowSuggestionsAboveCursor={true}
                        allowSpaceInQuery={true}
                        value={text}
                        placeholder='Write your message...'
                        onChange={(event) => { setText(event.target.value) }}
                        onKeyPress={onInputKeyPress}
                        inputRef={inputRef}
                        className="mentionsInput"
                        style={{
                            control: {
                                fontSize: 14,
                                fontWeight: 'normal',
                            },
                            '&multiLine': {
                                display: 'inline-block',
                                width: '100%',
                                height: '100%',

                                highlighter: {
                                    border: 0,
                                },
                                input: {
                                    padding: 10,
                                    border: 0,
                                    height: '100%',
                                    minHeight: '100%'
                                },
                            },
                            suggestions: {
                                list: {
                                    backgroundColor: 'white',
                                    border: '1px solid rgba(0,0,0,0.15)',
                                    fontSize: 14,
                                },
                                item: {
                                    padding: '5px 15px',
                                    borderBottom: '1px solid rgba(0,0,0,0.15)',
                                    '&focused': {
                                        backgroundColor: '#cee4e5',
                                    },
                                },
                            },
                        }}
                    >
                        <Mention
                            trigger="@"
                            data={mentions}
                            markup='@{{__id__##__display__}}'
                            renderSuggestion={renderMentionSuggesions}
                            displayTransform={displayMention}
                        />
                    </MentionsInput>


                    
                    <button
                        className="chat-plus borderless"
                        onClick={handleMenu}
                    >
                        <Add style={{ color: "#272AEE" }} />
                    </button>
                    <input onChange={e => handleselectedFile(e)} id="file-input" type="file" name="file-input" className='hidden' />

                    <Menu
                        id="plus-menu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                        }}
                        // keepMounted
                        transformOrigin={{
                            horizontal: 'left',
                            // vertical: 'top',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <EventButton />
                        </MenuItem>
                        <Divider />
                        <label for="file-input">
                            <MenuItem>
                                <div class="image-upload fw5">
                                    <img style={{ cursor: "pointer", top: "3px" }} className="mr-5 pR" src={process.env.PUBLIC_URL + '/attachment_icon.svg'} />
                                    Send attachment
                                </div>
                            </MenuItem>
                        </label>
                    </Menu>

                    <button
                        className="stllr-btn primary mr-10 is-flex fdr aic jcc"
                        style={{ borderRadius: "50%", width: "45px", height: "45px" }}
                        onClick={sendMessage}>
                        <img className="send-icon" src={process.env.PUBLIC_URL + '/send_msg.svg'} />
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChatBox