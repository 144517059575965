import React, { useMemo } from "react"
import { useMutation, useQuery } from "react-query"
import { Grid, Container } from "@material-ui/core"
import TableContainerServerSide from "../components/TableContainer"
import DefaultFilter from "../components/DefaultFilter"
import { useAuth } from "../utils/Auth";
import { Chip } from "@mui/material";
import moment from "moment";
import SelectColumnFilter from "../components/SelectFilter";
import { fetchAllSubscriptions } from "../services/subscription"

function Subscriptions() {
    const { user } = useAuth()

    const { data: subscriptions = [], isLoading, refetch } = useQuery("subscriptions", () => fetchAllSubscriptions(), {
        select: res => res.subscriptions
    })

    console.log('yoo', subscriptions)

    const columns = useMemo(() => [
        {
            accessor: 'customer_name',
            Header: 'Customer name',
            Filter: DefaultFilter,
            disableSortBy: true,
            show: true,
        },
        {
            accessor: 'customer_email',
            Header: 'Customer email',
            Filter: DefaultFilter,
            disableSortBy: true,
            show: true,
        },
        {
            accessor: 'plan_type',
            Header: 'Plan type',
            disableFilters: true,
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
                const { plan } = row.original
                return <span>{plan.name}</span>
            }
        },
        {
            accessor: 'date_add',
            Header: 'Date started',
            disableFilters: true,
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
                const { date_add } = row.original
                return <span>{moment(date_add).format('ll')}</span>
            }
        },
        {
            accessor: 'date_renewal',
            Header: 'Renewal date',
            disableFilters: true,
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
                const { date_renewal } = row.original
                return <span>{moment(date_renewal).format('ll')}</span>
            }
        },
        {
            accessor: 'month',
            Header: 'Active for',
            disableFilters: true,
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
                const { month } = row.original
                return <span>{month} month(s) </span>
            }
        },
        {
            accessor: 'date_next_streak',
            Header: 'Streak gift on',
            disableFilters: true,
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
              const { date_next_streak } = row.original
              return <span>{moment(date_next_streak).format('ll')}</span>
            }
          },
        {
            accessor: 'status',
            Header: 'Subscription status',
            Filter: SelectColumnFilter,
            FilterOptions: ['Active', 'Failed', 'Canceled'],
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
                const { status } = row.original
                return <Chip size="small" className={status === 'Active' ? 'chip-green' : 'chip-red'} label={status} />
            }
        },
        {
            accessor: 'is_renewable_str',
            Header: 'Will be renewed?',
            Filter: SelectColumnFilter,
            FilterOptions: ['Yes', 'No'],
            disableSortBy: true,
            show: true,
            Cell: ({ row }) => {
                const { to_be_canceled } = row.original
                return <Chip size="small" className={to_be_canceled ? 'chip-red' : 'chip-green'} label={to_be_canceled ? 'No' : 'Yes'} />
            }
        },
    ]);

    return (
        <Container spacing={1} maxWidth='xl'>
            <Grid item lg={12}>
                <div className="is-flex aic jcsb">
                    <h2 className="mt-0 mb-5 mt-20">Subscriptions (<i>{subscriptions.length}</i>)</h2>
                </div>

                {isLoading ? "loading.." : <TableContainerServerSide
                    name='subscriptions'
                    paging={true}
                    columns={columns}
                    hiddenColumns={[]}
                    data={subscriptions}
                    hasFilters={true}
                    allowSorting={true}
                />}
            </Grid>

        </Container>
    )
}

export default Subscriptions