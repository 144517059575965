import React, { useState, useMemo, useEffect } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { fetchAccountantProjects, fetchUser } from "../services"
import { Grid, Container, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core"
import TableContainer from "../components/TableContainer"
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import slugify from 'slugify';
import moment from "moment";
import { RatingModal } from "../components/RatingModal";
import { useAuth } from "../utils/Auth";

function AccountantProjects () {
    const { user } = useAuth()
    const history = useHistory()
    const [reviewData, setReviewData] = useState(null)

    // const { data: user, isLoading: userLoading } = useQuery("user", fetchUser, {
    //   select: (data) => data.data,
    // })

    const columns = useMemo(() => [
      {
        accessor: 'company_name',
        Header: 'Company',
        Filter: DefaultFilter
      },
      /*{
        accessor: 'name',
        Header: 'Project name',
        disableFilters: true
      },*/
      {
        accessor: 'project_status',
        Header: 'Status',
        Cell: ({ row }) => {
          const { status_id, project_status } = row.original
          let statusClass = ``

          switch (Number(status_id)) {
            case Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT):
              statusClass=`-red`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PENDING_BRIEF):
              statusClass=`-orange`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PENDING_MILESTONES):
              statusClass=`-blue`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING):
              statusClass=`-gold`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PAUSED_BY_CUSTOMER):
              statusClass=`-purple`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PAUSED):
              statusClass=`-yellow`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_CANCELLED):
              statusClass=`-grey`
              break;

            default:
              statusClass=`-green`
          }

          return <Chip size="small" className={`chip${statusClass}`} label={project_status} />
        },
        Filter: SelectColumnFilter
      },
      {
        accessor: `duration`,
        Header: `Duration`,
        Filter: DefaultFilter,
        Cell: ({ row }) => {
          const { duration } = row.original
          return <span>{duration} Months</span>
        }
      },
      {
        Header: 'Consumed',
        accessor: "consumed_duration",
        disableFilters: true,
        Cell: ({ row }) => {
          const { consumed_duration_days } = row.original
          return <span>{(consumed_duration_days / 30).toFixed(2)} Months</span>
        }
      },
      {
        accessor: 'cost',
        Header: 'Cost',
        disableFilters: true
      },
      {
        accessor: 'n_experts',
        Header: '#Experts',
        disableFilters: true,
        Cell: ({ row }) => {
          const { experts } = row.original
          return <span>{experts.length}</span>
        }
      },
      {
        accessor: 'type',
        Header: 'Type',
        disableFilters: true,
        Cell: ({ row }) => {
          const { creator_role_id, name } = row.original
          // console.log(creator_role_id + " - " + name)
          return <span>{creator_role_id == process.env.REACT_APP_ADMIN_ROLE_ID ? `Team` : `Self-Checkout`}</span>
        }
      },
      {
        accessor: 'commission',
        Header: 'Comm.%',
        disableFilters: true
      },
      {
        Header: 'Created date',
        accessor: "date_add",
        disableFilters: true,
        Cell: ({ row }) => {
          const { date_add } = row.original
          return moment(date_add).format('ll')
        }
      },
      {
        Header: '1st Payment',
        accessor: "date_ongoing",
        disableFilters: true,
        Cell: ({ row }) => {
          const { date_ongoing } = row.original
          return date_ongoing && moment(date_ongoing).format('ll')
        }
      },
      {
        accessor: 'price',
        Header: 'Price',
        disableFilters: true
      },
      {
        accessor: `k_status`,
        Header: `K Status`,
        Filter: DefaultFilter,
        Cell: ({ row }) => {
          const { k_status } = row.original
          return <span>{k_status}</span>
        }
      },
      {
        accessor: `k_description`,
        Header: `K Description`,
        Filter: DefaultFilter,
        Cell: ({ row }) => {
          const { k_description } = row.original
          return <span>{k_description}</span>
        }
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          const { id, owner_id, status_id, experts } = row.original
          console.log(row.original)
          return user?.email == process.env.REACT_APP_FINANCE_ADMIN_EMAIL ? (
            <div className="is-flex aic">
              <button onClick={() => window.location.href = `/project-payments/${id}`} className="stllr-btn btn-sm primary bordered ml-10">PAYMENTS</button>
            </div>            
          ) : (<div className="is-flex aic">

            {/* RATE EXPERTS BUTTON */}
            {status_id == process.env.REACT_APP_PROJECT_STATUS_FINISHED && experts.some(e => !e.is_reviewed) && <button
                className="stllr-btn btn-sm primary bordered mr-10"
                onClick={() => {
                  const data = {
                    projectId: id,
                    experts: experts.filter(e => !e.is_reviewed).map(e => {
                      return { name: e.expert_name, id: e.expert_id, avatar: e.expert_avatar }
                    })
                  }
                  setReviewData(data)
                }}
            >Rate Experts</button>}

            <button onClick={() => window.location.href = `/edit-project/${id}`} className="stllr-btn btn-sm primary bordered">EDIT</button>
            <button onClick={() => window.location.href = `/company-brief/${owner_id}`} className="stllr-btn btn-sm primary bordered ml-10">BRIEF</button>
            <button onClick={() => window.location.href = `/project-history/${id}`} className="stllr-btn btn-sm primary bordered ml-10">HISTORY</button>
            <button onClick={() => window.location.href = `/chats/${slugify(row.original.name || row.original.company_name)}/${id}`} className="stllr-btn btn-sm primary bordered ml-10">CHAT</button>
            <a href={`${process.env.REACT_APP_STLLR_URL}project/${slugify(row.original.name || row.original.company_name)}/${id}`} className="stllr-btn btn-sm primary bordered ml-10" target="_blank" rel="noopener noreferrer">VIEW</a>
            {user.email === process.env.REACT_APP_ADMIN_EMAIL || (user.email === process.env.REACT_APP_AMIRA_EMAIL) ? <button onClick={() => window.location.href = `/pulses/${id}`} className="stllr-btn btn-sm primary bordered ml-10">Pulses</button> : null}
          </div>
          )
        }
    },
  ]);
  const [open, setOpen] = useState(true)
  const { data: projects, isLoading, refetch } = useQuery("projects", () => fetchAccountantProjects(null), {
    select: ({ projects }) => projects,
    enabled: false
  })

  useEffect(() => {
    console.log(user)
    if (user && user.email) {
      refetch()
    }
  }, [user])

  if (isLoading || !projects) return <div>Loading....</div>

  return (
    <Container spacing={1} maxWidth='xl'>
      <Grid item lg={12}>
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Projects (<i>{projects.length}</i>)</h2>
          {user?.email != process.env.REACT_APP_FINANCE_ADMIN_EMAIL && <a className="stllr-btn primary btn-lg" href="/new-project">Create project</a>}
        </div>

        <TableContainer paging={true} columns={columns} hiddenColumns={user.email === process.env.REACT_APP_ADMIN_EMAIL ? [""] : ["k_status", "k_description"]} data={projects} hasFilters={true} />
      </Grid>

      {projects.map(e => (
        <div>
          {e.pulsesNeeded > 0 && (e.pulsesNeeded > e.pulses.length) ?
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth={true}>
              <DialogTitle id="form-dialog-title">
                <div className='is-flex fdr aic jcsb'>
                  Projects that need to be pulsed
                  <IconButton onClick={() => setOpen(false)}>
                    {/* <CloseIcon /> */}
                    X
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className='group'>
                  <div className="" style={{ fontSize: "14px", lineHeight: "17px" }}>
                    <div>
                      <div className="is-flex aic jcfs mb-15">
                        {/* {arr.push(e.id), console.log(arr)} */}
                        <div>{e.name}</div>
                        <button onClick={() => window.location.href = `/pulses/${e.id}`} className="stllr-btn btn-sm primary bordered ml-10">Fill Pulse</button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog> : null}
        </div>
      ))}

      <RatingModal
        data={reviewData}
        isOpen={Boolean(reviewData)}
        onClose={() => setReviewData(null)}
      />

    </Container>
  )
}

export default AccountantProjects