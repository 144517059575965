import React, { useMemo } from "react"
import { Container, Grid, Box } from "@material-ui/core"
import { useHistory, useLocation, useParams } from "react-router"
import { useQuery } from "react-query";
import { fetchUserDrafts } from "../services"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import moment from 'moment';

function CompanyDrafts() {
    const { id: user_id } = useParams()

    const { data: drafts, isLoading } = useQuery("drafts", () => fetchUserDrafts(user_id), {
        select: res => res.briefs
    })

    console.log('ostoora', drafts)

    if (isLoading) return <div>Loading...</div>

    return (
        <Container spacing={1} maxWidth='lg'>
            <Grid item lg={12}>
                <div className="is-flex fdc">
                    <h2 className="mt-0 mb-20 mt-20">Drafts</h2>

                    <div>
                        <Grid container spacing={3}>
                            {drafts.map((draft, index) => (
                                <Grid item lg={6} key={index}>
                                    <Box pb={2}>
                                        <Accordion style={{ width: 'auto' }}>
                                            <AccordionSummary
                                                style={{ width: '100%' }}
                                                expandIcon={<ExpandMore />}
                                                aria-controls={`panel-${index}`}
                                                id={`panel-${index}`}
                                            >
                                                <div className='is-flex fdr aic jcsb has-full-width mr-20'>
                                                    <span>{draft.name}</span>
                                                    <span>{moment(draft.date_add).format("ll")}</span>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{ width: '100%' }}
                                            >
                                                <Typography>
                                                    Goal: {draft.goal}
                                                </Typography>
                                                <Typography>
                                                    Country: {draft.brief.country?.label}
                                                </Typography>
                                                <Typography>
                                                    Niche: {draft.brief.niche?.label}
                                                </Typography>
                                                <Typography>
                                                    Product Delivery: {draft.brief.product_delivering?.label}
                                                </Typography>
                                                {draft.brief.videoTypes.length ? (
                                                    <Typography>
                                                        Video Types: {draft.brief.videoTypes.map((videoType) => videoType.name).join(', ')}
                                                    </Typography>
                                                ) : null}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </Grid>
        </Container>
    )
}

export default CompanyDrafts
