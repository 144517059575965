import React from "react"
import * as Yup from "yup"
import { useMutation, useQuery } from "react-query"
import { Button } from "../components";
import { Field, Formik } from "formik"
import { Container } from "@material-ui/core"
import Select from "react-select"
import { fetchCurrenceis, fetchCustomers } from "../services";
import { createCoupon } from "../services/coupon";
import { Switch, TextField } from "@mui/material";

function NewCoupon() {
  const typeOptions = [
    { value: 'percent', label: "Percent Discount" },
    { value: 'amount', label: "Amount Discount" },
  ]

  const { data: currencyOptions = [], isLoading: isCurrenciesLoading } = useQuery(['currencies'], () => fetchCurrenceis(), {
    select: res => res.currencies?.map(e => ({ label: `${e.name} - ${e.code}`, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const { data: customers = [], isLoading: isCustomersLoading } = useQuery(['customers'], () => fetchCustomers(), {
    select: (data) => data.customers.map(c => ({ label: `${c.name} - ${c.email}`, value: c.id })),
    refetchOnWindowFocus: false,
  })

  const { mutate: addCoupon, isLoading: isAdding } = useMutation(newCoupon => createCoupon(newCoupon), {
    onSuccess: () => {
      alert("Coupon successfully added !")
      window.location.href = `${process.env.REACT_APP_STLLR_ADMIN_URL}all-coupons`
    },
    onError: error => {
      alert(error.data?.message)
    },
  })

  return (
    <Container spacing={1} maxWidth='xl'>
      <div className="content-wrapper mb-55">
        <h3 className="mb-20 is-flex fw7">Add information about the coupon</h3>
        <Formik
          initialValues={{
            name: '',
            promo_code: '',
            is_active: 1,
            max_redemptions: 1,
            max_redemptions_per_user: 1,
            type: 'percent',
            percent_off: 0,
            max_amount: null,
            amount_off: 0,
            currency_id: 3,
            date_expire: null,
            customer_id: null,
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(false)
            addCoupon(values)
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required("Please specify a name"),
            promo_code: Yup.string()
              .required("Please specify a promo code")
              .test('is-valid-code', 'Only alphanumeric characters are allowed', code => /^[a-zA-Z0-9]*$/.test(code)),
            max_redemptions: Yup.number().min(1, 'Maximum redemptions should be 1 or more'),
            max_redemptions_per_user: Yup.number().min(1, 'Maximum redemptions per user should be 1 or more').test(
              'max',
              'Maximum redemptions should be less or equal to total redemptions',
              (value, ctx) => ctx?.parent?.max_redemptions && value <= ctx.parent.max_redemptions
            ),

            percent_off: Yup.number().when('type', {
              is: type => type === 'percent',
              then: Yup.number()
                .max(100, 'Maximum is 100 percent')
                .min(1, 'Please specify discount percent'),
              otherwise: Yup.number(),
            }),

            amount_off: Yup.number().when('type', {
              is: type => type === 'amount',
              then: Yup.number()
                .min(1, 'Please specify discount percent or discount amount'),
              otherwise: Yup.number(),
            }),
          })}
        >
          {
            props => {
              const {
                values,
                touched,
                errors,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              } = props
              return (
                <form onSubmit={handleSubmit}>

                  <div className="group is-flex fdr">
                    <div className="group" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Coupon Name* (this name will be displayed to user when coupon is applied)</div>
                      <input
                        name="name"
                        value={values.name}
                        className="stllr-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <span className="v-error">{errors.name}</span>
                      )}
                    </div>

                    <div className="group ml-20" style={{ width: "35%" }}>
                      <div className="teams-title mb-10">Promo Code* (This is the code that user enters to apply the coupon)</div>
                      <input
                        name="promo_code"
                        value={values.promo_code ? values.promo_code.toUpperCase().trim() : ''}
                        className="stllr-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.promo_code && touched.promo_code && (
                        <span className="v-error">{errors.promo_code}</span>
                      )}
                    </div>

                    <div className="group ml-20" style={{ width: "15%" }}>
                      <div className="teams-title mb-10">Active*</div>
                      <div className="is-flex fdr aic">
                        <div className="mr-10">Coupon is active?</div>
                        <Field
                          name='is_active'
                          component={Switch}
                          color='primary'
                          value={true}
                          defaultChecked={values.is_active}
                          onChange={e => setFieldValue('is_active', Number(e.target.checked))}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.is_active && touched.is_active && (
                        <span className="v-error">{errors.is_active}</span>
                      )}
                    </div>
                  </div>

                  <hr />

                  <div className="group" style={{ width: "50%" }}>
                    <div className="teams-title mb-10">Discount Type</div>
                    <Select
                      name='type'
                      options={typeOptions}
                      value={typeOptions?.find?.(e => e.value === values.type)}
                      placeholder="Select type"
                      onChange={({ value }) => setFieldValue('type', value)}
                      styles={{
                        control: base => ({
                          ...base,
                          height: 42,
                          minHeight: 42
                        })
                      }}
                    />
                    {errors.type && touched.type && (
                      <span className="v-error">{errors.type}</span>
                    )}
                  </div>

                  {values.type === 'percent' ? <div className="group is-flex fdr">
                    <div className="group" style={{ width: "50%" }}>
                      <div className="is-flex fdr aic jcsb">
                        <div className="teams-title mb-10">Discount Percent (%)</div>
                        <div>
                          {values.max_amount === null
                            ? <button className="stllr-btn primary btn-lg" type="button" onClick={() => setFieldValue('max_amount', 0)}>add max amount</button>
                            : <button className="stllr-btn danger btn-lg" type="button" onClick={() => setFieldValue('max_amount', null)}>clear max amount</button>
                          }
                        </div>
                      </div>
                      <input
                        type="number"
                        name="percent_off"
                        value={values.percent_off}
                        onChange={handleChange}
                        className="stllr-input"
                      />
                      {errors.percent_off && touched.percent_off && (
                        <span className="v-error">{errors.percent_off}</span>
                      )}
                    </div>

                    

                    {values.max_amount === null ? null : <>
                      <div className="group ml-20" style={{ width: "25%" }}>
                        <div className="teams-title mb-10">Maximum Discount Amount</div>
                        <input
                          type="number"
                          name="max_amount"
                          value={values.max_amount}
                          onChange={handleChange}
                          className="stllr-input"
                        />
                        {errors.max_amount && touched.max_amount && (
                          <span className="v-error">{errors.max_amount}</span>
                        )}
                      </div>
                      <div className="group ml-20" style={{ width: "25%" }}>
                        <div className="teams-title mb-10">Discount Amount Currency</div>
                        <Select
                          name='currency_id'
                          options={currencyOptions}
                          value={currencyOptions?.find?.(e => e.value === values.currency_id)}
                          isLoading={isCurrenciesLoading}
                          placeholder="Select currency"
                          onChange={({ value }) => setFieldValue('currency_id', value)}
                          styles={{
                            control: base => ({
                              ...base,
                              height: 42,
                              minHeight: 42
                            })
                          }}
                        />
                        {errors.currency_id && touched.currency_id && (
                          <span className="v-error">{errors.currency_id}</span>
                        )}
                      </div>
                    </>}
                  </div> : null}

                  {values.type === 'amount' ? <div className="group is-flex fdr">
                    <div className="group" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Discount Amount* ($)</div>
                      <input
                        type="number"
                        name="amount_off"
                        value={values.amount_off}
                        onChange={handleChange}
                        className="stllr-input"
                      />
                      {errors.amount_off && touched.amount_off && (
                        <span className="v-error">{errors.amount_off}</span>
                      )}
                    </div>

                    <div className="group ml-20" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Discount Amount Currency</div>
                      <Select
                        name='currency_id'
                        options={currencyOptions}
                        value={currencyOptions?.find?.(e => e.value === values.currency_id)}
                        isLoading={isCurrenciesLoading}
                        placeholder="Select currency"
                        onChange={({ value }) => setFieldValue('currency_id', value)}
                        styles={{
                          control: base => ({
                            ...base,
                            height: 42,
                            minHeight: 42
                          })
                        }}
                      />
                      {errors.currency_id && touched.currency_id && (
                        <span className="v-error">{errors.currency_id}</span>
                      )}
                    </div>
                  </div> : null}

                  <hr />

                  <div className="group is-flex fdr">
                    <div className="group" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Maximum Redemptions*</div>
                      <input
                        type="number"
                        name="max_redemptions"
                        value={values.max_redemptions}
                        max={100}
                        onChange={handleChange}
                        className="stllr-input"
                      />
                      {errors.max_redemptions && touched.max_redemptions && (
                        <span className="v-error">{errors.max_redemptions}</span>
                      )}
                    </div>

                    <div className="group ml-20" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Maximum Redemptions per user*</div>
                      <input
                        type="number"
                        name="max_redemptions_per_user"
                        value={values.max_redemptions_per_user}
                        max={100}
                        onChange={handleChange}
                        className="stllr-input"
                      />
                      {errors.max_redemptions_per_user && touched.max_redemptions_per_user && (
                        <span className="v-error">{errors.max_redemptions_per_user}</span>
                      )}
                    </div>
                  </div>

                  <div className="group is-flex fdr">
                    <div className="group" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Expiry Date</div>
                      <TextField
                        type="date"
                        name={`date_expire`}
                        size="small"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.date_expire}
                        fullWidth
                      />
                      {errors.date_expire && touched.date_expire && (
                        <span className="v-error">{errors.date_expire}</span>
                      )}
                    </div>

                    <div className="group ml-20" style={{ width: "50%" }}>
                      <div className="teams-title mb-10">Specific Client (optional)</div>
                      <Select
                        name="customer_id"
                        options={customers}
                        value={customers?.find?.(e => e.value === values.customer_id)}
                        isLoading={isCustomersLoading}
                        placeholder="specifiy customer"
                        onChange={(e) => setFieldValue("customer_id", e?.value)}
                        isClearable
                        styles={{
                          control: base => ({
                            ...base,
                            height: 42,
                            minHeight: 42
                          })
                        }}
                      />
                      {errors.customer_id && touched.customer_id && (
                        <span className="v-error">{errors.customer_id}</span>
                      )}
                    </div>
                  </div>

                  <div className="group is-flex aic jcfe fdr mt-45">
                    <Button
                      text="CREATE Coupon"
                      type="submit"
                      disabled={isSubmitting || isAdding}
                      className="stllr-btn primary btn-lg"
                    />
                  </div>
                </form>
              )
            }
          }
        </Formik>
      </div>
    </Container>
  )
}

export default NewCoupon