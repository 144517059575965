import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { fetchUser } from "../services/user"

const Auth = React.createContext()

const AuthProvider = props => {
    const [auth, setAuth] = useState(false)
    const [user, setUser] = useState({ isLoading: true })
    const token = Cookies.get("token")

    useEffect(() => {
        setAuth(token ? true : false)

        if (token) {
            // get user information
            fetchUser()
                .then(res => {
                    const user = res.data
                    if (user.roles.some(role => [1, 2].includes(role))) {
                        Cookies.remove("token")
                        Cookies.remove("token", { path: '/', domain: '.stllr.network' })
                        window.location.href = process.env.REACT_APP_STLLR_URL
                    }
                    else {
                        setUser(res.data)
                    }
                })
                .catch(e => setUser({ error: true }))
        }
    }, [token])

    // useEffect(() => {
    //     if (user && !user.isLoading) {
    //         if (user.roles.some(role => [1, 2].includes(role))) {
    //             // Cookies.remove("token")
    //             // Cookies.remove("token", { path: '/', domain: '.stllr.network' })
    //             window.location.href = process.env.REACT_APP_STLLR_URL
    //         }
    //     }
    // }, [user])

    if (token && user?.isLoading) {
        return 'loading..'
    }

    const authContextValue = { auth, setAuth, user }
    return <Auth.Provider value={authContextValue} {...props} />
}

const useAuth = () => React.useContext(Auth);

export { AuthProvider, useAuth }