import React, { useEffect, useMemo, useState } from "react"
import { Card, CircularProgress, Container, Grid } from "@material-ui/core"
import TableContainer from "../components/TableContainer"
import { useMutation, useQuery } from "react-query"
import { fetchWithdrawalRequests, getWithdrawalRequestPDF, updateTransactionById } from "../services/expert"
import Select from "react-select"
import DefaultFilter from "../components/DefaultFilter"
import SelectColumnFilter from "../components/SelectFilter"
import { CSVLink, CSVDownload } from "react-csv";
import { Button } from "../components/button"
import { BankDetails } from "../components/BankDetails"
import moment from 'moment'
import { fetchUser } from "../services"
import { ExpertTransactions } from "../components/ExpertTransactions"
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from "../utils/Auth"
import { WithdrawalIssueDetails } from "../components/WithdrawalIssueDetails"
import { WithdrawalIssueForm } from "../components/WIthdrawalIssueForm"
import { WithdrawalMissingForm } from "../components/WithdrawalMissingForm"

function WithdrawalRequests() {
  const { user } = useAuth()
  const [currentCat, setCurrentCat] = useState(null)
  const [bankTransactionId, setBankTransactionId] = useState(null)
  const [bankExpertId, setBankExpertId] = useState(null)
  const [transExpertId, setTransExpertId] = useState(null)
  const [issueTransaction, setIssueTransaction] = useState(null)
  const [reportTransaction, setReportTransaction] = useState(null)
  const [missingTransaction, setMissingTransaction] = useState(null)
  const [downloading, setDownloading] = useState(null)
  const [data, setData] = useState({ requests: [], stats: [] })

  const handleCloseBank = () => {
    setBankExpertId(null)
    setBankTransactionId(null)
  }

  const handleViewIssue = (transaction) => {
    setIssueTransaction(transaction)
  }

  const handleReportIssue = (transaction) => {
    setReportTransaction(transaction)
  }

  const handleReportMissingTransation = (data) => {
    setMissingTransaction(data)
  }

  const { isLoading, refetch } = useQuery(['requests'], () => fetchWithdrawalRequests(), {
    onSuccess: data => setData(data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    setData(data => (
      data.requests.filter(p => (
        !currentCat ||
        (currentCat === 'other' && !p.payment_method_id) ||
        p.payment_method_id == currentCat
      ))
    ))
  }, [currentCat])

  // const { data: user, isLoading: userLoading } = useQuery("user", fetchUser, {
  //   select: (data) => data.data,
  // })

  const { mutate: updateTransaction, isLoading: isUpdating } = useMutation(data => updateTransactionById(data), {
    onSuccess: data => {
      alert('Transaction updated successfully')
      refetch()
    },
    onError: error => {
      alert(error.data?.message)
    }
  })

  const getCSV = () => {
    const outputCSV =
      data.requests.filter(p => (
        !currentCat ||
        (currentCat === 'other' && !p.payment_method_id) ||
        p.payment_method_id == currentCat
      )).map(d => {
        const filteredValues = [d.expert_name, d.currency_code, d.amount, d.status_name, d.payment_method_name, moment(d.date_add).format('ll')]
        return filteredValues
      })
    outputCSV.unshift(["expert", "Currency", "Amount", "Status", "Payment Method", "Date"])
    return outputCSV
  }

  const downloadInvoice = async (transaction) => {
    try {
      const { id, expert_name, code, date_add } = transaction
      setDownloading(id)

      const data = await getWithdrawalRequestPDF(id)

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]))
      link.setAttribute('download', `${expert_name}_${code}_${moment(date_add).format('L')}.pdf`);
      link.click()

      setDownloading(null)
    }
    catch (e) {
      console.log(e)
      alert('Oops, something went wrong!')
      setDownloading(null)
    }
  }

  const payment_status = [
    { label: 'Pending', value: process.env.REACT_APP_TRANSACTION_PENDING_STATUS },
    { label: 'Transferred', value: process.env.REACT_APP_TRANSACTION_TRANSFERRED_STATUS },
    { label: 'On-Hold', value: process.env.REACT_APP_TRANSACTION_ONHOLD_STATUS },
    { label: 'Missing bank info', value: process.env.REACT_APP_TRANSACTION_MISSING_INFO_STATUS },
  ]

  const columns = useMemo(() => [
    {
      Header: 'Expert',
      accessor: `expert_name`,
      Filter: DefaultFilter,
      Cell: ({ row }) => {
        const { expert_id, expert_name } = row.original
        return (<div className="is-flex aic">{expert_name} <InfoIcon style={{ color: 'blue', marginLeft: 5, cursor: 'pointer' }} onClick={() => setTransExpertId(expert_id)} fontSize='small' /></div>)
      }
    },
    {
      Header: 'Latest project',
      accessor: `project_name`,
      disableFilters: true,
    },
    {
      Header: 'Currency',
      accessor: `currency_code`,
      Filter: SelectColumnFilter,
      Cell: ({ row }) => {
        const { currency_code } = row.original
        return `${currency_code}`
      }
    },
    {
      Header: 'Amount',
      accessor: `amount`,
      disableFilters: true,
      Cell: ({ row }) => {
        const { amount } = row.original
        return `${amount}`
      }
    },
    {
      Header: 'Code',
      accessor: `code`,
      disableFilters: true,
      Cell: ({ row }) => {
        const { code } = row.original
        return <div className="monospace">
          {code.toLowerCase()}
        </div>
      }
    },
    {
      Header: 'Status',
      accessor: `status_name`,
      Filter: SelectColumnFilter,
      filterValue: sessionStorage.getItem('status_name') || null,
      Cell: ({ row }) => {
        const { status_name, status_id, payment_method_id, id, expert_id } = row.original
        return process.env.REACT_APP_TRANSACTION_TRANSFERRED_STATUS != status_id &&
          process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER == payment_method_id &&
          user.role_id != process.env.REACT_APP_ASSISTANT_ADMIN_ROLE ? (
          <Select
            value={payment_status.find(s => s.value == status_id)}
            options={payment_status}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: 120,
                borderColor: status_id == process.env.REACT_APP_TRANSACTION_MISSING_INFO_STATUS || status_id == process.env.REACT_APP_TRANSACTION_ONHOLD_STATUS ? 'red' : 'lightgrey'
              }),
            }}
            onChange={e => {
              if (e.value != status_id) {
                if (e.value == process.env.REACT_APP_TRANSACTION_MISSING_INFO_STATUS) {
                  if (window.confirm(`Are you sure you want to change this request to ${e.label}? This will delete the withdrawal request (Make sure to delete it from Brex as well)`)) {
                    handleReportMissingTransation({ transactionId: id, expertId: expert_id })
                  } else {
                    e.preventDefault?.()
                  }
                } else {
                  if (window.confirm(`Are you sure you want to change this request to ${e.label}?`)) {
                    updateTransaction({ status_id: e.value, id, expert_id })
                  } else {
                    e.preventDefault?.()
                  }
                }
              }
            }}
            disabled={isUpdating}
            isLoading={isUpdating}
          />
        ) : (
          status_name
        )
      }
    },
    {
      Header: 'Payment method',
      accessor: `payment_method_name`,
      Filter: SelectColumnFilter,
    },
    {
      Header: 'Created date',
      accessor: `date_add`,
      disableFilters: true,
      Cell: ({ row }) => {
        const { date_add } = row.original
        return moment(date_add).format('ll')
      }
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { id, expert_id, has_issue } = row.original
        return (
          <div className="is-flex fdc aife">
            <button
              onClick={() => setBankTransactionId(id)}
              className="stllr-btn btn-sm primary bordered mb-5"
            >Withdrawal Bank Details</button>
            <button
              onClick={() => setBankExpertId(expert_id)}
              className="stllr-btn btn-sm primary bordered mb-5"
            >Expert Bank Details</button>

            <button
              onClick={() => downloadInvoice(row.original)}
              className="stllr-btn btn-sm secondary bordered mb-5"
              disabled={downloading === id}
            >Download Invoice</button>

            {has_issue
              ? <button
                onClick={() => handleViewIssue(row.original)}
                className="stllr-btn btn-sm alert bordered fw-7"
              >View issue</button>
              : <button
                onClick={() => handleReportIssue(row.original)}
                className="stllr-btn btn-sm danger bordered"
              >Report issue</button>
            }

          </div>
        )
      }
    },
  ])

  const getRowStyles = ({ original }) => {
    const { has_issue } = original
    return has_issue ? { backgroundColor: '#fee' } : {}
  }

  return (
    <Container spacing={1} maxWidth='lg'>
      <Grid container>
        {isLoading ? <CircularProgress /> : <Grid item lg={12}>
          <h2>Pening withdrawal requests</h2>
          {data.stats.map(stats => <CatRow data={stats} />)}

          <h2 className="mb-10">all withdrawal requests</h2>
          <TableContainer
            paging={true}
            columns={columns}
            hiddenColumns={[]}
            data={data.requests}
            hasFilters={true}
            getRowStyles={getRowStyles}
          />

          <div className="is-flex fdr jcfe mt-20">
            <CSVLink data={getCSV()} className="stllr-btn primary btn-md">EXPORT</CSVLink>
          </div>
        </Grid>}
      </Grid>
      <BankDetails
        transactionId={bankTransactionId}
        expertId={bankExpertId}
        onClose={handleCloseBank}
      />
      <ExpertTransactions
        expertId={transExpertId}
        onClose={() => setTransExpertId(null)}
      />
      <WithdrawalIssueDetails
        transaction={issueTransaction}
        onClose={() => setIssueTransaction(null)}
        onSuccess={() => refetch()}
      />
      <WithdrawalIssueForm
        transaction={reportTransaction}
        onClose={() => setReportTransaction(null)}
        onSuccess={() => refetch()}
      />
      <WithdrawalMissingForm
        info={missingTransaction}
        onClose={() => setMissingTransaction(null)}
        onSuccess={() => refetch()}
      />
    </Container>
  )
}


const CatRow = ({ data }) => {

  return (
    <div className="is-flex fdr aic jcsa mt-20 mb-55">
      <CatBox
        label='Total'
        value={data.total}
        currency={data.currency_symbol}
      // active={currentCat === null}
      // onClick={() => setCurrentCat(null)}
      />
      <CatBox
        label='Stripe'
        value={data.stripe}
        currency={data.currency_symbol}
      // active={currentCat === process.env.REACT_APP_PAYMENT_METHOD_STRIPE}
      // onClick={() => setCurrentCat(process.env.REACT_APP_PAYMENT_METHOD_STRIPE)}
      />
      <CatBox
        label='Paypal'
        value={data.paypal}
        currency={data.currency_symbol}
      // active={currentCat === process.env.REACT_APP_PAYMENT_METHOD_PAYPAL}
      // onClick={() => setCurrentCat(process.env.REACT_APP_PAYMENT_METHOD_PAYPAL)}
      />
      <CatBox
        label='Bank Transfer'
        value={data.bank}
        currency={data.currency_symbol}
      // active={currentCat === process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER}
      // onClick={() => setCurrentCat(process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER)}
      />
      <CatBox
        label='Other'
        value={data.other}
        currency={data.currency_symbol}
      // active={currentCat === 'other'}
      // onClick={() => setCurrentCat('other')}
      />
    </div>
  )
}

const CatBox = ({ label, value, currency, active, ...props }) => {
  return (
    <Card
      {...props}
      className={`payouts-box ${active && 'selected'}`}
    >
      {label}: <span>{currency}{value?.toFixed(2)}</span>
    </Card>
  )
}

export default WithdrawalRequests