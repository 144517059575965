import React, { useState } from 'react'
import { Avatar, Checkbox, Dialog, DialogContent, FormControlLabel, FormLabel, FormGroup, TextField } from "@material-ui/core"
import { DialogTitle } from '@mui/material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button } from './index'
import { Formik } from 'formik';
import * as Yup from "yup"

export const NewMeetingModal = ({ open, handleClose, onSubmit, members, ...props }) => {

    const handleSubmit = (values) => {
        const endDate = new Date(values.start.getTime() + (60 * 60 * 1000))
        const data = {
            ...values,
            attendees: values.attendees.map(a => JSON.parse(a)),
            start: values.start.toISOString(),
            end: endDate.toISOString(),
        }
        // console.log(data)
        onSubmit(data)
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle>
                Select Meeting Time
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{ summary: '', description: '', attendees: [], start: '', end: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                        summary: Yup.string().required('Please specify a short summary'),
                        // description: Yup.string().required('Please specify a short description'),
                        attendees: Yup.array().min(1, 'Please select at lease one attendee'),
                        start: Yup.mixed().required('Please specify start date'),
                    })}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <div className='group'>
                                <TextField
                                    name='summary'
                                    placeholder='Meeting summary'
                                    className='stllr-input'
                                    value={props.values.summary}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                {props.errors.summary && props.touched.summary && (
                                    <span className="v-error">{props.errors.summary}</span>
                                )}
                            </div>
                            <div className='group'>
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            name='start'
                                            label="Start date/time"
                                            value={props.values.start}
                                            onChange={date => props.setFieldValue('start', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {props.errors.start && props.touched.start && (
                                    <span className="v-error">{props.errors.start}</span>
                                )}
                            </div>
                            <div className='group'>
                                <FormLabel>Attendees</FormLabel>
                                <FormGroup
                                    className='checkboxBordered'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                >
                                    {members.map(m => (
                                        <FormControlLabel
                                            value={JSON.stringify(m)}
                                            label={<div className='is-flex fdr aic'>
                                                <Avatar src={m.avatar} alt={m.name} style={{ width: 25, height: 25 }} />
                                                <div className='ml-10'>{m.name}</div>
                                            </div>}
                                            control={<Checkbox name={'attendees'} />}
                                        />
                                    ))}
                                </FormGroup>
                                {JSON.stringify(props.values.attendees)}
                                {props.errors.attendees && props.touched.attendees && (
                                    <span className="v-error">{props.errors.attendees}</span>
                                )}
                            </div>

                            <Button
                                text="SUBMIT"
                                type="submit"
                                className="stllr-btn primary btn-lg"
                            />
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}