import Cookies from "js-cookie"

const getToken = () => {
    return Cookies.get("token")
}

const setToken = (name, opts) => {
    Cookies.set(name, {domain: `.admin.stllr.io`, ...opts})
}

const signout = () => {
    Cookies.remove("token")
    window.location.href = process.env.REACT_APP_STLLR_ADMIN_URL
}

export { getToken, setToken, signout }