import React, { useEffect, useState, useRef } from "react"
import * as Yup from "yup"
import { v4 as uuidv4 } from 'uuid'
import { useHistory, useLocation, useParams } from "react-router"
import { useQuery, QueryClient, useMutation } from "react-query"
import { Button, TeamMilestoneDialog } from "../components";
import { Formik, Field } from "formik"
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Avatar, IconButton, Divider, Dialog, DialogContent, Switch, TextField } from "@material-ui/core"
import { DataGrid } from '@material-ui/data-grid';
import { editTeam, fetchTeams, fetchExpertsByEmail, createTeam, fetchExpert } from "../services"
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Select from "react-select"
import TableContainer from "../components/TableContainer"
import { Editor } from '@tinymce/tinymce-react'
import HtmlParser from 'react-html-parser';
import { Close as RemoveIcon, Edit } from "@material-ui/icons"
import qs from 'qs'
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete'

function EditTeam() {
    const [experts, setExperts] = useState([])
    const [leaderOptions, setLeaderOptions] = useState([])
    const [email, setEmail] = useState('')
    const [cover, setCover] = useState();
    const [errorMsg, setErrorMsg] = useState(false)
    const { search } = useLocation()
    const { new_expert, tab } = qs.parse(search, { ignoreQueryPrefix: true });
    const history = useHistory();
    const [services, setServices] = useState([])
    const [currentService, setCurrentService] = useState(null)
    const [openMilestoneForm, setOpenMilestoneForm] = useState(null)
    const [milestones, setMilestones] = useState([])
    const fileRef = useRef();

    const { id: teamId } = useParams()

    const { data: team, isLoading: isTeamLoading } = useQuery("team", () => fetchTeams(teamId), {
        select: (data) => data.teams,
        onSuccess: data => {
            setExperts(data.experts.map(e => ({ ...e, hourly_rate: e.hourly_rate })))
            setMilestones(data.milestones)
            data.experts.map(e => {
                leaderOptions.push({ label: e.name, value: e.id })
            })
            if (new_expert) {
                sendInvite(new_expert)
            }
        },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
    })

    useEffect(() => {
        setServices(() => {
            return experts
                .map(e => e.services || [])
                .flat()
                .reduce((a, c) => a?.find(e => c && e?.service_id === c?.service_id) ? a : [...a, c], [])
                .map(s => ({
                    label: s.service_name,
                    value: s.service_id
                }))
        })
    }, [experts])

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            reject();
        };
    })

    const { data: expert } = useQuery(['expert'], () => fetchExpert(), {
        select: res => res.expert,
        onSuccess: expert => {
            handleAddExpert(expert)
            if (new_expert) {
                sendInvite(new_expert)
            }
        }
    })

    const { mutate: sendInvite, isLoading: isSending } = useMutation(data => fetchExpertsByEmail(data), {
        select: (data) => data.experts[0],
        onSuccess: (data) => {
            let expert = data.experts[0]
            if (expert) {
                handleAddExpert(expert)
            } else {
                alert("Expert doesn't exist")
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { mutate: updateTeam, isLoading: isSubmitting } = useMutation(data => editTeam(data, teamId), {
        onSuccess: (data) => {
            alert('Team is updated successfully')
            history.push(`/team/${teamId}`);
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const constructMemberObject = expert => ({
        id: expert.id,
        name: expert.name,
        avatar: expert.avatar,
        hourly_rate: expert.hourly_rate,
        tier: expert.tier_name,
        services: expert.services,
        email: expert.email,
    })

    const [durations] = useState(() => {
        let durationsArr = [];
        for (let i = 1; i <= 12; i++) {
            durationsArr.push({ label: i, value: i });
        }
        return durationsArr;
    })
    const otherInputRef = useRef({})

    const handleAddExpert = (expert) => {
        if (experts.find(e => e.email === expert.email)) {
            return
        }
        setExperts(experts => {
            experts.push(constructMemberObject(expert))
            leaderOptions.push({ label: expert.name, value: expert.id })
            return experts
        })
    }

    const handleUpdateHours = (expert, hours) => {
        setExperts(experts => experts.map(e => {
            if (e.email === expert.email) {
                e.hours = hours
            }
            return e
        }))
    }

    const handleRemoveExpert = (expert) => {
        setExperts(experts => experts.filter(e => e.email !== expert.email))
        setLeaderOptions(experts => experts.filter(e => e.value !== expert.id))
    }

    const handleOnInviteClick = (event) => {
        event.preventDefault()
        if (email) {
            sendInvite(email)
        }
    }

    const handleOnMilestoneSubmit = ({ title, description }) => {
        const newMilestone = {
            id: Math.random(),
            title,
            description,
            service_id: currentService.value,
            service_name: currentService.label,
        }

        setMilestones(milestones => [...milestones, newMilestone])
        setOpenMilestoneForm(false)
    }

    const removeMilestone = (milestoneId) => {
        setMilestones(milestones => milestones.filter(m => m.id !== milestoneId))
    }

    if (isTeamLoading) {
        return (
            <div className='is-flex aic jcc mt-45 mb-45'>
                Loading...
            </div>
        );
    }

    return (
        <Container spacing={1} maxWidth='xl'>
            <div className="content-wrapper mb-55">
                <h3 className="mb-20 is-flex fw7">Add information about the team</h3>
                <Formik
                    initialValues={{
                        name: team.name,
                        duration: team.duration,
                        description: team.description,
                        hours: team.hours,
                        is_earnings_dispatched: team.is_payout_dispatched,
                        expert_creator_id: team.expert_creator_id
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            values.experts = experts
                            values.milestones = milestones
                            const fd = new FormData();
                            fd.append('cover', values.cover);
                            fd.append('data', JSON.stringify(values));
                            setSubmitting(false)
                            if (experts.length < 3) {
                                alert("Minimum team should be of 3 members")
                            }
                            else {
                                updateTeam(fd)
                            }
                        }, 500)
                    }}
                    validate={values => {
                        const errors = {}
                        if (experts.some(e => !e.hours || e.hours <= 0)) {
                            errors.experts = 'There are missing hours'
                        }
                        return errors
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required("Please specify a name"),
                        duration: Yup.string()
                            .required("Please specify a duration"),
                        description: Yup.string()
                            .required("Please add a description"),
                        is_earnings_dispatched: Yup.number(),
                        expert_creator_id: Yup.string()
                            .required("Please select a team leader"),
                    })}
                >
                    {
                        props => {
                            const {
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="group is-flex fdc aic jcc">
                                        <div
                                            style={{ backgroundColor: "#DCDDE0", cursor: "pointer", borderRadius: "8px 8px 0 0", height: 200 }}
                                            onClick={() => {
                                                fileRef.current.click();
                                            }}
                                            className="has-full-width is-flex aic jcc fdc"
                                        >
                                            {cover || team.cover ? (
                                                <div
                                                    style={{ backgroundImage: `url(${cover || team.cover})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: 200 }}
                                                    className="has-full-width"
                                                />
                                            )
                                                :
                                                (<h3 className="has-text-centered">
                                                    Upload cover photo for the team*
                                                </h3>)
                                            }

                                            <input
                                                ref={fileRef}
                                                type="file"
                                                name="cover"
                                                className="hidden"
                                                onChange={(event) => {
                                                    const ev = event.currentTarget.files;
                                                    console.log("ev:", ev)
                                                    if (ev) {
                                                        if (ev.length === 0) {
                                                            return;
                                                        }
                                                        const img = document.createElement("img");
                                                        img.onload = function () {
                                                            if (img.width / img.height > 1.5) {
                                                                setFieldValue("cover", ev[0]);
                                                                getBase64(ev[0]).then(data => {
                                                                    setCover(data);
                                                                    setErrorMsg(false)
                                                                });
                                                            } else {
                                                                setErrorMsg(true)
                                                            }
                                                        }
                                                        img.src = URL.createObjectURL(ev[0])
                                                    }
                                                }}
                                            />
                                        </div>
                                        {errorMsg && <div style={{ background: "#DC1B1B", borderRadius: "0 0 8px 8px", height: 50, color: "#FFF" }}
                                            className="has-full-width is-flex aic jcc">
                                            You can only upload a team cover photo with a rectangular dimension
                                        </div>}

                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Team Name* (example: launch new ecommerce website, grow your paid leads)</div>
                                            <input
                                                ref={otherInputRef}
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                className="stllr-input"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.name && touched.name && (
                                                <span className="v-error">{errors.name}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Payment Options*</div>
                                            <div className="is-flex fdr aic">
                                                <div className="mr-10">Dispatch Project’s Earnings</div>
                                                <Field
                                                    name='is_earnings_dispatched'
                                                    component={Switch}
                                                    color='primary'
                                                    value={true}
                                                    defaultChecked={values.is_earnings_dispatched}
                                                    onChange={e => setFieldValue('is_earnings_dispatched', Number(e.target.checked))}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {errors.is_earnings_dispatched && touched.is_earnings_dispatched && (
                                                <span className="v-error">{errors.is_earnings_dispatched}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Description*</div>
                                            <textarea
                                                ref={otherInputRef}
                                                type="text"
                                                name="description"
                                                value={values.description}
                                                className="stllr-input"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.description && touched.description && (
                                                <span className="v-error">{errors.description}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group">

                                    </div>

                                    <div className="group is-flex fdr">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Project duration (Months)*</div>
                                            <Select
                                                name="duration"
                                                options={durations}
                                                value={durations.find(b => b.value === values.duration)}
                                                onChange={val => setFieldValue('duration', val.value)}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                            {errors.duration && touched.duration && (
                                                <span className="v-error">{errors.duration}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="group is-flex jcsb fdc" style={{ width: "50%" }}>
                                        <h3 className="mb-10 is-flex fw7">Members</h3>
                                        <div className="teams-title">Team Member Email</div>
                                        <div className="is-flex fdr">
                                            <input
                                                type="email"
                                                value={email}
                                                className="stllr-input mr-10"
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            <Button
                                                text="ADD"
                                                onClick={handleOnInviteClick}
                                                className="stllr-btn primary btn-lg"
                                                disabled={isSending}
                                            />
                                        </div>
                                        {errors.email && touched.email && (
                                            <span className="v-error">{errors.email}</span>
                                        )}
                                    </div>

                                    <Grid item lg={12}>
                                        <div style={{ padding: "0px 35px 16px 35px" }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Role</TableCell>
                                                        <TableCell>Number of Hours</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {experts.map(e => (
                                                        <TableRow>
                                                            <TableCell>
                                                                <div className='is-flex fdr aic'>
                                                                    <Avatar src={e.avatar} style={{ width: 30, height: 30 }} />
                                                                    <h3 style={{ flexShrink: 0 }} className="ml-10 mr-10">{e.name}</h3>
                                                                    {e.is_creator === 1 &&
                                                                        <img src={`${process.env.REACT_APP_STLLR_STATIC_URL}blue-flag.svg`} alt="" />
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div className='is-flex fdr aic'>
                                                                    <div className={`tier-circle ${e.tier_name?.toLowerCase()}`}></div>
                                                                    <div style={{ marginLeft: 9 }}>{e.tier_name || e.tier}</div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {e.services ? e.services.map(service => (
                                                                    <span className="service-chip">{service.service_name}</span>
                                                                )) : 'Pending'}
                                                            </TableCell>
                                                            <TableCell>
                                                                <input
                                                                    type="number"
                                                                    placeholder="Hours"
                                                                    value={e.hours || 0}
                                                                    className="stllr-input"
                                                                    onChange={event => handleUpdateHours(e, event.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton
                                                                    onClick={() => handleRemoveExpert(e)}
                                                                >
                                                                    <RemoveIcon style={{ color: 'red' }} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        {errors.experts && (
                                            <span className="v-error">{errors.experts}</span>
                                        )}
                                        <div className="group" style={{ width: "30%" }}>
                                            <div className="teams-title mb-10">Team Leader</div>
                                            <Select
                                                name="duration"
                                                options={leaderOptions}
                                                // value={experts.find(b => b.value === values.expert_creator_id)}
                                                onChange={val => setFieldValue('expert_creator_id', val.value)}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                            {errors.expert_creator_id && touched.expert_creator_id && (
                                                <span className="v-error">{errors.expert_creator_id}</span>
                                            )}
                                        </div>
                                    </Grid>

                                    <div className="group is-flex jcsb fdc">
                                        <h3 className="mb-10 is-flex fw7">Scope of work</h3>
                                        <h5 className="mb-10 dimmed">Automatically add scope of work when you choose a service and edit them if needed </h5>
                                    </div>

                                    <div className="group is-flex fdr aic jcsb">
                                        <div className="group" style={{ width: "50%" }}>
                                            <div className="teams-title mb-10">Service*</div>
                                            <Select
                                                options={services}
                                                value={services.find(s => s.value === currentService?.value)}
                                                onChange={val => setCurrentService(val)}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <button
                                            onClick={e => {
                                                e.preventDefault()
                                                if (!currentService) {
                                                    alert('You must select a service first')
                                                }
                                                else {
                                                    setOpenMilestoneForm(true)
                                                }
                                            }}
                                            className="stllr-btn link btn-lg"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <AddIcon style={{ fill: 'blue' }} className='mr-5' />
                                            Create a new scope of work
                                        </button>
                                    </div>

                                    <Grid item lg={12}>
                                        <Table>
                                            <TableBody>
                                                {milestones.length ? milestones.map(m => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className='is-flex fdr aic jcsb'>
                                                                <div style={{ width: '75%' }}>
                                                                    <h3 className="has-text-bold">{m.title}</h3>
                                                                    <p>{HtmlParser(m.description)}</p>
                                                                </div>
                                                                <div>
                                                                    <span className="service-chip">{m.service_name}</span>
                                                                </div>
                                                                <div>
                                                                    <IconButton onClick={() => removeMilestone(m.id)}>
                                                                        <DeleteIcon style={{ color: "#E02020" }} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )) : <div className="is-flex fdr aic jcc has-text-centered">
                                                    No scope of work added yet.
                                                </div>}
                                            </TableBody>
                                        </Table>
                                    </Grid>

                                    <div className="group is-flex aic jcfe fdr mt-45">
                                        <Button
                                            text="EDIT TEAM"
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="stllr-btn primary btn-lg"
                                        />
                                    </div>
                                </form>
                            )
                        }
                    }
                </Formik>
            </div>
            <TeamMilestoneDialog
                open={openMilestoneForm}
                onClose={() => setOpenMilestoneForm(false)}
                onSubmit={handleOnMilestoneSubmit}
            />
        </Container>
    )
}

export default EditTeam