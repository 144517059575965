import React, { useState } from "react"
import { useQuery, useMutation } from "react-query"
import {
    Grid,
    Container,
    CircularProgress,
} from "@material-ui/core"
import { Rating } from '@mui/material'
import { useParams } from "react-router-dom"
import {
    fetchAllExperts,
    fetchUser,
    submitScore,
    updateScore,
} from "../services"
import { Formik } from 'formik'
import Star from '@material-ui/icons/StarBorder'
import StarFilled from '@material-ui/icons/Star'
import { Divider } from "material-ui-core"
import moment from 'moment'
import { useAuth } from "../utils/Auth"
import Select from "react-select"

function ScoreExpert() {
    const { user } = useAuth()
    const { id: expertId } = useParams()

    const personaOptions = [
        { label: 'Serious', value: 'serious' },
        { label: 'Funny', value: 'funny' },
        { label: 'Cool', value: 'cool' },
        { label: 'Model', value: 'model' },
        { label: 'Charismatic', value: 'charismatic' },
        { label: 'Energetic', value: 'energetic' },
        { label: 'Engaging', value: 'engaging' },
        { label: 'Stylish', value: 'stylish' },
        { label: 'Passionate', value: 'passionate' },
        { label: 'Creative', value: 'creative' },
        { label: 'Satirical', value: 'satirical' },
        { label: 'Instructive', value: 'instructive' },
    ]

    const { data: expert, isLoading } = useQuery("expert", () => fetchAllExperts(expertId), {
        select: data => data.experts[0]
    })

    // const { data: user, isUserLoading } = useQuery('user', () => fetchUser(), {
    //     select: res => res.data
    // })

    const { mutate: submitScoreData, isLoading: isScoring } = useMutation(data => submitScore(data, expertId), {
        onSuccess: data => {
            if (data?.status) {
                alert("Score sumbitted successfully")
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { mutate: updateScoreData, isLoading: isScoreUpdating } = useMutation(data => updateScore(data, expertId), {
        onSuccess: data => {
            if (data?.status) {
                alert("Score updated successfully")
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const handleSubmitScore = (values, key) => {
        let data

        if (key === "personal_skills" || key === "technical_skills") {
            data = {
                score: values,
                total: Object.values(values).reduce((a, c) => a + c, 0),
                date_add: Date.now(),
                modified: Date.now(),
            }
            if (expert[key]) {
                data.date_add = expert[key].date_add;
            }
        } else {
            data = {
                values: values,
            }
        }


        // if there is already scores, update!!
        // if (Boolean(expert.personal_skills && expert.technical_skills)) {
        //     updateScoreData({ [key]: data })
        // }
        // else {
            submitScoreData({ [key]: data });
        // }
    }

    if (isLoading) return <div>Loading...</div>

    const { personal_skills = {}, technical_skills = {}, personas = {}, notes = '' } = expert;
    const isPersonalEditable = user?.email === process.env.REACT_APP_PERSONAL_SKILLS_EDITOR || user?.email === process.env.REACT_APP_NONO_ADMIN_EMAIL;
    const isTechnicalEditable = user?.email === process.env.REACT_APP_TECHNICAL_SKILLS_EDITOR || user?.email === process.env.REACT_APP_NONO_ADMIN_EMAIL;

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb mb-5 mt-20">
                        <h2 className="">Score expert <span style={{ fontSize: 18 }}>"{expert.name}"</span></h2>
                    </div>

                    <Grid container spacing={8}>
                        <Grid item lg={6}>
                            <Formik
                                initialValues={{
                                    video_quality: 0,
                                    following_instructions: 0,
                                    speed_of_delivery: 0,
                                    ...(technical_skills?.score || {}),
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                        handleSubmitScore(values, 'technical_skills')
                                    }, 500)
                                }}
                            >
                                {props => {
                                    return (
                                        <form onSubmit={props.handleSubmit}>
                                            <h2>Technical skills</h2>
                                            <p className='mb-20'>{technical_skills?.modified && (
                                                `Last modified: ${moment(technical_skills?.modified).format('lll')}`
                                            )}</p>
                                            <RatingInput
                                                label='Video quality'
                                                name='video_quality'
                                                formikProps={props}
                                            // readOnly={!isTechnicalEditable}
                                            />
                                            <RatingInput
                                                label='Following instructions (meets client requirements in brief)'
                                                name='following_instructions'
                                                formikProps={props}
                                            // readOnly={!isTechnicalEditable}
                                            />
                                            <RatingInput
                                                label='Speed of delivery'
                                                name='speed_of_delivery'
                                                formikProps={props}
                                            // readOnly={!isTechnicalEditable}
                                            />
                                            <Divider />
                                            <TotalScore formikProps={props} />

                                            <div className='is-flex jcc aic mt-45'>
                                                <button
                                                    type='submit'
                                                    className='stllr-btn primary btn-lg'
                                                    disabled={isScoring || isScoreUpdating}
                                                >SUBMIT</button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                        <Grid item lg={6}>
                            <Formik
                                initialValues={{
                                    responsiveness: 0,
                                    availability: 0,
                                    keen_to_work: 0,
                                    flexibility: 0,
                                    ...(personal_skills?.score || {}),
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                        handleSubmitScore(values, 'personal_skills')
                                    }, 500)
                                }}
                            >
                                {props => {
                                    return (
                                        <form onSubmit={props.handleSubmit}>
                                            <h2>Personal Skills</h2>
                                            <p className='mb-20'>{personal_skills?.modified && (
                                                `Last modified: ${moment(personal_skills?.modified).format('lll')}`
                                            )}</p>
                                            <RatingInput
                                                label='Responsiveness'
                                                name='responsiveness'
                                                formikProps={props}
                                            // readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='Availability'
                                                name='availability'
                                                formikProps={props}
                                            // readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='keen to work'
                                                name='keen_to_work'
                                                formikProps={props}
                                            // readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='Flexibility'
                                                name='flexibility'
                                                formikProps={props}
                                            // readOnly={!isPersonalEditable}
                                            />
                                            <Divider />
                                            <TotalScore formikProps={props} />

                                            <div className='is-flex jcc aic mt-45'>
                                                <button
                                                    type='submit'
                                                    className='stllr-btn primary btn-lg'
                                                    disabled={isScoring || isScoreUpdating}
                                                >SUBMIT</button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                        <Grid item lg={6}>
                            <Formik
                                initialValues={{
                                    personas: personas || [],
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                        handleSubmitScore(values, 'personas')
                                    }, 500)
                                }}
                            >
                                {props => {
                                    return (
                                        <form onSubmit={props.handleSubmit}>
                                            <h2>Personas</h2>

                                            <Select
                                                options={personaOptions}
                                                value={personaOptions.filter(persona => props.values.personas?.includes(persona.value)) || null}
                                                onChange={value => {
                                                    const selectedValues = value ? value.map(v => v.value) : [];
                                                    props.setFieldValue('personas', selectedValues);
                                                }}
                                                isMulti
                                            />

                                            <div className='is-flex jcc aic mt-45'>
                                                <button
                                                    type='submit'
                                                    className='stllr-btn primary btn-lg'
                                                    disabled={isScoring || isScoreUpdating}
                                                >SUBMIT</button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                        <Grid item lg={6}>
                            <Formik
                                initialValues={{
                                    notes: notes || ''
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                        handleSubmitScore(values, 'notes')
                                    }, 500)
                                }}
                            >
                                {props => {
                                    return (
                                        <form onSubmit={props.handleSubmit}>
                                            <h2>Notes</h2>

                                            <textarea
                                                name="notes"
                                                value={props.values.notes}
                                                onChange={props.handleChange}
                                            ></textarea>

                                            <div className='is-flex jcc aic mt-45'>
                                                <button
                                                    type='submit'
                                                    className='stllr-btn primary btn-lg'
                                                    disabled={isScoring || isScoreUpdating}
                                                >SUBMIT</button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const RatingInput = ({ label, name, formikProps, readOnly, ...props }) => {
    const { values, setFieldValue } = formikProps;
    const [hover, setHover] = useState(null);

    return (
        <Grid container>
            <Grid item lg={5}>
                <h4 className="fw7 mr-15">{label}</h4>
            </Grid>
            <Grid item lg={7} className='is-flex fdr aic jcfs'>
                <Rating
                    name="communication"
                    defaultValue={values[name]}
                    icon={<StarFilled style={{ color: '#fcbb08' }} />}
                    emptyIcon={<Star style={{ color: '#f2b407' }} />}
                    onChange={(e, value) => setFieldValue(name, value)}
                    onChangeActive={(e, v) => setHover(v >= 0 ? v : null)}
                    max={10}
                    readOnly={readOnly}
                    {...props}
                />
                <div className='mb-10 ml-10'>{hover || values[name] || 0}</div>
            </Grid>
        </Grid>
    )
}

const TotalScore = ({ formikProps }) => {
    const { values } = formikProps;

    return (
        <Grid container>
            <Grid item lg={5}>
                <h4 className="fw7 mr-15">Total Score</h4>
            </Grid>
            <Grid item lg={7} className='is-flex fdr aic jcfs'>
                {Object.values(values).reduce((a, c) => a + parseInt(c), 0)}
                /
                {Object.keys(values).length * 10}
            </Grid>
        </Grid>
    )
}

export default ScoreExpert