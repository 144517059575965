import React, { useState } from "react"
import { useQuery, useMutation } from "react-query"
import {
    Grid,
    Container,
    CircularProgress,
} from "@material-ui/core"
import { Rating } from '@mui/material'
import { useParams } from "react-router-dom"
import {
    fetchUnscorredExperts,
    fetchUser,
    submitScore,
    updateScore,
} from "../services"
import { Formik } from 'formik'
import Star from '@material-ui/icons/StarBorder'
import StarFilled from '@material-ui/icons/Star'
import { Divider } from "material-ui-core"
import moment from 'moment'
import { useAuth } from "../utils/Auth"

function VetterScore() {
    const { user } = useAuth()
    const { id: expertId } = useParams()

    const { data: expert, isLoading } = useQuery("expert", () => fetchUnscorredExperts(expertId), {
        select: data => data.experts[0]
    })

    // const { data: user, isUserLoading } = useQuery('user', () => fetchUser(), {
    //     select: res => res.data
    // })

    const { mutate: submitScoreData, isLoading: isScoring } = useMutation(data => submitScore(data, expertId), {
        onSuccess: data => {
            if (data?.status) {
                alert("Score submitted successfully")
                window.location.href = "/unscorred-experts"
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { mutate: updateScoreData, isLoading: isScoreUpdating } = useMutation(data => updateScore(data, expertId), {
        onSuccess: data => {
            if (data?.status) {
                alert("Score updated successfully")
                window.location.href = "/unscorred-experts"
            }
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const handleSubmitScore = (values, key) => {
        let data = {
            score: values,
            total: Object.values(values).reduce((a, c) => a + c, 0),
            date_add: Date.now(),
            modified: Date.now(),
        }

        if (expert[key]) {
            data.date_add = expert[key].date_add;
        }

        // if there is already scores, update!!
        if (Boolean(expert.personal_skills && expert.technical_skills)) {
            updateScoreData({ [key]: data })
        }
        else {
            submitScoreData({ [key]: data });
        }
    }

    if (isLoading) return <div>Loading...</div>

    const { personal_skills = {}, technical_skills = {} } = expert;
    const isPersonalEditable = user?.email === process.env.REACT_APP_PERSONAL_SKILLS_EDITOR;
    const isTechnicalEditable = user?.email === process.env.REACT_APP_TECHNICAL_SKILLS_EDITOR;

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb mb-5 mt-20">
                        <h2 className="">Score expert <span style={{ fontSize: 18 }}>"{expert.name}"</span></h2>
                    </div>

                    <Grid container spacing={8}>
                        <Grid item lg={6}>
                            <Formik
                                initialValues={{
                                    results: 0,
                                    focus_industry: 0,
                                    attribution_models: 0,
                                    growth_knowledge: 0,
                                    cross_marketing_knowledge: 0,
                                    ...(technical_skills?.score || {}),
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                        handleSubmitScore(values, 'technical_skills')
                                    }, 500)
                                }}
                            >
                                {props => {
                                    return (
                                        <form onSubmit={props.handleSubmit}>
                                            <h2>Technical skills</h2>
                                            <p className='mb-20'>{technical_skills?.modified && (
                                                `Last modified: ${moment(technical_skills?.modified).format('lll')}`
                                            )}</p>
                                            <RatingInput
                                                label='Results'
                                                name='results'
                                                formikProps={props}
                                                readOnly={!isTechnicalEditable}
                                            />
                                            <RatingInput
                                                label='Focus industry'
                                                name='focus_industry'
                                                formikProps={props}
                                                readOnly={!isTechnicalEditable}
                                            />
                                            <RatingInput
                                                label='Attribution models'
                                                name='attribution_models'
                                                formikProps={props}
                                                readOnly={!isTechnicalEditable}
                                            />
                                            <RatingInput
                                                label='Growth knowledge'
                                                name='growth_knowledge'
                                                formikProps={props}
                                                readOnly={!isTechnicalEditable}
                                            />
                                            <RatingInput
                                                label='Cross-marketing knowledge'
                                                name='cross_marketing_knowledge'
                                                formikProps={props}
                                                readOnly={!isTechnicalEditable}
                                            />
                                            <Divider />
                                            <TotalScore formikProps={props} />

                                            <div className='is-flex jcc aic mt-45'>
                                                {isTechnicalEditable && <button
                                                    type='submit'
                                                    className='stllr-btn primary btn-lg'
                                                    disabled={isScoring || isScoreUpdating}
                                                >SUBMIT</button>}
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                        <Grid item lg={6}>
                            <Formik
                                initialValues={{
                                    communication: 0,
                                    teamwork: 0,
                                    behavior: 0,
                                    commitment: 0,
                                    language: 0,
                                    ...(personal_skills?.score || {}),
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                        handleSubmitScore(values, 'personal_skills')
                                    }, 500)
                                }}
                            >
                                {props => {
                                    return (
                                        <form onSubmit={props.handleSubmit}>
                                            <h2>Personal Skills</h2>
                                            <p className='mb-20'>{personal_skills?.modified && (
                                                `Last modified: ${moment(personal_skills?.modified).format('lll')}`
                                            )}</p>
                                            <RatingInput
                                                label='Communication'
                                                name='communication'
                                                formikProps={props}
                                                readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='Teamwork'
                                                name='teamwork'
                                                formikProps={props}
                                                readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='Behavior'
                                                name='behavior'
                                                formikProps={props}
                                                readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='Commitment/Availability'
                                                name='commitment'
                                                formikProps={props}
                                                readOnly={!isPersonalEditable}
                                            />
                                            <RatingInput
                                                label='Language'
                                                name='language'
                                                formikProps={props}
                                                readOnly={!isPersonalEditable}
                                            />
                                            <Divider />
                                            <TotalScore formikProps={props} />

                                            <div className='is-flex jcc aic mt-45'>
                                                {isPersonalEditable && <button
                                                    type='submit'
                                                    className='stllr-btn primary btn-lg'
                                                    disabled={isScoring || isScoreUpdating}
                                                >SUBMIT</button>}
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const RatingInput = ({ label, name, formikProps, readOnly, ...props }) => {
    const { values, setFieldValue } = formikProps;
    const [hover, setHover] = useState(null);

    return (
        <Grid container>
            <Grid item lg={5}>
                <h4 className="fw7 mr-15">{label}</h4>
            </Grid>
            <Grid item lg={7} className='is-flex fdr aic jcfs'>
                <Rating
                    name="communication"
                    defaultValue={values[name]}
                    icon={<StarFilled style={{ color: '#fcbb08' }} />}
                    emptyIcon={<Star style={{ color: '#f2b407' }} />}
                    onChange={(e, value) => setFieldValue(name, value)}
                    onChangeActive={(e, v) => setHover(v >= 0 ? v : null)}
                    max={10}
                    readOnly={readOnly}
                    {...props}
                />
                <div className='mb-10 ml-10'>{hover || values[name] || 0}</div>
            </Grid>
        </Grid>
    )
}

const TotalScore = ({ formikProps }) => {
    const { values } = formikProps;

    return (
        <Grid container>
            <Grid item lg={5}>
                <h4 className="fw7 mr-15">Total Score</h4>
            </Grid>
            <Grid item lg={7} className='is-flex fdr aic jcfs'>
                {Object.values(values).reduce((a, c) => a + parseInt(c), 0)}
                /
                {Object.keys(values).length * 10}
            </Grid>
        </Grid>
    )
}

export default VetterScore