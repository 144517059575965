import React from "react"
import { CustomModal } from "./modal"
import { Formik, Form } from "formik"
import Select from "react-select"

function ViewVideoDetails({ isOpen, onClose, video, platforms, genders }) {

    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={
                <Formik
                    initialValues={{
                        brief: {
                            name: video?.name || '',
                            instructions: video?.instructions || '',
                            content_type: video?.brief.content_type.label || '',
                            tone_of_voice: video?.brief.tone_of_voice.label || '',
                            language: video?.brief.language.label || '',
                            platform: video?.brief.platform ? video.brief.platform.map(p => p.value) : [],
                            duration: video?.brief.duration || '',
                            format: video?.brief.format.label || '',
                            gender: (video?.type_id === 2 || video?.type_id === 3) ? video?.brief.gender || '' : null,
                            age: video?.type_id === 3 ? video?.brief.age || '' : null,
                        },
                    }}
                >
                    {({ values }) => (
                        <Form className="form mt-30">
                            <input
                                type="text"
                                name="brief.name"
                                value={values.brief.name}
                                className="stllr-input mb-10"
                                disabled={true}
                                placeholder="Enter video title"
                            />
                            <textarea
                                name="brief.instructions"
                                value={values.brief.instructions}
                                className="stllr-input mb-10"
                                disabled={true}
                                placeholder="Enter video description"
                            ></textarea>
                            <Select
                                name="brief.content_type"
                                isDisabled={true}
                                placeholder={values.brief.content_type}
                                value={null}
                                className="mb-10"
                            />
                            <Select
                                name="brief.tone_of_voice"
                                isDisabled={true}
                                placeholder={values.brief.tone_of_voice}
                                value={null}
                                className="mb-10"
                            />
                            <Select
                                name="brief.language"
                                isDisabled={true}
                                placeholder={values.brief.language}
                                value={null}
                                className="mb-10"
                            />
                            <Select
                                name="brief.platform"
                                options={platforms}
                                isDisabled={true}
                                placeholder="Platform"
                                value={platforms.filter(e => values.brief.platform.includes(e.value))}
                                className="mb-10"
                                isMulti
                            />
                            <Select
                                name="brief.duration"
                                isDisabled={true}
                                placeholder={values.brief.duration}
                                value={null}
                                className="mb-10"
                            />
                            <Select
                                name="brief.format"
                                isDisabled={true}
                                placeholder={values.brief.format}
                                value={null}
                                className="mb-10"
                            />

                            {/* Conditional fields for video.type_id */}
                            {video.type_id === 3 && (
                                <>
                                    <Select
                                        name="brief.gender"
                                        options={genders}
                                        isDisabled={true}
                                        placeholder="Select gender"
                                        value={genders.find(e => e.value === values.brief.gender)}
                                        className="mb-10"
                                    />
                                    <Select
                                        name="brief.age"
                                        isDisabled={true}
                                        placeholder={values.brief.age}
                                        value={null}
                                        className="mb-10"
                                    />
                                </>
                            )}
                            {video.type_id === 2 && (
                                <Select
                                    name="brief.gender"
                                    options={genders}
                                    isDisabled={true}
                                    placeholder="Select gender"
                                    value={genders.find(e => e.value === values.brief.gender)}
                                    className="mb-10"
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            }
        />
    );
}


export { ViewVideoDetails }