import React from 'react';
import { Avatar } from '@material-ui/core';

export const OverlappingAvatars = ({ avatars, ...props }) => {
  return (
    <ul className='overlapping-avatars fw'>
      {avatars.map((avatar, index) => (
        <li key={index}>
          <Avatar alt={avatar.alt} src={avatar.url} />
        </li>
      ))}
    </ul>
  )
}

export default OverlappingAvatars;