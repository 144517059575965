import React, { useMemo } from "react"
import { CustomModal } from "./modal"
import moment from "moment";
import TableContainer from "./TableContainer";

function ViewCouponRedemptionsList({ isOpen, onClose, list }) {

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: `name`,
    },
    {
      Header: 'Email',
      accessor: `email`,
    },
    {
      Header: 'Redemption Date',
      accessor: `date_add`,
      Cell: ({ cell }) => {
        let { date_add } = cell.row.original
        return <span>{moment(date_add).format('lll')}</span>
      }
    },
    {
      Header: 'Campaign',
      accessor: `project_name`,
    },
  ])

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      className="commonCustomModal feedbackModal"
      body={
        <TableContainer columns={columns} hiddenColumns={[]} data={list} />
      }
    />
  );
}

export { ViewCouponRedemptionsList }