import React, { useState, useMemo, useEffect, useCallback } from "react"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { fetchProjects, fetchUser, fetchPostOfferProjects, getProjectContractPDF } from "../services"
import { Grid, Container, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core"
import TableContainerServerSide from "../components/TableContainerServerSide"
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import slugify from 'slugify';
import moment from "moment";
import { RatingModal } from "../components/RatingModal";
import { useAuth } from "../utils/Auth";

function Projects() {
  const history = useHistory()
  const { user } = useAuth()
  const [reviewData, setReviewData] = useState(null)
  const [total, setTotal] = useState(0)
  const [pageCount, setPageCount] = useState(1)
  const [projects, setProjects] = useState([])
  const [downloading, setDownloading] = useState(null)

  // const { data: user, isLoading: userLoading } = useQuery("user", fetchUser, {
  //   select: (data) => data.data,
  //   onSuccess: () => {
  //   }
  // })

  const downloadContract = async (project) => {
    try {
      const { id, company_name, company_role, owner_name, owner_email, price_formatted, duration } = project
      setDownloading(id)

      const body = {
        id: project.id,
        // company_name: project.company_name,
        // company_role: project.company_role,
        // owner_name: project.owner_name,
        // owner_email: project.owner_email,
        // price_formatted: project.price_formatted,
        // duration: project.duration,
      }

      const data = await getProjectContractPDF(body)

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]))
      link.setAttribute('download', `${company_name}_${company_role}_${moment().format('L')}.pdf`);
      link.click()

      setDownloading(null)
    }
    catch (e) {
      console.log(e)
      alert('Oops, something went wrong!')
      setDownloading(null)
    }
  }

  const columns = useMemo(() => [
    {
      accessor: 'company_name',
      Header: 'Company',
      Filter: DefaultFilter
    },
    {
      accessor: 'name',
      Header: 'Project name',
      Filter: DefaultFilter,
    },
    {
      accessor: 'project_status',
      Header: 'Status',
      Cell: ({ row }) => {
        const { status_id, project_status } = row.original
        let statusClass = ``

        switch (Number(status_id)) {
          case Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT):
            statusClass = `-red`
            break;

          case Number(process.env.REACT_APP_PROJECT_STATUS_PENDING_BRIEF):
            statusClass = `-orange`
            break;

          case Number(process.env.REACT_APP_PROJECT_STATUS_PENDING_MILESTONES):
            statusClass = `-blue`
            break;

          case Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING):
            statusClass = `-gold`
            break;

          case Number(process.env.REACT_APP_PROJECT_STATUS_PAUSED_BY_CUSTOMER):
            statusClass = `-purple`
            break;

          case Number(process.env.REACT_APP_PROJECT_STATUS_PAUSED):
            statusClass = `-yellow`
            break;

          case Number(process.env.REACT_APP_PROJECT_STATUS_CANCELLED):
            statusClass = `-grey`
            break;

          default:
            statusClass = `-green`
        }

        return <Chip size="small" className={`chip${statusClass}`} label={project_status} />
      },
      Filter: SelectColumnFilter,
      FilterOptions: [
        'Awaiting payment',
        'Pending Brief',
        'Pending milestones',
        'Ongoing',
        'Paused by Customer',
        'Paused',
        'Cancelled',
        'Finished',
        'Payment Failed',
        'Expert Replaced',
        'Pending Offer',
        'Offer Rejected',
        'Offer Submitted',
      ]
    },
    {
      accessor: `duration`,
      Header: `Duration`,
      Filter: DefaultFilter,
      Cell: ({ row }) => {
        const { duration } = row.original
        return <span>{duration} Months</span>
      }
    },
    {
      accessor: `hours`,
      Header: `Hours`,
      disableFilters: true,
      Cell: ({ row }) => {
        const { hours } = row.original
        return <span>{hours ? `${hours} Hours` : ``}</span>
      }
    },
    {
      Header: 'Consumed',
      accessor: "consumed_duration",
      disableFilters: true,
      Cell: ({ row }) => {
        const { consumed_duration_days } = row.original
        return <span>{(consumed_duration_days / 30).toFixed(2)} Months</span>
      }
    },
    {
      accessor: 'cost',
      Header: 'Cost',
      disableFilters: true,
      Cell: ({ row }) => {
        const { cost_formatted } = row.original
        return <span>{cost_formatted}</span>
      }
    },
    {
      accessor: 'payout',
      Header: 'Payouts',
      disableFilters: true,
      Cell: ({ row }) => {
        const { payout_formatted } = row.original
        return <span>{payout_formatted}</span>
      }
    },
    {
      accessor: 'n_experts',
      Header: '#Experts',
      disableFilters: true,
      Cell: ({ row }) => {
        const { experts } = row.original
        return <span>{experts.length}</span>
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      disableFilters: true,
      Cell: ({ row }) => {
        const { creator_role_id, name } = row.original
        // console.log(creator_role_id + " - " + name)
        return <span>{creator_role_id == process.env.REACT_APP_ADMIN_ROLE_ID ? `Team` : `Self-Checkout`}</span>
      }
    },
    {
      accessor: 'actual_commission',
      Header: 'Comm.%',
      disableFilters: true,
      Cell: ({ row }) => {
        const { actual_commission } = row.original
        return <span>{actual_commission}</span>
      }
    },
    {
      Header: 'Created date',
      accessor: "date_add",
      Filter: DefaultFilter,
      FilterPlaceholder: 'YYYY-MM-DD',
      Cell: ({ row }) => {
        const { date_add } = row.original
        return moment(date_add).format('ll')
      }
    },
    {
      Header: '1st Payment',
      accessor: "date_ongoing",
      disableFilters: true,
      Cell: ({ row }) => {
        const { date_ongoing } = row.original
        return <span>{date_ongoing && moment(date_ongoing).format('ll')}</span>
      }
    },
    {
      accessor: 'price',
      Header: 'Price',
      disableFilters: true,
      Cell: ({ row }) => {
        const { price_formatted } = row.original
        return <span>{price_formatted}</span>
      }
    },
    {
      accessor: `k_status`,
      Header: `K Status`,
      Filter: DefaultFilter,
      Cell: ({ row }) => {
        const { k_status } = row.original
        return <span>{k_status}</span>
      }
    },
    {
      accessor: `k_description`,
      Header: `K Description`,
      Filter: DefaultFilter,
      Cell: ({ row }) => {
        const { k_description } = row.original
        return <span>{k_description}</span>
      }
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const { id, owner_id, status_id, current_month, experts, has_videos } = row.original
        return user?.email == process.env.REACT_APP_FINANCE_ADMIN_EMAIL ? (
          <div className="is-flex aic">
            <button onClick={() => window.location.href = `/project-payments/${id}`} className="stllr-btn btn-sm primary bordered ml-10">PAYMENTS</button>
          </div>
        ) : (<div className="is-flex aic">

          {/* RATE EXPERTS BUTTON */}
          {/* {status_id == process.env.REACT_APP_PROJECT_STATUS_FINISHED && experts.some(e => !e.is_reviewed) && */}
          {experts.some(e => current_month > e.is_reviewed) &&
            <button
              className="stllr-btn btn-sm primary bordered mr-10"
              onClick={() => {
                const data = {
                  projectId: id,
                  month: current_month,
                  experts: experts.filter(e => current_month > e.is_reviewed).map(e => {
                    return { name: e.expert_name, id: e.expert_id, avatar: e.expert_avatar, months: e.months_reviewed }
                  })
                }
                setReviewData(data)
              }}
            >Rate Experts</button>
          }

          <a href={has_videos ? `/edit-project/${id}/ugc` : `/edit-project/${id}`} className="stllr-btn btn-sm primary bordered">EDIT</a>
          <a href={`/project-brief/${id}`} className="stllr-btn btn-sm primary bordered ml-10">BRIEF</a>
          <a href={`/project-history/${id}`} className="stllr-btn btn-sm primary bordered ml-10">HISTORY</a>
          <a href={`/chats/${slugify(row.original.name || row.original.company_name)}/${id}`} className="stllr-btn btn-sm primary bordered ml-10">CHAT</a>
          <a href={`${process.env.REACT_APP_STLLR_URL}project/${slugify(row.original.name || row.original.company_name)}/${id}`} className="stllr-btn btn-sm primary bordered ml-10" target="_blank" rel="noopener noreferrer">VIEW</a>
          {user.role_id === Number(process.env.REACT_APP_ADMIN_ROLE) || (user.email === process.env.REACT_APP_AMIRA_EMAIL) ? <a href={`/pulses/${id}`} className="stllr-btn btn-sm primary bordered ml-10">Pulses</a> : null}
          {row.original.status_id == process.env.REACT_APP_PROJECT_STATUS_ONGOING &&
            <button
              onClick={() => downloadContract(row.original)}
              className="stllr-btn btn-sm primary bordered ml-10"
              disabled={downloading === id}
            >Download Contract</button>
          }
        </div>
        )
      }
    },
  ]);
  const [open, setOpen] = useState(true)
  // const { data: projects, isLoading, refetch } = useQuery("projects", () => fetchProjects(null, null, null, user?.email === process.env.REACT_APP_FINANCE_ADMIN_EMAIL), {
  //   select: ({ projects }) => projects,
  //   enabled: false
  // })
  const { mutate: fetch, isLoading } = useMutation(params => fetchProjects(null, null, null, user?.email === process.env.REACT_APP_FINANCE_ADMIN_EMAIL, params), {
    onSuccess: ({ projects }) => {
      setProjects(projects.data || [])
      setTotal(projects.total)
      if (projects.pageCount !== pageCount) {
        setPageCount(projects.pageCount)
      }
    }
  })

  const { data: postOfferProjects } = useQuery('post-offer-projects', () => fetchPostOfferProjects(), {
    select: res => res.projects,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const handleRefetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      fetch({
        pageIndex,
        pageSize,
        search,
      })
    },
    []
  )

  // useEffect(() => {
  //   if (user && user.email) {
  //     fetch({})
  //   }
  // }, [user])

  // if (userLoading) return <div>Loading....</div>

  return (
    <Container spacing={1} maxWidth='xl'>
      <Grid item lg={12}>
        {postOfferProjects === undefined ? null : <div>
          <span className="fw7">projects created from a post offer: {postOfferProjects?.length}</span>
        </div>}
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Projects (<i>{total}</i>)</h2>
          <div className="is-flex aic">
            {user?.email != process.env.REACT_APP_FINANCE_ADMIN_EMAIL && <a className="stllr-btn primary btn-md" href="/new-project">Create project</a>}
            {user?.email != process.env.REACT_APP_FINANCE_ADMIN_EMAIL && <a className="stllr-btn primary btn-lg ml-10" href="/new-project/ugc">Create UGC project</a>}
          </div>
        </div>

        <TableContainerServerSide
          name='projects'
          paging={true}
          columns={columns}
          hiddenColumns={user.email === process.env.REACT_APP_ADMIN_EMAIL ? [""] : ["k_status", "k_description"]}
          data={projects}
          hasFilters={true}
          loading={isLoading}
          pageCount={pageCount}
          fetchData={handleRefetchData}
        />
      </Grid>

      {projects.map(e => (
        <div>
          {e.pulsesNeeded > 0 && (e.pulsesNeeded > e.pulses.length) ?
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth={true}>
              <DialogTitle id="form-dialog-title">
                <div className='is-flex fdr aic jcsb'>
                  Projects that need to be pulsed
                  <IconButton onClick={() => setOpen(false)}>
                    {/* <CloseIcon /> */}
                    X
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className='group'>
                  <div className="" style={{ fontSize: "14px", lineHeight: "17px" }}>
                    <div>
                      <div className="is-flex aic jcfs mb-15">
                        {/* {arr.push(e.id), console.log(arr)} */}
                        <div>{e.name}</div>
                        <button onClick={() => window.location.href = `/pulses/${e.id}`} className="stllr-btn btn-sm primary bordered ml-10">Fill Pulse</button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog> : null}
        </div>
      ))}

      <RatingModal
        data={reviewData}
        isOpen={Boolean(reviewData)}
        onClose={() => setReviewData(null)}
      />

    </Container>
  )
}

export default Projects