import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { FieldArray, Formik } from "formik";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, RadioGroup, FormControlLabel, Radio, CircularProgress } from "@mui/material"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { CreditCardRounded, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "../components"
import { getOffers } from '../services';

export const OfferDialogView = ({ open, onClose, payload }) => {
  const { start_offer_id, offers_count } = payload || {}

  const { data: offers, isLoading: isOffersLoading, isError: isOffersError, refetch } = useQuery(['offers', payload], () => getOffers({ offset: start_offer_id, limit: offers_count, is_post: payload?.is_post }), {
    select: res => res.offers,
    enabled: false,
  })

  useEffect(() => {
    if (payload) {
      refetch()
    }
  }, [payload])

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth='sm' fullWidth={true}>
      {isOffersLoading
        ? <div className='is-flex jcc mt-50 mb-50'><CircularProgress /></div>
        : isOffersError
          ? <div className='is-flex jcc mt-50 mb-50'>E R R O R</div>
          : <Formik
            initialValues={{
              name: offers?.[0]?.name || '',
              goal: offers?.[0]?.goal || '',
              offers: offers?.length
                ? offers.map(o => ({ ...o, cost: o.cost * o.duration }))
                : [{ cost: '', duration: '', revisions: '', addons: [] }],
            }}
          >
            {
              props => {
                const {
                  values,
                } = props

                return (
                  <form style={{ width: "100%" }}>
                    <DialogTitle id="form-dialog-title">
                      <div className='is-flex fdr aic jcsb'>
                        {'View Offer(s)'}
                        <IconButton onClick={() => onClose()}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <DialogContent>

                      <Grid container spacing={4}>
                        <Grid item lg={4}>
                          <div className='group'>
                            <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'Project name'}</div>
                            <input
                              type="text"
                              value={`${values.name}`}
                              className="stllr-input"
                              disabled={true}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={8}>
                          <div className='group'>
                            <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'Project goal'}</div>
                            <input
                              type="text"
                              value={`${values.goal}`}
                              className="stllr-input"
                              disabled={true}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div>
                        {values.offers?.length ? values.offers.map((offer, index) => (
                          <div>
                            <div className='sep'/>
                            <Grid container spacing={4}>
                              <Grid item lg={12}>
                                <div>
                                  <div className='is-flex fdr jcsb aic mb-20'>
                                    <h4 className='fw6'>{index + 1}{
                                      index === 0
                                        ? 'st'
                                        : index === 1
                                          ? 'nd'
                                          : index === 2 ?
                                            'rd'
                                            : 'th'
                                    } Offer</h4>
                                    <h3 className='fw7' >
                                      {'Total'}: {offer.total_formatted}
                                    </h3>
                                  </div>
                                  <Grid container spacing={4}>
                                    <Grid item lg={4}>
                                      <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'Total Price'}</div>
                                      <input
                                        name={`offers.${index}.cost`}
                                        value={offer.cost_total_formatted}
                                        className="stllr-input"
                                        disabled={true}
                                      />
                                    </Grid>

                                    <Grid item lg={4}>
                                      <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'Service Duration'}</div>
                                      <input
                                        name={`offers.${index}.duration`}
                                        value={`${offer.duration} ${'month(s)'}`}
                                        className="stllr-input"
                                        disabled={true}
                                      />
                                    </Grid>

                                    <Grid item lg={4}>
                                      <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'# of Revisions'}</div>
                                      <input
                                        name={`offers.${index}.revisions`}
                                        value={`${offer.revisions}`}
                                        className="stllr-input"
                                        disabled={true}
                                      />
                                    </Grid>

                                    {!offer.addons?.length ? null : <Grid item lg={12}>
                                      <div className='is-flex fdr aic'>
                                        <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'Service add-ons'}</div>
                                      </div>
                                      {values.offers?.[index].addons.map((addon, addonIndex) => (
                                        <FieldArray
                                          name={`offers.${index}.addons`}
                                          render={addonsHelper => (
                                            <div key={addonIndex}>
                                              <div className="is-flex fdr aic jcsb mb-10">
                                                <input
                                                  type="text"
                                                  name={`offers.${index}.addons.${addonIndex}.name`}
                                                  value={addon.name}
                                                  className="stllr-input has-full-width mr-10"
                                                  disabled={true}
                                                />
                                                <input
                                                  name={`offers.${index}.addons.${addonIndex}.cost`}
                                                  value={`${addon.cost_formatted}`}
                                                  className="stllr-input "
                                                  disabled={true}
                                                  style={{ width: "30%" }}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        />
                                      ))}
                                    </Grid>}

                                    {!offer.milestones?.length ? null : <Grid item lg={12}>
                                      <div className='is-flex fdr aic'>
                                        <div className="mini-title mb-10" style={{ fontSize: "14px", lineHeight: "17px" }}>{'Scope of work'}</div>
                                      </div>
                                      {offer.milestones.map((milestone, milestoneIndex) => (
                                        <Accordion style={{ width: 'auto' }}>
                                          <AccordionSummary
                                            style={{ width: '100%' }}
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`panel-${index}-${milestoneIndex}`}
                                            id={`panel-${index}-${milestoneIndex}`}
                                          >
                                            <div className='is-flex fdr aic jcsb has-full-width mr-20'>
                                              <span>{milestone.title}</span>
                                              <span>{milestone.start_date} - {milestone.end_date}</span>
                                            </div>
                                          </AccordionSummary>
                                          <AccordionDetails
                                            style={{ width: '100%' }}
                                          >
                                            <Typography>
                                              {milestone.description}
                                            </Typography>
                                          </AccordionDetails>
                                        </Accordion>
                                      ))}
                                    </Grid>}
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        )) : null}
                      </div>

                    </DialogContent>
                  </form>
                )
              }
            }
          </Formik>}
    </Dialog>
  )
}