import React, { useEffect, useState, useRef } from 'react';
import { Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as Yup from "yup"
import { Button } from '../components';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react'


const BriefForm = ({ sections, onSubmit, isSubmitting, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({})
  const [validationSchema, setValidationSchema] = useState({})
  const editorRef = useRef(null);

  useEffect(() => {
    const iv = {}
    const vs = {}

    sections.forEach(s => {
      if (!s.questions) {
        console.log('missing questions', s.title)
        return
      }
      s.questions.forEach(q => {
        if (!q.key) {
          console.log('missing key', q.question)
          return
        }

        switch (q.type) {
          case 'text':
          case 'richtext':
            iv[q.key] = q.value || null
            vs[q.key] = Yup.string().nullable()
            // If input is required
            if (q.isRequired !== false) {
              vs[q.key] = vs[q.key].required()
            }
            break;

          case 'checkbox':
            iv[q.key] = q.value || []
            vs[q.key] = Yup.array()
            // If input is required
            if (q.isRequired !== false) {
              vs[q.key] = vs[q.key].min(1)
            }
            if (q.max) {
              vs[q.key] = vs[q.key].max(q.max)
            }
            break;

          case 'radio':
            iv[q.key] = q.value || null
            vs[q.key] = Yup.string().nullable()
            // If input is required
            if (q.isRequired !== false) {
              vs[q.key] = vs[q.key].required()
            }
            break;

          case 'dropdown':
            // If support multiple dropdown
            if (q.isMulti) {
              iv[q.key] = Array.isArray(q.value) ? q.value : (q.value ? [q.value] : [])
              vs[q.key] = Yup.array()
              // If input is required
              if (q.isRequired !== false) {
                vs[q.key] = vs[q.key].min(1)
              }
              if (q.max) {
                vs[q.key] = vs[q.key].max(q.max)
              }
            }
            // If does not support multiple select
            else {
              iv[q.key] = q.value || null
              vs[q.key] = Yup.string().nullable()
              // If input is required
              if (q.isRequired !== false) {
                vs[q.key] = vs[q.key].required()
              }
            }
            break;
        }

        // IF input has other option value
        if (q.otherValue) {
          iv[q.key + '#other'] = q.otherValue || null
        }
      })
    })

    setInitialValues(iv)
    setValidationSchema(vs)
    setIsLoading(false)
  }, [sections])

  const selectMapper = e => ({ label: e, value: e });

  const handleOnFormSubmit = (values) => {
    const answers = {}

    Object.keys(values).forEach(key => {
      if (key.endsWith('#other')) return
      const value = values[key]
      const other = values[key + '#other']

      answers[key] = { value }
      if (other && ((Array.isArray(value) && value.includes('Other')) || value === 'Other')) {
        answers[key].otherValue = other
      }
    })

    onSubmit(answers);
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnFormSubmit}
      validationSchema={Yup.object().shape(validationSchema)}
    >
      {props => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="white-box" style={{ width: '100%' }}>
              <div style={{ padding: "20px 35px 16px 35px" }}>
                {sections.map(s => (
                  <div className='mb-55'>
                    <div className='is-flex fdr aic'>
                      {s.icon && <img src={process.env.REACT_APP_STLLR_STATIC_URL + s.icon} className='mr-15' />}
                      <div>
                        <h2 className='has-text-bold mb-5'>{s.title}</h2>
                        <h3>{s.subtitle}</h3>
                      </div>
                    </div>
                    <Divider style={{ margin: '20px 10px' }} />
                    {s.questions.map(q => q.key && (
                      <div className='group'>
                        {console.log("THIS IS ME", q)}
                        <div className='brief-label mb-10'>
                          {q.question}
                          {' '}
                          {q.isRequired !== false && <span style={{ color: 'red' }}>*</span>}
                        </div>
                        {q.helper && <div className='mb-10' style={{ color: '#777' }}>{q.helper}</div>}
                        {q.type === 'text' ? (
                          <Field
                            className='stllr-input'
                            name={q.key}
                            value={values[q.key]}
                            placeholder={q.placeholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ) : q.type === 'dropdown' && Array.isArray(q.options) ? (
                          <Select
                            options={q.options.concat(q.addOther ? ['Other'] : []).map(selectMapper)}
                            isMulti={q.isMulti}
                            placeholder={q.placeholder}
                            name={q.key}
                            defaultValue={
                              q.isMulti ? values[q.key].map(selectMapper) : ({
                                label: values[q.key],
                                value: values[q.key],
                              })
                            }
                            onChange={e => {
                              setFieldValue(q.key, (
                                q.isMulti ? e.map(e => e.value) : e.value
                              ))
                            }}
                          />
                        ) : q.type === 'checkbox' && Array.isArray(q.options) ? (
                          <div>
                            <FormGroup
                              className='checkboxBordered'
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {q.options.concat(q.addOther ? ['Other'] : []).map(option => (
                                <FormControlLabel
                                  value={option}
                                  label={option}
                                  control={<Checkbox
                                    name={q.key}
                                    defaultChecked={values[q.key]?.includes(option)}
                                  />}
                                />
                              ))}
                            </FormGroup>
                          </div>
                        ) : q.type === 'radio' && Array.isArray(q.options) ? (
                          <div>
                            <div className='is-flex fdr aic'>
                              <RadioGroup
                                name={q.key}
                                className='radioBordered'
                                defaultValue={q.options.includes(values[q.key]) ? values[q.key] : values[q.key] === null ? null : 'Other'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {q.options.concat(q.addOther ? ['Other'] : []).map(option => (<>
                                  <FormControlLabel
                                    value={option}
                                    label={option}
                                    control={<Radio />}
                                  />
                                </>))}
                              </RadioGroup>
                            </div>
                          </div>
                        ) : q.type === 'file' ? (
                          <div>
                            {q.value && <div className='mt-10'>
                              <a href={q.value?.url} download={q.value.filename} target='_blank'>
                                {q.value.filename}
                              </a>
                            </div>}
                          </div>
                        ) : q.type === 'richtext' ? (
                          <Editor
                            name={q.key}
                            value={values[q.key]}
                            disabled={true}
                            apiKey={process.env.REACT_APP_TINY_API_KEY}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                              width: '100%',
                              height: 200,
                              menubar: false,
                              placeholder: 'Tell us more so we can find the best expert for you',
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                            onEditorChange={value => setFieldValue(q.key, value)}
                          />) : null}
                        <AddOtherOption
                          q={q}
                          formikProps={props}
                          className={q.type === 'dropdown' && 'mt-10'}
                        />
                        {errors[q.key] && touched[q.key] && (
                          <span className="v-error">{errors[q.key]}</span>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            {/* <div className='is-flex fdr jcfe mt-15'>
              <Button
                text='Submit'
                type='submit'
                className='stllr-btn primary btn-lg has-text-bold'
                disabled={isSubmitting}
              />
            </div> */}
          </form>
        )
      }}
    </Formik>
  )
}

const AddOtherOption = ({ formikProps, q, ...props }) => {
  const { values, handleChange, handleBlur } = formikProps
  const value = values[q.key]
  const key = q.key + '#other'

  return (Array.isArray(value) && value.includes('Other')) || value === 'Other' ? (
    <div {...props}>
      <Field
        className='stllr-input'
        name={key}
        value={values[key]}
        placeholder={q.placeholder || 'Add other option'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  ) : null
}

BriefForm.defaultProps = {
  onSubmit: () => null,
}

export default BriefForm;