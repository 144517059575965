function DefaultFilter({
    column: { filterValue, preFilteredRows, setFilter, FilterPlaceholder, Header },
  }) {
    const count = preFilteredRows?.length
    
    return (
      <input
        className="stllr-input"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={FilterPlaceholder || `Search ${count || ''} records...`}
      />
    )
}

export default DefaultFilter