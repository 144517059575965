import React from "react"
import { Container, Grid, Chip } from "@material-ui/core"
import { useHistory } from 'react-router';
import { useQuery } from "react-query";
import { fetchProjects } from "../services"
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import slugify from 'slugify';

function ChatActivities() {
    const history = useHistory();
    const { data: projects, isLoading } = useQuery("projects", () => fetchProjects(null), {
        select: ({ projects }) => projects,
    })

    console.log(projects)

    if (isLoading) return <div>Loading...</div>

    return (
        <Container spacing={1} maxWidth='lg'>
            {/* <Grid item lg={12}> */}
            <div className="is-flex aic jcsb">
                <h2 className="mt-0 mb-55 mt-20">Pending offer projects</h2>
            </div>

            <div className="">
                <Grid container direction="row" spacing="2">
                    {projects.map(p => (
                        p.status_id == process.env.REACT_APP_PROJECT_PENDING_OFFER_STATUS ?
                            <Grid item lg={3}>
                                <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Since: {moment(p.date_add).format("ll")}
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            {p.company_name}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {p.owner_name}
                                        </Typography>
                                        <Typography variant="body2">
                                            <div>
                                                {/* one two three four five six seven eight nine */}
                                                {p.experts.map(e => (
                                                    p.team_id ? (p.team_leader_id == e.expert_id ? e.expert_name : null)
                                                        :
                                                        e.expert_name
                                                ))}
                                            </div>
                                        </Typography>
                                    </CardContent>
                                    <div className="is-flex aife jcfe">
                                    <CardActions>
                                        <Button size="medium" 
                                        style={{color: "#272AEE"}}
                                        onClick={
                                            () => history.push(`/chats/${slugify(p.name || p.company_name)}/${p.id}`)
                                        }>Go to chat</Button>
                                    </CardActions>
                                    </div>
                                </Card>
                            </Grid>
                            : null
                    ))}
                </Grid>
            </div>
        </Container>
    )
}

export default ChatActivities