import React, { useRef, useState } from "react"
import { useQuery, useMutation } from "react-query"
import {
    Grid,
    Container
} from "@material-ui/core"
import Select from "react-select"
import { useParams } from "react-router-dom"
import {
    fetchAllExperts,
    updateExpert,
    fetchCountries,
    fetchCityByCountry,
    fetchLanguages,
    fetchNiches,
    fetchTools,
    fetchActiveServices,
    uploadWork,
    deleteWork
} from "../services"
import { FieldArray, Formik } from 'formik';
import * as Yup from "yup"
import getVideoMetadata from "../utils/getVideoMetadata"

function EditExpert() {
    const { id } = useParams()
    const [countryId, setCountryId] = useState(null);
    const fileRef = useRef()

    const genders = [
        { value: 'm', label: 'Male' },
        { value: 'f', label: 'Female' },
    ]

    const faceOptions =
        [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
        ]

    const { data: expert, isLoading, refetch } = useQuery("expert", () => fetchAllExperts(id), {
        select: data => data.experts,
        onSuccess: (data) => {
            setCountryId(data[0].country_id)
        },
        refetchOnWindowFocus: false,
    })

    const { data: countries = [], isCountryLoading } = useQuery('countries', () => fetchCountries(), {
        select: data => data.countries.map(e => ({ value: e.id, label: e.name })),
        refetchOnWindowFocus: false,
    })

    const { data: cities = [], isCityLoading } = useQuery(['cities', countryId], () => fetchCityByCountry(countryId), {
        select: data => data.cities.map(e => ({ value: e.id, label: e.name })),
        refetchOnWindowFocus: false,
    })

    const { data: languages = [], isLanguageLoading } = useQuery('languages', () => fetchLanguages(), {
        select: data => data.languages.map(e => ({ value: e.id, label: e.name })),
        refetchOnWindowFocus: false,
    })

    const { data: categories = [], isCategoryLoading } = useQuery('categories', () => fetchActiveServices(), {
        select: data => data.services.map(e => ({ value: e.id, label: e.name })),
        refetchOnWindowFocus: false,
    })

    const { data: niches = [], isNicheLoading } = useQuery('niches', () => fetchNiches(), {
        select: data => data.niches.map(e => ({ value: e.id, label: e.name })),
        refetchOnWindowFocus: false,
    })

    const { data: tools = [], isToolLoading } = useQuery('tools', () => fetchTools(), {
        select: data => data.tools.map(e => ({ value: e.id, label: e.name })),
        refetchOnWindowFocus: false,
    })

    const mutation = useMutation(newExpert => updateExpert(newExpert, id), {
        onSuccess: data => {
            if (data.status) {
                alert("Successfully updated")
                refetch()
            }
        },
        onError: error => {
            alert(error.data.message)
            console.log(error)
        }
    })

    const { mutate: uploadWorkSample, isLoading: isUploading } = useMutation(data => uploadWork(data), {
        onSuccess: (data) => {
            alert('File uploaded successfully')
            refetch()

        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { mutate: deleteWorkSample, isLoading: isDeleting } = useMutation(id => deleteWork(id), {
        onSuccess: () => {
            alert('File deleted successfully')
            refetch()

        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const deleteFile = async (id) => {
        if (window.confirm('Are you sure you want to delete this file?')) {
            deleteWorkSample(id)
        }
    }

    const uploadFile = async (file) => {
        const data = new FormData()
        data.append('attachment', file)
        data.append('expert_id', expert[0].id)
        if (file.type.startsWith('video')) {
            const metadata = await getVideoMetadata(file)
            data.append('metadata', JSON.stringify(metadata))
        }

        uploadWorkSample(data)
    }

    const handleOnFileSelect = (e) => {
        const file = e.target.files[0]
        console.log(file)
        if (file.size >= 500e6) { // 500MB
            alert(`${file.name.length > 20 ? file.name.slice(0, 20) + '..' : file.name} - File size is bigger than 500 MB`)
        }
        uploadFile(file)
        fileRef.current.value = null
    }

    if (isLoading) return <div>Loading...</div>

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic">
                        <h2 className="mb-5 mr-10">Edit {expert[0].name}'s profile</h2>
                        <a href={`${process.env.REACT_APP_STLLR_ADMIN_URL}score-expert/${expert[0].id}`}>Score creator</a>
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={{
                            username: expert[0].uid,
                            gender: expert[0].gender,
                            bio: expert[0].bio,
                            is_show_face: expert[0].is_show_face,
                            // is_nano: expert[0].is_nano,
                            country: expert[0].country_id,
                            city: expert[0].city_id,
                            shipping_address: expert[0].shipping_address,
                            languages: expert[0].languages.map(e => e.id),
                            categories: expert[0].services.map(e => e.service_id),
                            niche: expert[0].niche_id,
                            tools: expert[0].tools.map(e => e.id),
                            instagram_url: expert[0].instagram_url,
                            linkedin_url: expert[0].linkedin_url,
                            tiktok_url: expert[0].tiktok_url,
                            youtube_url: expert[0].youtube_url,
                            twitter_url: expert[0].twitter_url,
                            imdb_url: expert[0].imdb_url,
                            behance_url: expert[0].behance_url,
                            dribbble_url: expert[0].dribbble_url,
                            soundcloud_url: expert[0].soundcloud_url,
                            works: expert[0].works,
                        }}
                        validationSchema={Yup.object().shape({
                            bio: Yup.string().required('Enter bio'),
                            city: Yup.number().nullable().required('Select city'),
                            country: Yup.number().nullable().required('Select country'),
                            gender: Yup.string().nullable().required('Select gender'),
                            languages: Yup.array().min(1, 'Select languages & dialects'),
                            categories: Yup.array().min(1, 'Select categories'),
                            is_show_face: Yup.number().nullable().when('categories', {
                                is: categories => categories.includes(165),
                                then: Yup.number().nullable().required('Select whether creator show face in videos or not')
                            }),
                            niche: Yup.number().nullable().when('categories', {
                                is: categories => categories.includes(165),
                                then: Yup.number().nullable().required('Select niche')
                            }),
                            tools: Yup.array().when('categories', {
                                is: categories => !categories.includes(165),
                                then: Yup.array().min(1, 'Select tools').required('Select tools')
                            }),
                        })}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            mutation.mutate({ ...values })
                        }}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                <div>
                                    <div className="is-flex aic mt-0 mb-10">
                                        <h4 className="mt-15 mr-15 dimmed">CREATOR INFORMATION</h4>
                                    </div>

                                    <div className="group">
                                        <Grid container spacing={2}>
                                            <Grid item lg={6}>
                                                <label>Gender</label>
                                                <Select
                                                    options={genders}
                                                    value={genders.find(e => e.value === props.values.gender) || null}
                                                    name="gender"
                                                    onChange={({ value }) => props.setFieldValue('gender', value)}
                                                    className="mb-30"
                                                />

                                                {props.errors.gender && props.touched.gender ? (
                                                    <span className="v-error">{props.errors.gender}</span>
                                                ) : null}

                                                <Grid container spacing={2}>
                                                    <Grid item lg={12}>
                                                        {props.values.categories.includes(165) &&
                                                            <React.Fragment>
                                                                <label>Does the creator show face?</label>
                                                                <Select
                                                                    options={faceOptions}
                                                                    value={faceOptions.find(e => e.value === props.values.is_show_face) || null}
                                                                    onChange={({ value }) => props.setFieldValue('is_show_face', value)}
                                                                />

                                                                {props.errors.categories && props.touched.categories ? (
                                                                    <span className="v-error">{props.errors.categories}</span>
                                                                ) : null}
                                                            </React.Fragment>
                                                        }
                                                    </Grid>

                                                    {/* <Grid item lg={6}>
                                                        <React.Fragment>
                                                            <label>Nano creator?</label>
                                                            <Select
                                                                options={faceOptions}
                                                                value={faceOptions.find(e => e.value === props.values.is_nano) || null}
                                                                onChange={({ value }) => props.setFieldValue('is_nano', value)}
                                                            />
                                                        </React.Fragment>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={6}>
                                                <label>Bio</label>
                                                <textarea
                                                    name="bio"
                                                    value={props.values.bio}
                                                    onChange={props.handleChange}
                                                ></textarea>
                                                {props.errors.bio && props.touched.bio ? (
                                                    <span className="v-error">{props.errors.bio}</span>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className="group">
                                        <Grid container spacing={2}>
                                            <Grid item lg={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={6}>
                                                        <label htmlFor="name">Country</label>
                                                        <Select
                                                            options={countries}
                                                            isLoading={isCountryLoading}
                                                            value={countries.find(e => e.value === props.values.country) || null}
                                                            onChange={({ value }) => {
                                                                setCountryId(value)
                                                                props.setFieldValue('country', value)
                                                                props.setFieldValue('city', null)
                                                            }}
                                                        />

                                                        {props.errors.country && props.touched.country ? (
                                                            <span className="v-error">{props.errors.country}</span>
                                                        ) : null}
                                                    </Grid>

                                                    <Grid item lg={6}>
                                                        <label htmlFor="name">City</label>
                                                        <Select
                                                            options={cities}
                                                            isLoading={isCityLoading}
                                                            value={cities.find(e => e.value === props.values.city) || null}
                                                            onChange={({ value }) => props.setFieldValue('city', value)}
                                                            className="mb-30"
                                                        />

                                                        {props.errors.city && props.touched.city ? (
                                                            <span className="v-error">{props.errors.city}</span>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <label htmlFor="name">Languages</label>
                                                    <Select
                                                        options={languages}
                                                        isLoading={isLanguageLoading}
                                                        value={languages.filter(language => props.values.languages.includes(language.value)) || null}
                                                        onChange={value => {
                                                            const selectedValues = value ? value.map(v => v.value) : [];
                                                            props.setFieldValue('languages', selectedValues);
                                                        }}
                                                        isMulti
                                                    />

                                                    {props.errors.languages && props.touched.languages ? (
                                                        <span className="v-error">{props.errors.languages}</span>
                                                    ) : null}
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={6}>
                                                <label>Shipping address</label>
                                                <textarea
                                                    name="shipping_address"
                                                    value={props.values.shipping_address}
                                                    onChange={props.handleChange}
                                                ></textarea>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className="group">
                                        <Grid container spacing={2}>
                                            <Grid item lg={6}>
                                                <label htmlFor="name">Services</label>
                                                <Select
                                                    options={categories}
                                                    isLoading={isCategoryLoading}
                                                    value={categories.filter(language => props.values.categories.includes(language.value)) || null}
                                                    onChange={value => {
                                                        const selectedValues = value ? value.map(v => v.value) : [];
                                                        props.setFieldValue('categories', selectedValues);
                                                    }}
                                                    isMulti
                                                />

                                                {props.errors.categories && props.touched.categories ? (
                                                    <span className="v-error">{props.errors.categories}</span>
                                                ) : null}
                                            </Grid>

                                            {props.values.categories.includes(165) &&
                                                <Grid item lg={6}>
                                                    <label htmlFor="name">Niche</label>
                                                    <Select
                                                        options={niches}
                                                        isLoading={isNicheLoading}
                                                        value={niches.find(e => e.value === props.values.niche) || null}
                                                        onChange={({ value }) => props.setFieldValue('niche', value)}
                                                    />

                                                    {props.errors.niche && props.touched.niche ? (
                                                        <span className="v-error">{props.errors.niche}</span>
                                                    ) : null}
                                                </Grid>
                                            }

                                            {!props.values.categories.includes(165) &&
                                                <Grid item lg={6}>
                                                    <label htmlFor="name">Tools</label>
                                                    <Select
                                                        options={tools}
                                                        isLoading={isToolLoading}
                                                        value={tools.filter(tool => props.values.tools.includes(tool.value)) || null}
                                                        onChange={value => {
                                                            const selectedValues = value ? value.map(v => v.value) : [];
                                                            props.setFieldValue('tools', selectedValues);
                                                        }}
                                                        isMulti
                                                    />

                                                    {props.errors.tools && props.touched.tools ? (
                                                        <span className="v-error">{props.errors.tools}</span>
                                                    ) : null}
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>

                                    <div className="group">
                                        <h3 className="mb-20 mt-30">Social Links (Make sure to follow the correct format):</h3>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6}>
                                                <div className="is-flex aic">
                                                    <label className="mr-10">Instagram URL</label>
                                                    {!props.values.instagram_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('instagram_url', 'https://www.instagram.com/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="instagram_url" value={props.values.instagram_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://www.instagram.com/username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">LinkedIn URL</label>
                                                    {!props.values.linkedin_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('linkedin_url', 'https://www.linkedin.com/in/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="linkedin_url" value={props.values.linkedin_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://www.linkedin.com/in/username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">TikTok URL</label>
                                                    {!props.values.tiktok_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('tiktok_url', 'https://www.tiktok.com/@') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="tiktok_url" value={props.values.tiktok_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://www.tiktok.com/@username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">Youtube URL</label>
                                                    {!props.values.youtube_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('youtube_url', 'https://www.youtube.com/@') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="youtube_url" value={props.values.youtube_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://www.youtube.com/@username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">X (formerly Twitter) URL</label>
                                                    {!props.values.twitter_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('twitter_url', 'https://x.com/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="twitter_url" value={props.values.twitter_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://x.com/username" />
                                            </Grid>

                                            <Grid item lg={6}>
                                                <div className="is-flex aic">
                                                    <label className="mr-10">IMDB URL</label>
                                                    {!props.values.imdb_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('imdb_url', 'https://www.imdb.com/name/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="imdb_url" value={props.values.imdb_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://www.imdb.com/name/username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">Behance URL</label>
                                                    {!props.values.behance_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('behance_url', 'https://www.behance.net/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="behance_url" value={props.values.behance_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://www.behance.net/username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">Dribbble URL</label>
                                                    {!props.values.dribbble_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('dribbble_url', 'https://dribbble.com/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="dribbble_url" value={props.values.dribbble_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://dribbble.com/username" />

                                                <div className="is-flex aic">
                                                    <label className="mr-10">Soundcloud URL</label>
                                                    {!props.values.soundcloud_url &&
                                                        <button
                                                            className="stllr-btn btn-sm link"
                                                            onClick={(e) => { e.preventDefault(); props.setFieldValue('soundcloud_url', 'https://soundcloud.com/') }}
                                                        >
                                                            Fill the initial URL
                                                        </button>
                                                    }
                                                </div>
                                                <input type="text" name="soundcloud_url" value={props.values.soundcloud_url} onChange={props.handleChange} className="stllr-input mb-20" placeholder="https://soundcloud.com/username" />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>

                                {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                <button
                                    type="submit"
                                    className="stllr-btn btn-lg primary"
                                >
                                    Update Creator
                                </button>

                                <hr className="mb-30 mt-20" />

                                <div className="mb-30">
                                    <div className="is-flex aic mt-0 mb-10">
                                        <h4 className="mt-15 mr-15 dimmed">CREATOR WORK SAMPLES</h4>
                                    </div>

                                    <FieldArray
                                        name="works"
                                        render={helper => {


                                            return (
                                                <div className="mb-5">
                                                    {props.values.works.filter(w => w.file_id).map(work => (<>
                                                        <div className="is-flex aic jcsb mb-5">
                                                            <div className="is-flex aic">
                                                                <a href={work.file_thumbnail} target="_blank">
                                                                    <img src={work.file_thumbnail} height={50} className="mr-10" />
                                                                </a>
                                                                <a href={work.file_url} target="_blank">{work.file_name}</a>
                                                            </div>
                                                            <div className="is-flex aic">
                                                                <button onClick={() => deleteFile(work.id)} disabled={isDeleting} className="stllr-btn btn-sm danger" type="button">delete</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>))}

                                                    {isUploading
                                                        ? <span>uploading..</span>
                                                        : <input type="file" onChange={handleOnFileSelect} accept='image/png, image/jpeg, video/mp4' ref={fileRef} />
                                                    }

                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Container>
        </div>
    )
}

export default EditExpert