import React, { useEffect, useState, useMemo, useRef } from "react"
import moment from 'moment'
import * as Yup from "yup"
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from "react-router"
import { useQuery, QueryClient, useMutation } from "react-query"
import { Button } from "../components/button";
import { Formik } from "formik"
import { Container, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { fetchServices, fetchExperts, fetchCustomers, createProject, fetchUser, fetchCurrenceis, fetchMinifiedTeams, fetchGroups, generateProposal, fetchServiceAvgPrice } from "../services"
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Select from "react-select"
import TableContainer from "../components/TableContainer"
import { Editor } from '@tinymce/tinymce-react'
import HtmlParser from 'react-html-parser';
import MilestonesDialog from "../components/MilestonesDialog"

function AddProposal() {
    const history = useHistory()
    const editorRef = useRef(null)
    const formikRef = useRef(null)
    const [avgServicePrice, setAvgServicePrice] = useState(null)
    const [selectedService, setSelectedService] = useState(null)
    const [filteredServices, setFilteredServices] = useState([])
    const [refinedExperts, setRefinedExperts] = useState([])
    const [selectedExpert, setSelectedExpert] = useState(null)
    const [projectServices, setProjectServices] = useState([])
    const [duration, setDuration] = useState(null)
    const [milestone, setMilestone] = useState(null)
    const [hourlyRate, setHourlyRate] = useState(null)
    const [oldRate, setOldRate] = useState(null)
    const [hours, setHours] = useState(null)
    const [cost, setCost] = useState(null)
    const [payout, setPayout] = useState(null)
    const [commission, setCommission] = useState(null)
    const [projectMilestones, setProjectMilestones] = useState([])
    const [projectExperts, setProjectExperts] = useState([])
    const [experts, setExperts] = useState([]);
    const [groupsOptions, setGroupsOptions] = useState([]);
    const [groupExperts, setGroupExperts] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([])
    const [currentCurrency, setCurrentCurrency] = useState({ id: 1, code: 'usd', symbol: '$', rate: 1 })
    const [generateMilestones, setGenerateMilestones] = useState(null)

    const { data: groups } = useQuery("groups", () => fetchGroups(), {
        select: res => res.groups,
        onSuccess: res => setGroupsOptions(res.map(e => {
            return { label: e.project_name, value: e.experts_names }
        }))
    })

    const { data: currencies, isLoading: isCurrenciesLoading } = useQuery(['currencies'], () => fetchCurrenceis(), {
        select: res => res.currencies,
        onSuccess: data => {
            setCurrencyOptions(data?.map(e => ({ label: `${e.name} - ${e.code}`, value: e.id })) || [])
        }
    })

    useEffect(() => {
        if (selectedExpert) {
            setHourlyRate(selectedExpert.hourly_rate_converted * currentCurrency.rate)
            setOldRate(selectedExpert.hourly_rate_converted * currentCurrency.rate)
        }
    }, [currentCurrency])

    const kStatus = [
        { value: 'Onboarding', label: 'Onboarding' },
        { value: 'Upselling', label: 'Upselling' },
        { value: 'Healthy', label: 'Healthy' },
        { value: 'At risk', label: 'At risk' }
    ]

    const milestonesColumns = useMemo(() => [
        {
            accessor: 'title',
            Header: "Title",
            disableFilters: true
        },
        {
            accessor: 'description',
            Header: "Description",
            disableFilters: true,
            Cell: ({ row }) => {
                return HtmlParser(row.original.description);
            }
        },
        // {
        //     accessor: 'expert_name',
        //     Header: "Expert",
        //     disableFilters: true,
        //     Cell: ({ row }) => {
        //         let { expert_id } = row.original

        //         // Get the service name equivalent to this service ID
        //         let expert = projectExperts.filter(ps => {
        //             return ps.value == expert_id
        //         })

        //         return (expert[0]?.label)
        //     }
        // },
        // {
        //     accessor: 'service_name',
        //     Header: "Service",
        //     disableFilters: true,
        // },
        {
            accessor: "startDate",
            Header: "Start date",
            disableFilters: true
        },
        {
            accessor: "endDate",
            Header: "End date",
            disableFilters: true
        },
        {
            Header: "Actions",
            Cell: ({ row }) => {
                return (
                    <Button className="stllr-btn btn-sm danger" text="REMOVE" onClick={() => removeMilestone(row.id)} />
                )
            }
        }
    ]),
        columns = useMemo(() => [
            // {
            //     accessor: 'service_name',
            //     Header: "Service",
            //     disableFilters: true
            // },
            // {
            //     accessor: "expert_name",
            //     Header: "Expert",
            //     disableFilters: true
            // },
            // {
            //     accessor: "hourly_rate",
            //     Header: "Hourly rate",
            //     disableFilters: true,
            //     Cell: ({ row }) => {
            //         const { symbol, rate } = currentCurrency
            //         let { hourly_rate } = row.original
            //         return `${symbol} ${Number(hourly_rate * rate).toFixed(2)} /hr`
            //     }
            // },
            {
                accessor: "duration",
                Header: "Duration",
                disableFilters: true,
                Cell: ({ row }) => {
                    let { duration } = row.original
                    return `${duration} Month(s)`
                }
            },
            // {
            //     accessor: "hours",
            //     Header: "Hours",
            //     disableFilters: true,
            //     Cell: ({ row }) => {
            //         let { hours } = row.original
            //         return `${Number(hours)?.toFixed(1)} Hour(s)`
            //     }
            // },
            // {
            //     accessor: "cost",
            //     Header: "Cost",
            //     disableFilters: true,
            //     Cell: ({ row }) => {
            //         const { symbol, rate } = currentCurrency
            //         let { cost } = row.original
            //         return `${symbol} ${(cost * rate).toFixed(2)}`
            //     }
            // },
            {
                // accessor: "cost",
                Header: "Payout",
                disableFilters: true,
                Cell: ({ row }) => {
                    const { symbol, rate } = currentCurrency
                    let { cost } = row.original
                    return `${symbol} ${(cost * rate * (0.8)).toFixed(2)}`
                }
            },
            {
                accessor: "total",
                Header: "Total",
                disableFilters: true,
                Cell: ({ row }) => {
                    const { symbol, rate } = currentCurrency
                    let { total } = row.original
                    return `${symbol} ${(total * rate).toFixed(2)}`
                }
            },
            // {
            //     accessor: "state",
            //     Header: "State",
            //     disableFilters: true,
            //     Cell: ({ row }) => {
            //         const { symbol, rate } = currentCurrency
            //         let { state } = row.original
            //         return `${symbol} ${(state * rate).toFixed(2)}`
            //     }
            // },
            {
                Header: "Actions",
                Cell: ({ row }) => {
                    return (<div className="is-flex fdr aic">
                        <Button
                            className="stllr-btn colorful fw6 mr-10"
                            style={{ width: 'auto' }}
                            text="SOW Generate"
                            onClick={() => handleOpenGenerateMilestones(row.id)}
                        />
                        <Button className="stllr-btn btn-sm danger" text="REMOVE" onClick={() => removeService(row.id)} />
                    </div>)
                }
            }
        ]),

        paymentMethods = [
            {
                label: 'Stripe',
                value: process.env.REACT_APP_PAYMENT_METHOD_STRIPE,
            },
            {
                label: 'Bank Transfer',
                value: process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER,
            },
        ]

    const removeMilestone = (index) => {
        setProjectMilestones(m => m.filter((i, counter) => {
            return counter != index
        }))
    },
        removeService = index => {
            const expert_id = projectServices.find((e, i) => i == index)?.expert_id
            if (projectMilestones.some(m => m.expert_id === expert_id)) {
                alert('cannot remove expert who have scope of work')
                return
            }
            setProjectServices(s => s.filter((i, counter) => {
                return counter != index
            }))
            setProjectExperts(e => e.filter((i, counter) => {
                return counter != index
            }))
        }

    // const { data: user } = useQuery("user", fetchUser, {
    //     select: (data) => data.data,
    //     refetchOnWindowFocus: false,
    // })

    const { data: serviceExperts, isLoading: isExpertsLoading } = useQuery(["experts", selectedService, currentCurrency], () => fetchExperts(selectedService, currentCurrency?.id), {
        select: (data) => data.experts,
        onSuccess: experts => {
            setExperts(experts.map(e => {
                return {
                    label: `${e.name} - ${e.hourly_rate_formatted}/h`,
                    value: e.expert_id
                }
            }))
        },
        refetchOnWindowFocus: false,
    })

    // Pre-fetch data
    const { isLoading, data: services } = useQuery("services", fetchServices, {
        select: (data) => data.services.map(s => {
            return { label: s.name, value: s.id }
        }),
        refetchOnWindowFocus: false,
    })

    const { isLoading: usersLoading, data: customers } = useQuery("customers", fetchCustomers, {
        select: (data) => data.customers.map(c => {
            return { label: `${c.company_name}`, value: c.id }
        }),
        refetchOnWindowFocus: false,
    })

    // const { data: allExperts, isLoading: allExpertsLoading } = useQuery("allexperts", () => fetchAllExperts(null), {
    //     select: data => data.experts.map(e => {
    //         return { label: e.name, value: e.expert_id }
    //     }),
    //     refetchOnWindowFocus: false,
    // })

    const { data: teams, isLoading: teamsLoading } = useQuery("teams", () => fetchMinifiedTeams(), {
        select: data => data.teams.map(team => {
            return { label: team.name, value: team.id }
        }),
        refetchOnWindowFocus: false,
    })

    const mutation = useMutation(newProject => generateProposal(newProject), {
        onSuccess: (data) => {
            alert("Proposal successfully created, please check your email !")
            window.open(data.url, '_blank')
        },
        onError: error => {
            alert(error.data?.message)
        },
        refetchOnWindowFocus: false,
    })

    const serviceMutation = useMutation(service_id => fetchServiceAvgPrice(service_id), {
        onSuccess: (data) => {
            console.log("this is the data", data)
            setAvgServicePrice(Math.round(data.services_avg_price))
        },
        onError: error => {
            alert(error.data?.message)
        },
        refetchOnWindowFocus: false,
    })

    const handleServiceChange = (serviceId) => {
        console.log('yoo', formikRef.current.values)
        serviceMutation.mutate(serviceId)
        setSelectedService(serviceId)
    }

    const handleExpertChange = (expertId) => {
        const expert = serviceExperts.find(e => e.expert_id === expertId);
        setHourlyRate(expert.hourly_rate_converted)
        setOldRate(expert.hourly_rate_converted)
        setSelectedExpert(expert)
    }

    const updateServices = (event) => {
        event.preventDefault()

        // if (!selectedExpert) {
        //     alert('Expert is not selected')
        //     return;
        // }

        if (!duration) {
            alert('Duration (Months) is not set')
            return;
        }

        if ([hours, cost, payout].every(e => !e)) {
            // alert('You must enter one of these: Cost, Hours or Payout')
            alert('You must enter the payout')
            return;
        }

        if ([hours, cost, payout].filter(Boolean).length > 1) {
            alert('You have to enter ONLY one of these: Cost, Hours or Payout')
            return;
        }

        // const { expert_id, expert_uid, avatar, portfolio_link } = selectedExpert;
        const { symbol, rate } = currentCurrency;
        let newHourlyRate = hourlyRate,
            newHours,
            newCost,
            oldCost,
            newPayout;

        if (hours) {
            newHours = hours
            oldCost = oldRate * newHours
            newCost = newHourlyRate * newHours
            newPayout = newCost - (newCost * 0.2)
        }
        else if (cost) {
            newCost = cost
            newHours = newCost / newHourlyRate
            oldCost = oldRate * newHours
            newPayout = newCost - (newCost * 0.2)
        }
        else if (payout) {
            newPayout = payout
            newCost = newPayout / (1 - 0.2)
            newHours = newCost / newHourlyRate
            oldCost = oldRate * newHours
        }

        const newTotal = duration * newCost
        const oldTotal = duration * oldCost
        const newState = oldTotal - newTotal

        let newService = {
            id: projectServices.length,
            // service_id: selectedService,
            // expert_uid: expert_uid,
            // expert_id: expert_id,
            // avatar: avatar,
            // portfolio_link: portfolio_link,
            duration: duration,
            // hours: newHours,
            // cost: (newCost / rate),
            payout: (newPayout / rate),
            total: (newTotal / rate),
            // hourly_rate: (newHourlyRate / rate),
            state: (newState / rate),
        }

        // Get expert and service
        let service = services.filter(obj => {
            return obj.value === newService.service_id
        })

        let expert = experts.filter(obj => {
            return obj.value === newService.expert_id
        })

        let exists = projectExperts.some((el) => {
            return el.value === expert[0].value
        }),
            results = experts.filter(ex => {
                return ex.value === expert[0].value
            })

        // if (!exists) setProjectExperts([...projectExperts, { label: results[0].label, value: results[0].value }])

        // newService.service_name = service[0].label
        // newService.expert_name = expert[0].label.split(' - ')[0]

        // let curratedServices = [...projectServices, newService]

        // Filter duplicates from curratedServices array and publish it to the milestone service dropdown
        // let fs = curratedServices.reduce((acc, current) => {
        //     const x = acc.find(item => item.service_id === current.service_id);
        //     if (!x) { return acc.concat([current]) } return acc;
        // }, []);

        // console.log(curratedServices)
        // setProjectServices(curratedServices)
        setProjectServices(newService)
        // setFilteredServices(fs)
    }

    useEffect(() => {
        handleCommissionChange(projectServices, null, commission)
    }, [projectServices, currentCurrency, payout, duration])

    const handleCommissionChange = (services, payinValue, commissionValue) => {
        if (!formikRef?.current || !formikRef?.current?.setFieldValue) {
            return
        }
        const { values, setFieldValue } = formikRef.current

        // const totalCost = services.reduce((a, c) => a + Number(c['cost']) * c['duration'], 0)
        // const totalPayout = services.reduce((a, c) => a + Number(c['payout']) * c['duration'], 0)
        const totalPayout = payout * duration
        // const duration = values.is_duration_parallel ? (
        //     Math.max(...projectServices.map(s => parseInt(s.duration)))
        // ) : (
        //     projectServices.reduce((a, c) => a + parseInt(c.duration), 0)
        // )

        if (values.calc_mode === 1) {
            const monthlyPrice = Number(payinValue || values.payin) / currentCurrency.rate
            const totalPrice = monthlyPrice * duration
            if (totalPrice > 0 && totalPayout > 0) {
                if (totalPayout > 0) {
                    const actual_commission = ((totalPrice - totalPayout) / totalPrice) * 100
                    console.log('yoo', actual_commission)
                    setFieldValue('actual_commission', actual_commission > 0 ? actual_commission?.toFixed(2) : 0)
                }
                else {
                    console.log('haha')
                    setFieldValue('actual_commission', 0)
                }

                // if (totalCost > 0) {
                //     const commission = ((totalPrice / totalCost) - 1) * 100
                //     setFieldValue('commission', commission > 0 ? commission?.toFixed(2) : 0)
                // }
                // else {
                //     setFieldValue('commission', 0)
                // }
            }
            else {
                console.log('im here')
                setFieldValue('commission', 0)
                setFieldValue('actual_commission', 0)
            }
        }
        else if (values.calc_mode === 2) {
            console.log('in this else', commissionValue, values.commission)
            const commission = Number(commissionValue || values.commission)
            const totalPrice = totalPayout / (1 - (commission / 100)) * currentCurrency.rate
            const monthlyPrice = totalPrice / duration
            console.log({ totalPayout, commission, totalPrice })
            setFieldValue('payin', monthlyPrice?.toFixed(2))
        }
    }

    const handleMilestoneServiceChange = (serviceId, serviceName) => {
        let array = projectServices.filter(fs => {
            return serviceId == fs.service_id
        }).map(ref => {
            return { label: ref.expert_name, value: ref.expert_id }
        })

        // Get expert of this selected service
        setRefinedExperts(array)

        setMilestone({ ...milestone, service_id: serviceId, service_name: serviceName })
    }

    const updateMilestones = (event) => {
        event.preventDefault();

        let newMilestone = {
            id: projectMilestones.length,
            ...milestone,
        }

        // Check if the same milestone title is assigned for the same expert
        let exists = projectMilestones.some((m) => {
            return m.expert_id === newMilestone.expert_id && m.title === newMilestone.title
        })

        if (exists) {
            alert("A scope of work with the same exact name has been already added")
        }
        else {
            setProjectMilestones([...projectMilestones, newMilestone])
        }
    }

    const getMilestoneExpertName = () => {
        if (milestone?.expert_id) {
            const expert = refinedExperts.find(e => e.value === milestone?.expert_id)
            if (expert) {
                return expert?.label
            }
        }
    }

    // const handleOpenGenerateMilestones = ({ owner_id, is_duration_parallel }) => {
    const handleOpenGenerateMilestones = (id) => {
        if (!formikRef?.current || !formikRef?.current?.values) {
            alert('Something went wrong!')
            return
        }
        const { owner_id, is_duration_parallel, company_name } = formikRef.current.values

        // const expert = projectServices.find(e => e.id == id)
        const service = services.find(s => s.value == selectedService)?.label

        if (!selectedService) {
            alert('Enter a service')
            return
        }
        else if (!duration || duration < 0) {
            alert('Enter duration')
            return
        } else if (!company_name) {
            alert('Enter a Company')
            return
        }

        // if (!expert?.expert_id) {
        //     alert('Expert was not found')
        //     return
        // }

        // const { expert_id, expert_name } = expert
        // const duration = is_duration_parallel ? (
        //     Math.max(...projectServices.map(s => parseInt(s.duration)))
        // ) : (
        //     projectServices.reduce((a, c) => a + parseInt(c.duration), 0)
        // )

        setGenerateMilestones({
            // expert_name,
            // expert_id,
            owner_id,
            // services,
            duration,
            service
        })
    }

    const handleOnSubmitGeneratedMilestones = (milestones) => {
        const { expert_id } = generateMilestones
        if (milestones && Array.isArray(milestones)) {
            const mappedMilestones = milestones.map((m, i) => ({
                id: `${Math.round(Math.random() * 999999)}`,
                expert_id: expert_id,
                title: m.title,
                description: m.description,
                startDate: m.start_date,
                endDate: m.end_date,
                service_id: m.service_id,
                service_name: services.find(s => s.value == m.service_id)?.label
            }))
            console.log({ projectMilestones, mappedMilestones, milestones })
            setProjectMilestones([
                ...projectMilestones,
                ...mappedMilestones,
            ])
        }
    }

    const requireNotExistingOwner = (msg) => {
        return ['new_owner', {
            is: true,
            then: Yup.string().required(msg),
        }]
    }

    const updateModrator = (e) => {
        console.log(e.target.value)
    }

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic jcsb">
                        <h2 className="mt-0 mb-20 mt-20">New proposal</h2>
                    </div>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            company_name: "",
                            moderator_user_id: null,
                            commission: 0,
                            actual_commission: 0,
                            is_duration_parallel: true,
                            is_studio: false,
                            studio_name: null,
                            new_owner: false,
                            is_enterprise: false,
                            is_payins_cash: false,
                            currency: currentCurrency.id,
                            calc_mode: 2,
                            owner_id: null
                        }}
                        validationSchema={Yup.object().shape({
                            company_name: Yup.string().required("Please specify a name for this proposal"),
                            owner_name: Yup.string().when(...requireNotExistingOwner('Please specify owner name')),
                            owner_email: Yup.string().when(...requireNotExistingOwner('Please specify owner email')),
                            // commission: Yup.number().min(10, 'Please update proposal Payins & Payouts to make comission more than 10%'),
                            actual_commission: Yup.number().min(30, 'Please update proposal Payins & Payouts to make comission more than 30%'),
                            currency: Yup.string().required("Please specify a currency for this proposal"),
                            studio_name: Yup.string().nullable().when('is_studio', {
                                is: true,
                                then: Yup.string().required('Please specify studio name for this proposal')
                            }),
                        })}
                        onSubmit={(values, { setSubmitting, errors }) => {
                            setSubmitting(false)

                            // add uuid for new owner creation
                            if (values.new_owner) values.uuid = uuidv4();

                            // calculate project duration
                            const project_duration = values.is_duration_parallel ? (
                                Math.max(...projectServices.map(s => parseInt(s.duration)))
                            ) : (
                                projectServices.reduce((a, c) => a + parseInt(c.duration), 0)
                            )

                            // recalculate service with new currency rate
                            const experts = projectServices.map(e => ({
                                ...e,
                                cost: (e.cost * currentCurrency.rate).toFixed(2),
                                total: (e.total * currentCurrency.rate).toFixed(2),
                                state: (e.state * currentCurrency.rate).toFixed(2),
                            }))

                            const commission = parseInt(values.commission) + 20

                            const price = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: currentCurrency.code?.toUpperCase(),
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }).format(values.payin)
                            mutation.mutate({ ...values, commission, price, project_duration: duration, projectServices: experts, projectMilestones, service: services.find(s => s.value == selectedService)?.label })
                        }}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    touched,
                                    setFieldValue,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                } = props

                                return (
                                    <form className="is-flex jcsb fdc" onSubmit={handleSubmit}>
                                        <div>
                                            <div className="group">
                                                <Grid container spacing={2}>
                                                    <Grid item lg={6}>
                                                        <label htmlFor="name">Company name</label>
                                                        <div className="is-flex fdr aic">
                                                            <Select
                                                                options={customers}
                                                                isLoading={usersLoading}
                                                                name="company_name"
                                                                id="company_name"
                                                                className="has-full-width"
                                                                onChange={(option) => { setFieldValue("company_name", option.label); setFieldValue("owner_id", option.value) }}
                                                                styles={{
                                                                    control: base => ({
                                                                        ...base,
                                                                        height: 40,
                                                                        minHeight: 40
                                                                    })
                                                                }}
                                                            />

                                                            <h2 className="dimmed" style={{ padding: 10 }}>OR</h2>

                                                            <input type="text" name="company_name" onChange={handleChange} className="stllr-input" placeholder="Enter company name" />
                                                            {errors.company_name && touched.company_name ? (
                                                                <span className="v-error">{errors.company_name}</span>
                                                            ) : null}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="group">
                                                <Grid container direction="row" spacing={2}>
                                                    <Grid item lg={12}>
                                                        <div className="is-flex fdr">
                                                            <label>
                                                                <input type="radio" name="calc_mode" checked={values.calc_mode === 2} onChange={e => setFieldValue('calc_mode', 2)} />
                                                                {' '}
                                                                Calculate pay-ins automatically
                                                            </label>
                                                            <label className="mr-20">
                                                                <input type="radio" name="calc_mode" checked={values.calc_mode === 1} onChange={e => setFieldValue('calc_mode', 1)} />
                                                                {' '}
                                                                Calculate commission automatically
                                                            </label>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="group">
                                                <Grid container direction="row" spacing={2}>
                                                    <Grid item lg={3}>
                                                        <label htmlFor="name">Project Currency</label>
                                                        <Select
                                                            name='currency'
                                                            options={currencyOptions}
                                                            value={currencyOptions?.find?.(e => e.value === values.currency)}
                                                            isLoading={isCurrenciesLoading}
                                                            // isDisabled={!values.is_enterprise}
                                                            placeholder="Select currency"
                                                            onChange={({ value }) => {
                                                                setFieldValue('currency', value)
                                                                setCurrentCurrency(currencies.find(e => e.id === value))
                                                            }}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    height: 42,
                                                                    minHeight: 42
                                                                })
                                                            }}
                                                        />
                                                        {errors.currency ? (
                                                            <span className="v-error">{errors.currency}</span>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item lg={2}>
                                                        <label htmlFor="name">Commission %</label>
                                                        <input
                                                            disabled={values.calc_mode === 1}
                                                            type="number"
                                                            name="actual_commission"
                                                            value={values.actual_commission}
                                                            onChange={e => {
                                                                setFieldValue('actual_commission', Number(e.target.value))
                                                                setCommission(Number(e.target.value))
                                                                handleCommissionChange(projectServices, null, e.target.value)
                                                            }}
                                                            className="stllr-input"
                                                            placeholder="Enter project commssion"
                                                            min="10"
                                                            step="any"
                                                        />
                                                        {errors.actual_commission && touched.actual_commission ? (
                                                            <span className="v-error">{errors.actual_commission}</span>
                                                        ) : null}
                                                        <label><sub>Including the 20% independent's fees</sub></label>
                                                    </Grid>
                                                    <Grid item lg={2}>
                                                        <label htmlFor="name">Pay-In</label>
                                                        <input
                                                            type="number"
                                                            name="payin"
                                                            disabled={values.calc_mode === 2}
                                                            value={values.payin}
                                                            onChange={e => {
                                                                setFieldValue('payin', Number(e.target.value))
                                                                handleCommissionChange(projectServices, e.target.value)
                                                            }}
                                                            className="stllr-input"
                                                            placeholder="Enter project price"
                                                            step="any"
                                                        />
                                                        <label><sub>Price per month</sub></label>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="sep mb-18" />

                                            <Grid container direction="row" spacing={2}>
                                                <Grid item lg={12}>
                                                    <label>
                                                        <input type="checkbox" name="is_studio" onChange={e => setFieldValue('is_studio', e.target.checked ? 1 : 0)} />
                                                        {' '}
                                                        is this a team/studio proposal ?
                                                    </label>
                                                </Grid>
                                                {values.is_studio ? <Grid item lg={7}>
                                                    <label htmlFor="name">Team/Studio Name</label>
                                                    <input type="text" name="'studio_name'" onChange={handleChange} onBlur={handleBlur} className="stllr-input" placeholder="Team/Studio Name" value={values.studio_name} />
                                                    <label><sub>do not include "team" or "studio"</sub></label>
                                                </Grid> : null}
                                            </Grid>

                                            <div className="sep mb-18" />

                                            <>
                                                <div className="group">
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item lg={2}>
                                                            <label htmlFor="name">Service</label>
                                                            <Select
                                                                options={services}
                                                                isLoading={isLoading}
                                                                placeholder="Select service"
                                                                onChange={({ value }) => handleServiceChange(value)}
                                                                styles={{
                                                                    control: base => ({
                                                                        ...base,
                                                                        height: 42,
                                                                        minHeight: 42,
                                                                        marginBottom: 10,
                                                                    })
                                                                }}
                                                            />
                                                            <h3>The average of this service prices depending on our records is: ${avgServicePrice}</h3>
                                                        </Grid>

                                                        {/* 
                                                        <Grid item lg={3}>
                                                            <label htmlFor="name">Assigned Experts</label>
                                                            <Select
                                                                options={experts}
                                                                isLoading={isExpertsLoading}
                                                                placeholder="Assign to expert"
                                                                onChange={({ value }) => handleExpertChange(value)}
                                                                styles={{
                                                                    control: base => ({
                                                                        ...base,
                                                                        height: 42,
                                                                        minHeight: 42
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={1}>
                                                            <label htmlFor="name">Hourly rate</label>
                                                            <input type="number" name="hourly_rate" onChange={(e) => setHourlyRate(Number(e.target.value))} className="stllr-input" placeholder="/hr" value={Math.round(hourlyRate)} />
                                                        </Grid> */}

                                                        <Grid item lg={1}>
                                                            <label htmlFor="name">Duration</label>
                                                            <input type="number" name="duration" onChange={(e) => setDuration(e.target.value)} className="stllr-input" placeholder="Months" />
                                                        </Grid>

                                                        <Grid item lg={2}>
                                                            <label htmlFor="name">Payout per month</label>
                                                            <input type="number" name="payout" onChange={(e) => setPayout(e.target.value)} className="stllr-input" placeholder="Payout" />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" spacing={2}>
                                                        {/* <Grid item lg={2}>
                                                            <label htmlFor="name">Hours per month</label>
                                                            <input type="number" name="hours" onChange={(e) => setHours(e.target.value)} className="stllr-input" placeholder="Hours" />
                                                        </Grid>

                                                        <Grid item lg={2}>
                                                            <label htmlFor="name">Cost per month</label>
                                                            <input type="number" name="cost" onChange={(e) => setCost(e.target.value)} className="stllr-input" placeholder="Cost" />
                                                        </Grid> */}

                                                        {/* <Grid item lg={1}>
                                                            <label>&nbsp;</label>
                                                            <Button
                                                                className="stllr-btn bordered primary"
                                                                text="Save"
                                                                onClick={(e) => updateServices(e)}
                                                            />
                                                        </Grid> */}
                                                    </Grid>
                                                </div>
                                                {/* <div className="sep mb-18" /> */}

                                                <div className="mb-25">
                                                    {/* <TableContainer hiddenColumns={[]} notSticky={true} paging={true} columns={columns} data={projectServices} /> */}
                                                </div>
                                            </>


                                            <div className="is-flex aic mt-0 mb-10">
                                                <h4 className="mt-15 mr-15 dimmed">PROJECT SCOPE OF WORK</h4>
                                            </div>

                                            <div className="group">
                                                <Grid container direction="row" spacing={2}>
                                                    <Grid item lg={12}>
                                                        {/* <label>&nbsp;</label> */}
                                                        <Button
                                                            className="stllr-btn colorful fw6 mr-10"
                                                            style={{ width: 'auto' }}
                                                            text="SOW Generate"
                                                            onClick={() => handleOpenGenerateMilestones()}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <label>OR</label>
                                                    </Grid>
                                                    <Grid item lg={7}>
                                                        <label htmlFor="name">Scope of work</label>
                                                        <input type="text" onChange={(e) => setMilestone({ ...milestone, title: e.target.value })} className="stllr-input" placeholder="Milestone Title" />
                                                    </Grid>

                                                    {/* <Grid item lg={2}>
                                                        <label htmlFor="service">Service</label>
                                                        <Select
                                                            options={filteredServices.map(fs => {
                                                                return { label: fs.service_name, value: fs.service_id }
                                                            })}
                                                            onChange={({ value, label }) => handleMilestoneServiceChange(value, label)}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <label>Expert</label>
                                                        <Select
                                                            options={refinedExperts}
                                                            onChange={({ value: expert_id }) => setMilestone({ ...milestone, expert_id })}
                                                        />
                                                    </Grid> */}

                                                    {/* <Grid item lg={2}>
                                                        <label htmlFor="name">Start date</label>
                                                        <DayPickerInput onDayChange={(date) => setMilestone({ ...milestone, startDate: moment(date).format("Y-MM-DD") })} />
                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <label htmlFor="name">End date</label>
                                                        <DayPickerInput onDayChange={(date) => setMilestone({ ...milestone, endDate: moment(date).format("Y-MM-DD") })} />
                                                    </Grid> */}
                                                </Grid>

                                                <Grid container className='mt-20' spacing={2}>
                                                    <Grid item lg={12}>
                                                        <textarea
                                                            type="text"
                                                            name="description"
                                                            value={values.description}
                                                            className="stllr-input"
                                                            onChange={e => setMilestone({ ...milestone, description: e.target.value })}
                                                            onBlur={handleBlur}
                                                        />
                                                        {/* <Editor
                                                            apiKey={process.env.REACT_APP_TINY_API_KEY}
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            init={{
                                                                width: '100%',
                                                                height: 250,
                                                                menubar: false,
                                                                content_style: 'div { background-color: red }',
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat | help',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                            onEditorChange={value => setMilestone({ ...milestone, description: value })}
                                                        /> */}
                                                    </Grid>
                                                    <Grid item lg={1}>
                                                        <Button
                                                            className="stllr-btn bordered primary"
                                                            text="Save"
                                                            onClick={(e) => updateMilestones(e)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="mb-25">
                                                <TableContainer hiddenColumns={[]} notSticky={true} paging={true} columns={milestonesColumns} data={projectMilestones} />
                                            </div>
                                        </div>

                                        <input
                                            type="submit"
                                            className="stllr-btn primary btn-lg"
                                            // onClick={() => saveProject()}
                                            disabled={mutation.isLoading}
                                            value="Create proposal"
                                        />
                                        {Object.keys(errors).length ? <span className="mt-10 v-error">Please check for wrong/missing fields [{Object.keys(errors).join(', ')}]</span> : ``}

                                        <MilestonesDialog
                                            open={Boolean(generateMilestones)}
                                            onClose={() => setGenerateMilestones(null)}
                                            data={generateMilestones}
                                            onSubmit={handleOnSubmitGeneratedMilestones}
                                        />
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </Grid>
            </Container>
        </div>
    )
}

export default AddProposal