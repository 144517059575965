import React from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import Close from '@material-ui/icons/Close';

const TableContainer = ({ name, columns, data, hiddenColumns, paging, notSticky = false, hasFilters, allowSorting, getRowStyles }) => {
  const {
    getTableProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    rows: filterResults,
    prepareRow,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns,
        filters: hasFilters ? columns.map(c => ({ id: c.id || c.accessor, value: c.filterValue })).filter(e => e.value) : [],
        sortBy: allowSorting ? [
          {
            id: 'date_add',
            desc: true,
          },
        ] : [],
      },
      disableSortBy: !allowSorting,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const pageSizeOptions = [
    { label: '10 per page', value: 10 },
    { label: '20 per page', value: 20 },
    { label: '30 per page', value: 30 },
  ];

  const toggleSortBy = (column, desc) => {
    column.clearSortBy();
    column.toggleSortBy(desc);
  };

  return (
    <>
      <div className="is-flex mb-25 aic jcsb">
        {hasFilters ? (
          <div className="is-flex fdc">
            <div className="mb-10">Filter results ({filterResults?.length})</div>
            <div className="is-flex fdr aic mb-10 mt-20">
              {filters.map(f => {
                const filterName = columns.find(c => c.accessor === f.id)?.Header || f.id;
                return (
                  <Chip
                    size="small"
                    className="chip-blue button"
                    style={{ margin: '0 10px 10px 0' }}
                    label={
                      <div className="is-flex fdr aic">
                        <div className="mr-5">
                          {filterName}: <span className="fw6">{f.value}</span>
                        </div>
                        <Close style={{ width: 16, height: 16 }} />
                      </div>
                    }
                    onClick={() => {
                      setAllFilters(filters.filter(e => e.id !== f.id));
                      sessionStorage.removeItem(filters.filter(e => e.id == f.id)[0].id);
                    }}
                  />
                );
              })}
            </div>

            <div className="is-flex aic fw">
              {headerGroups.map(headerGroup => (
                <React.Fragment key={headerGroup.id}>
                  {headerGroup.headers.map(column => (
                    <div className="mr-10" key={column.id}>
                      {column.canFilter ? <h4 className="mb-5">{column.Header}</h4> : null}
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <div />
        )}

        {paging && (
          <div>
            <div className="is-flex aic">
              <button className="stllr-btn icon secondary mr-5" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}

              <button className="stllr-btn icon secondary mr-5" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}

              <Select
                value={pageSizeOptions.find(e => e.value === pageSize)}
                options={pageSizeOptions}
                onChange={({ value }) => setPageSize(value)}
                placeholder={"Page size"}
                styles={{
                  control: base => ({
                    ...base,
                    width: 150,
                    minWidth: 150,
                    height: 40,
                    minHeight: 40,
                  }),
                }}
              />
            </div>
          </div>
        )}
      </div>
      <MaUTable stickyHeader={!notSticky} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                column.showColumn === false ? null : (
                  <TableCell
                    {...column.getHeaderProps()}
                    onClick={() => toggleSortBy(column, !column.isSortedDesc)}
                    key={column.id}
                  >
                    <div className="is-flex fdr">
                      <span style={{ userSelect: 'none' }}>
                        {column.render('Header')}
                      </span>
                      {/* <span className="ml-20 is-flex">
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                      </span> */}
                    </div>
                  </TableCell>
                )
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            const { style, ...props } = row.getRowProps();
            const customStyle = typeof getRowStyles === 'function' ? getRowStyles(row) : {};
            const rowStyle = { ...style, ...customStyle };
            return (
              <TableRow {...props} style={rowStyle} key={i}>
                {row.cells.map(cell => (
                  cell.column.showColumn === false ? null : (
                    <TableCell {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                ))}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter />
      </MaUTable>
    </>
  );
};

export default TableContainer;
