import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_STLLR_API_URL

export const fetchServices = async () => {
    return request({
        url: `${BaseUrl}v1/services`
    })
}


export const fetchActiveServices = async () => {
    return request({
        url: `${BaseUrl}v1/services`,
        params: {
            active: 1
        }
    })
}
export const fetchTools = async () => {
    return request({
        url: `${BaseUrl}v1/tools`
    })
}

export const fetchExperts = async (serviceId, currency_id) => {
    return request({
        url: `${BaseUrl}v1/experts/services?service_id=${serviceId}&currency_id=${currency_id}`
    }, true)
}

export const fetchAllExperts = async (id = null, query) => {
    let params = {}
    if (id) params.id = id
    if (query) params = { ...params, ...query }

    return request({
        url: `${BaseUrl}v1/experts`,
        params,
    }, true)
}

export const fetchExpertStatusOptions = async () => {
    return request({
        url: `${BaseUrl}v1/expert_status`
    }, true)
}

export const updateExpert = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/expert/${id}`,
        method: "PUT",
        data
    }, true)
}

export const submitScore = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/expert/score/${id}`,
        method: "POST",
        data
    }, true)
}

export const updateScore = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/expert/score/${id}`,
        method: "PUT",
        data
    }, true)
}

export const submitRating = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/review/${id}`,
        method: "POST",
        data
    }, true)
}

export const fetchUpcomingPayouts = async (date) => {
    return request({
        url: `${BaseUrl}v1/upcoming_payouts`,
        params: { date }
    }, true)
}

export const fetchWithdrawalRequests = async () => {
    return request({
        url: `${BaseUrl}v1/withdrawal-requests`
    }, true)
}

export const getWithdrawalRequestPDF = async (id) => {
    return request({
        url: `${BaseUrl}v1/withdrawal-request/${id}?pdf=1`,
        responseType: 'arraybuffer'
    }, true)
}

export const updateTransactionById = async ({ id, ...data }) => {
    return request({
        url: `${BaseUrl}v1/transactions/${id}`,
        method: 'PUT',
        data
    }, true)
}

export const deleteTransactionById = async ({ id, ...data }) => {
    return request({
        url: `${BaseUrl}v1/transactions/${id}`,
        method: 'DELETE',
        data
    }, true)
}

export const fetchUnscorredExperts = async (id = null, query) => {
    let params = {}
    if (id) params.id = id
    if (query) params = { ...params, ...query }

    return request({
        url: `${BaseUrl}v1/unscorred-experts`,
        params
    }, true)
}

export const submitWarning = async (data, id) => {
    return request({
        url: `${BaseUrl}v1/warning/${id}`,
        method: "POST",
        data
    }, true)
}

export const fetchBankAccount = async ({ expert_id, transaction_id }) => {
    return request({
        url: `${BaseUrl}v1/expert/bank_account`,
        params: { expert_id, transaction_id }
    }, true)
}

export const fetchExpertTransactions = async (expert_id) => {
    return request({
        url: `${BaseUrl}v1/expert/transactions`,
        params: { expert_id }
    }, true)
}

export const fetchExpertsByEmail = async (email) => {
    let qs = email ? `?email=${encodeURIComponent(email)}` : ''
    return request({
        url: `${BaseUrl}v1/experts${qs}`
    }, true)
}

export const fetchExpert = async (fields = []) => {
    return request({
        url: `${BaseUrl}v1/expert`,
        method: 'GET',
        params: { fields }
    }, true)
}

export const fetchGroups = async () => {
    return request({
        url: `${BaseUrl}v1/project-groups`
    }, true)
}

export const fetchCurrenceis = async (includeRate = false) => {
    return request({
        url: `${BaseUrl}v1/currencies`,
        params: { include_rate: includeRate }
    }, true)
}

export const fetchCurrency = async (params, includeRate = false) => {
    return request({
        url: `${BaseUrl}v1/currencies`,
        params: { include_rate: includeRate, ...params }
    }, true)
}

export const chatWithExpert = async (data) => {
    return request({
        url: `${BaseUrl}v1/start_admin_chat`,
        method: "POST",
        data
    }, true)
}

export const uploadWork = async (data) => {
    return request({
        url: `${BaseUrl}v1/works`,
        method: 'POST',
        data: data,
    }, true)
}

export const deleteWork = async (id) => {
    return request({
        url: `${BaseUrl}v1/works/${id}`,
        method: 'DELETE',
    }, true)
}

export const fetchTopForNiche = async ({ niche_id }) => {
    return request({
        url: `${BaseUrl}v1/top-for-niche`,
        method: "GET",
        params: { niche_id }
    }, true)
}