import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_STLLR_API_URL

export const fetchTeams = async (teamId=null, filters) => {
    let qs = teamId ? `?id=${teamId}` : filters || ``
    return request({
        url: `${BaseUrl}v1/teams${qs}`
    }, true)
}

export const editTeam = async (data, teamId) => {
    return request({
      url: `${BaseUrl}v1/teams/${teamId}`,
      method: "PUT",
      data
    }, true)
  }

  export const createTeam = async (data) => {
    return request({
      url: `${BaseUrl}v1/teams`,
      method: 'POST',
      data
    }, true)
  }

  export const fetchMinifiedTeams = async () => {
    return request({
        url: `${BaseUrl}v1/admin-teams`
    }, true)
}