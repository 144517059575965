import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from "react-query";
import {
  fetchProjectConversations,
  fetchProjects
} from '../services';
import { ConversationsSidebar } from '../components/ConversationsSidebar';
import { Grid } from '@material-ui/core';
import { ChatBox } from "../components"
import { Container } from 'material-ui-core';
import slugify from 'slugify';
import { OfferDialogView } from '../components/OfferDialogView';

const Chat = () => {
  // const { chatId } = useParams()
  const { chatId } = useParams();
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [currentChat, setCurrentChat] = useState()
  const [offerPayload, setOfferPayload] = useState(null)

  const handleOpenOfferDialogue = (key, payload) => {
    if (!payload) {
      alert('Offer has been expired', 'error')
      return
    }
    setOfferPayload(payload)
  }

  const handleCloseOfferDialogue = () => {
    setOfferPayload(null)
  }

  const { data: chats, isLoading: isChatsLoading, isError: chatsError, refetch: refetchChats } = useQuery(["chats", chatId], () => fetchProjectConversations(), {
    select: data => data.list,
    keepPreviousData: true,
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  React.useEffect(() => {
    if (chatId && chats?.length) {
      const chat = chats.find(ch => ch.uid === chatId)
      setCurrentChat(chat)
    }  else if (!chatId && chats?.length) {
      setCurrentChat(chats[0])
    }
  }, [chatId, chats])

  const handleOnConversationClicked = (chat) => {
    history.push(`/chats/${chat.uid}`);
  }


  const handleToggleChange = (event) => {
    setChecked(event.target.checked);
  };

  if (chatsError) return 'Error occured';

  if (isChatsLoading) return 'loading..';

  console.log('eyy', currentChat)

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <Grid item lg={3}>
          <ConversationsSidebar
            checked={checked}
            search={search}
            onSearch={text => setSearch(text)}
            list={chats}
            activeProject={chatId || chats[0].uid}
            onConversationClicked={handleOnConversationClicked}
            handleChange={handleToggleChange}
            showChecked={true}
          />
        </Grid>

        <Grid item lg={9}>
          <div style={{ width: '100%', height: '86.5vh' }}>
            {currentChat && <ChatBox
              // projectId={!project.chat_id ? project.id : undefined}
              chatId={currentChat.id || chats[0].uid}
              userEmail={process.env.REACT_APP_ADMIN_EMAIL}
              members={currentChat.members}
              title={`${currentChat.name}`}
              actions={[
                { name: 'open-offer-review', handler: handleOpenOfferDialogue },
              ]}
              onSendMessage={() => refetchChats()}
              onReceiveMessage={() => refetchChats()}
            />}
          </div>
        </Grid>
      </Grid>

      <OfferDialogView
        open={Boolean(offerPayload)}
        onClose={handleCloseOfferDialogue}
        payload={offerPayload}
      />

    </Container>
  );
}

export default Chat;