import React from 'react';
import { useParams, useHistory } from 'react-router';
import { useQuery } from "react-query";
import { fetchAllExperts, fetchExpertConversations } from '../services';
import { Grid } from '@material-ui/core';
import { ChatBox } from "../components"
import { Container } from 'material-ui-core';
import { ConversationsSidebar } from '../components/ConversationsSidebar';

const ChatExpert = () => {
    const { id } = useParams();
    const history = useHistory()

    console.log({ id })
    const { data: expert, ...query } = useQuery(["expert", id], () => fetchAllExperts(id), {
        select: ({ experts }) => experts[0],
        refetchOnWindowFocus: false,
    })

    const {
        data: conversations,
        isLoading: isConversationsLoading,
        refetch: refetchConversations,
        error: conversationsError,
    } = useQuery(['conversations'], () => fetchExpertConversations())

    const handleOnConversationClicked = ({ id }) => {
      history.push(`/chat-expert/${id}`);
    }

    if (query.error || conversationsError) return 'Error occured';

    if (query.isLoading || isConversationsLoading) return 'Loading..';

    return (
        <Container maxWidth='lg'>
            <Grid container direction="row" spacing={2}>
                <Grid item lg={2}>
                    <ConversationsSidebar
                        list={conversations.list.map(e => ({
                            id: e.id,
                            owner_name: e.name,
                            owner_avatar: e.avatar,
                            project_status: e.tier,
                            unread_messages: e.unread_messages,
                        }))}
                        activeProject={id}
                        onConversationClicked={handleOnConversationClicked}
                    />
                </Grid>
                <Grid item lg={9}>
                    {id && <ChatBox
                        expertId={id}
                        userEmail={process.env.REACT_APP_ADMIN_EMAIL}
                        members={[expert]}
                        title={`${expert.name}`}
                        actions={[]}
                        meetingButton={false}
                        onSendMessage={() => refetchConversations()}
                        onReceiveMessage={() => refetchConversations()}
                    />}
                </Grid>
            </Grid>
        </Container>
    );
}

export default ChatExpert;