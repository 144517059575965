import React, { useEffect, useMemo, useState } from "react"
import { Card, CircularProgress, Container, Grid } from "@material-ui/core"
import TableContainer from "../components/TableContainer"
import { useMutation, useQuery } from "react-query"
import { fetchUpcomingPayouts } from "../services/expert"
import Select from "react-select"
import moment from "moment"

function UpcomingPayouts() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})
  const [dates, setDates] = useState([])
  const [currentDate, setCurrentDate] = useState({})
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const [currentCat, setCurrentCat] = useState(null)

  const { mutate: fetchData } = useMutation(date => fetchUpcomingPayouts(date), {
    onSuccess: res => {
      setData(res)
      setIsLoading(false)
    },
    onError: error => {
      alert(error.data?.message)
      console.log(error)
      setIsLoading(false)
    }
  })

  // fetch new data on selected month changes
  useEffect(() => {
    if (currentDate.value) {
      setIsLoading(true)
      fetchData(currentDate.value)
    }
  }, [currentDate])

  // set current month to the first generated one
  useEffect(() => {
    if (dates.length) {
      setCurrentDate(dates[0])
    }
  }, [dates])

  // generate array of upcoming months
  useEffect(() => {
    let dates = [],
      m = new Date().getMonth() + 1,
      y = new Date().getFullYear()
    for (let i = 0; i < 20; i++) {
      if (m > 12) {
        m = 1
        y++
      }
      const d = moment(`${y}-${m}`, 'YYYY-M').daysInMonth()
      dates.push({
        label: `${months[m - 1]} ${y}`,
        value: `${y}-${m}-${d}`
      })
      m++
    }
    setDates(dates)
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Creator',
      accessor: `expert_name`,
      Cell: ({ row }) => {
        const { expert_id, collector_expert_id, expert_name, collector_expert_name, is_ugc } = row.original
        return is_ugc ? expert_name : (
          expert_id !== collector_expert_id ? 
          (
            <div>
              {expert_name} (Collected by: {collector_expert_name})
            </div>
          ) : expert_name
        )
      }
    },
    {
      Header: 'Project',
      accessor: `project_name`,
    },
    {
      Header: 'Payout date',
      accessor: `payout_date`,
      Cell: ({ row }) => {
        const { payout_date, is_ugc } = row.original
        return `${is_ugc ? 'UGC' : moment(payout_date).format('ll')}`
      }
    },
    {
      Header: 'Amount',
      accessor: `cost`,
      Cell: ({ row }) => {
        const { currency_code, cost } = row.original
        return `${currency_code} ${cost}`
      }
    },
    {
      Header: 'Payment method',
      accessor: `payment_method_name`,
    },
    // {
    //   Header: 'Is UGC?',
    //   accessor: `is_ugc`,
    //   Cell: ({ row }) => {
    //     const { is_ugc } = row.original
    //     return `${is_ugc === 1 ? 'Yes' : 'No'}`
    //   }
    // },
  ])

  return (
    <Container spacing={1} maxWidth='lg'>
      <Grid container>
        <Grid item lg={8} />
        <Grid item lg={4}>
          {dates.length && currentDate.value ? <Select
            options={dates}
            value={currentDate}
            name="dates"
            onChange={(option) => setCurrentDate(option)}
          /> : <Select isLoading />}
        </Grid>
      </Grid>
      <Grid container>
        {isLoading ? <CircularProgress /> :
          <Grid item lg={12}>
            {data.stats.map(stats => <CatRow data={stats} />)}

            <TableContainer
              paging={true}
              columns={columns}
              hiddenColumns={[]}
              data={data.payouts.filter(p => (
                !currentCat ||
                (currentCat === 'other' && !p.payment_method_id) ||
                p.payment_method_id == currentCat
              ))}
            />
          </Grid>}
      </Grid>
    </Container>
  )
}


const CatRow = ({ data }) => {

  return (
    <div className="is-flex fdr aic jcsa mt-20 mb-55">
      <CatBox
        label='Total'
        value={data.total}
        currency={data.currency_code}
      // active={currentCat === null}
      // onClick={() => setCurrentCat(null)}
      />
      {/* <CatBox
        label='Stripe'
        value={data.stripe}
        currency={data.currency_symbol}
      // active={currentCat === process.env.REACT_APP_PAYMENT_METHOD_STRIPE}
      // onClick={() => setCurrentCat(process.env.REACT_APP_PAYMENT_METHOD_STRIPE)}
      />
      <CatBox
        label='Paypal'
        value={data.paypal}
        currency={data.currency_symbol}
      // active={currentCat === process.env.REACT_APP_PAYMENT_METHOD_PAYPAL}
      // onClick={() => setCurrentCat(process.env.REACT_APP_PAYMENT_METHOD_PAYPAL)}
      /> */}
      <CatBox
        label='Bank Transfer'
        value={data.bank}
        currency={data.currency_code}
      // active={currentCat === process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER}
      // onClick={() => setCurrentCat(process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER)}
      />
      <CatBox
        label='Other'
        value={data.other}
        currency={data.currency_code}
      // active={currentCat === 'other'}
      // onClick={() => setCurrentCat('other')}
      />
    </div>
  )
}

const CatBox = ({ label, value, currency, active, ...props }) => {
  return (
    <Card
      {...props}
      className={`payouts-box ${active && 'selected'}`}
    >
      {label}: <span>{currency} {value?.toFixed(2)}</span>
    </Card>
  )
}

export default UpcomingPayouts