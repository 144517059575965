import React, { useState, useEffect } from "react"
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp'
import { CustomModal } from "./modal"
import { Button } from "./"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { useQuery, useMutation } from "react-query"
import { ReactComponent as StarFilled } from '../assets/images/StarFilled.svg'
import { ReactComponent as Star } from '../assets/images/star.svg'
import {
  Avatar,
} from "@material-ui/core"
import {
  Rating,
  TextareaAutosize,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import {
  submitRating
} from "../services"
import Select from "react-select"

function RatingModal({ isOpen, onClose, data }) {
  const { experts = [], projectId, month } = data || {}
  const [reviewSubmitted, setReviewSubmitted] = useState(false),
    handleClose = () => onClose(false)

  const mutation = useMutation(({ expert, ...review }) => submitRating(review, expert), {
    onSuccess: (data) => {
      setReviewSubmitted(data.status)

      window.location.href = '/all-projects'
    }
  })

  const getMonthsOptions = ({ value }) => {
    const expert = experts.find(e => e.id === value)
    const options = []

    if (expert) {
      for (let i = 1; i <= month; i++) {
        if (expert.months.includes(String(i))) continue
        options.push({
          label: `${i === 1 
            ? '1st'
            : i === 2
              ? '2nd'
              : i === 3
                ? '3rd'
                : `${i}th`
          } Month`,
          value: i,
        })
      }
    }

    return options
  }

  const expertOptions = experts.map(e => ({
    label: e.name,
    value: e.id
  }))

  return (
    <CustomModal
      open={isOpen}
      onClose={handleClose}
      className="commonCustomModal feedbackModal"
      body={
        <Formik
          initialValues={{ quality: 0, deadline: 0, response_time: 0, comment: 0, expert: expertOptions[0] }}
          // validationSchema={{}}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            mutation.mutate({
              ...values,
              project_id: projectId,
              expert: values.expert.value,
              month: values.month.value,
            })
          }}
          validationSchema={Yup.object().shape({
            month: Yup.mixed().required('Please select a month'),
          })}
        >
          {({ errors, touched, isSubmitting, handleChange, handleBlur, values, setFieldValue }) => (
            <Form className="form">
              <div>
                <div className="is-flex aic profileHead">
                  <Avatar
                    alt={`${experts[0].name}`}
                    src={`${experts[0].avatar}`}
                    className=""
                    style={{ width: '56px', height: '56px' }}
                  />
                  <h5 className="ml-20">Feedback for {experts.length == 1 ? experts[0].name : "experts"}</h5>
                </div>
                <div className="mainRatingSection">
                  <div className="is-flex aic listing">
                    <h4 className="fw7">Expert name</h4>
                    <Select
                      options={expertOptions}
                      name="expert"
                      onChange={( value ) => {
                        setFieldValue("expert", value)
                        setFieldValue('month', null)
                      }}
                      className="has-half-width"
                      defaultValue= {values.expert}
                    />
                  </div>
                  <div className="is-flex aic listing">
                    <h4 className="fw7">Month</h4>
                    <Select
                      options={getMonthsOptions(values.expert)}
                      name="expert"
                      onChange={( value ) => setFieldValue("month", value)}
                      className="has-half-width"
                      value= {values.month}
                    />
                    {errors.month && (
                      <span className="v-error">{errors.month}</span>
                    )}
                  </div>
                  <div className="is-flex aic listing">
                    <h4 className="fw7">Quality</h4>
                    <Rating
                      name="size-small"
                      onChange={(e) => setFieldValue("quality", e.target.value)}
                      defaultValue={1} size="large"
                      icon={<StarFilled />}
                      emptyIcon={<Star />}
                    />
                  </div>
                  <div className="is-flex aic listing">
                    <h4 className="fw7">Adherance to deadlines</h4>
                    <Rating
                      name="size-small"
                      onChange={(e) => setFieldValue("deadline", e.target.value)}
                      defaultValue={1} size="large"
                      icon={<StarFilled />}
                      emptyIcon={<Star />}
                    />
                  </div>
                  <div className="is-flex aic listing">
                    <h4 className="fw7">Response time</h4>
                    <Rating
                      name="size-small"
                      onChange={(e) => setFieldValue("response_time", e.target.value)}
                      defaultValue={1} size="large"
                      icon={<StarFilled />}
                      emptyIcon={<Star />}
                    />
                  </div>
                  <h4 className="fw7 has-full-width mb-15 mt-20">Do you have any thoughts about this expert that you want to share?</h4>
                  <TextareaAutosize
                    maxRows={4}
                    onChange={(e) => setFieldValue("comment", e.target.value)}
                    aria-label="minimum height"
                    placeholder=""
                  />
                  <h4 className="fw7 has-full-width mb-15 mt-20">May we follow you up on your feedback?</h4>
                  <RadioGroup
                    onChange={(e) => { }}
                    name="company_type"
                    defaultValue={'Yes'}
                    className="feedModalRadio"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <div className="is-flex aic">
                    {reviewSubmitted && <><CheckCircleSharpIcon className="checkIcon" />
                      <h4 className="fw7 has-full-width mb-15">Thanks for your feedback!</h4></>}
                  </div>
                </div>
                <div className="is-flex aic jcfe">
                  <Button
                    className="stllr-btn primary btn-md fw7"
                    text="Submit"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  )
}

export { RatingModal }