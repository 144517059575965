import React, { useState } from "react"
import { fetchUser } from "../services"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { Container, Grid } from "@material-ui/core"
import { ExitToApp } from '@material-ui/icons';
import { signout } from "../utils"
import { useLocation } from "react-router";
import UpcomingPayouts from "../pages/upcoming-payouts"
import { useAuth } from "../utils/Auth"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export const Header = () => {
    const { user } = useAuth()
    const location = useLocation()
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    const tabs = [
        { path: "/", label: "UGC" },
        { path: "/all-companies", label: "Companies" },
        { path: "/all-projects", label: "Projects" },
        { path: "/all-coupons", label: "Coupons" },
        { path: "/all-subscriptions", label: "Subscriptions" },
        { path: "/upcoming-payouts", label: "Upcoming Payouts" },
        { path: "/withdrawal-requests", label: "Withdrawal Requests" },
        { path: "/page-activities", label: "Page Activities" },
    ]

    if (user?.email == process.env.REACT_APP_FINANCE_ADMIN_EMAIL) {
        if (location.pathname !== "/upcoming-payouts" &&
            location.pathname !== "/withdrawal-requests" &&
            location.pathname !== "/acc-projects" &&
            !location.pathname.startsWith('/project-payments/')) {
            window.location.href = "/upcoming-payouts"
        }
    } else if (user?.email == process.env.REACT_APP_PERSONAL_SKILLS_EDITOR) {
        if (location.pathname !== "/unscorred-experts" &&
            !location.pathname.startsWith('/vetter-score-expert/') &&
            !location.pathname.startsWith('/vetter-application/')) {
            window.location.href = "/unscorred-experts"
        }
    } else if (user?.email == process.env.REACT_APP_TECHNICAL_SKILLS_EDITOR) {
        if (location.pathname !== "/marketeer-experts" &&
            location.pathname !== "/marketeer-projects" &&
            location.pathname !== "/all-companies" &&
            !location.pathname.startsWith('/vetter-score-expert/') &&
            !location.pathname.startsWith('/vetter-application/')) {
            window.location.href = "/marketeer-experts"
        }
    }

    return (
        <div className="header is-flex aic">
            <Container>
                <Grid container direction="row" spacing={3}>
                    <div className="is-flex fdr aic jcsb has-full-width">
                        <div className="is-flex fdr aic">
                            {tabs.map(tab => (
                                <a
                                    key={tab.path}
                                    href={tab.path}
                                    className={`header-tab ${location.pathname === tab.path ? 'active' : ''}`}
                                >
                                    {tab.label}
                                </a>
                            ))}
                            <List component="nav" aria-labelledby="nested-list-subheader">
                                <ListItemButton onClick={handleClick}>
                                    <ListItemText primary="See more" />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "white",
                                        zIndex: 10,
                                        marginTop: "8px",
                                    }}
                                >
                                    <List component="div" disablePadding>
                                        <ListItemButton>
                                            <a href="/all-experts">Experts</a>
                                        </ListItemButton>
                                        <ListItemButton>
                                            <a href="/all-packages">Packages</a>
                                        </ListItemButton>
                                        <ListItemButton>
                                            <a href="/new-proposal">Proposal</a>
                                        </ListItemButton>
                                        <ListItemButton>
                                            <a href="/chats">Chats</a>
                                        </ListItemButton>
                                        <ListItemButton className="single-line-text">
                                            <a href="/activities">Chat Activities</a>
                                        </ListItemButton>
                                        <ListItemButton className="single-line-text">
                                            <a href="/demo-requests">Demo Requests</a>
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </List>
                        </div>

                        <button
                            className="dimmed borderless transparent bold is-flex aic"
                            onClick={() => signout()}
                        >
                            <ExitToApp size={15} style={{ marginRight: 5 }} />
                            Logout
                        </button>
                    </div>
                </Grid>
            </Container>
        </div >
    )
}

export default Header
