import React, { useRef } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import * as Yup from "yup"
import { Formik } from 'formik'
import { Editor } from '@tinymce/tinymce-react'
import { Button } from './button'

export const TeamMilestoneDialog = ({ open, onClose, onSubmit, title, ...props }) => {
    const editorRef = useRef()

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <Formik
                initialValues={{
                    title: "",
                    description: "",
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required("Please enter a valid scope of work title"),
                    description: Yup.string().required("Please enter a valid scope of work description")
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 1000)
                    onSubmit(values)
                }}
            >
                {
                    props => {
                        const {
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            handleSubmit
                        } = props

                        return (
                            <form onSubmit={handleSubmit} style={{ width: "98%" }}>
                                <DialogTitle id="form-dialog-title">{title || 'New scope of work'}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        autoFocus
                                        margin="normal"
                                        className="mb-10"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="title"
                                        variant="outlined"
                                        label="Enter scope of work title"
                                        type="text"
                                        fullWidth
                                    />
                                    {errors.title && touched.title && (
                                        <span className="v-error">{errors.title}</span>
                                    )}


                                    <Editor
                                        apiKey={process.env.REACT_APP_TINY_API_KEY}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        init={{
                                            width: '100%',
                                            height: 200,
                                            menubar: false,
                                            placeholder: 'Enter scope of work description',
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                        onEditorChange={value => setFieldValue('description', value)}
                                    />
                                    {errors.description && touched.description && (
                                        <span className="v-error">{errors.description}</span>
                                    )}

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onClose} className="stllr-btn link" text="Cancel" />
                                    <Button type="submit" className="stllr-btn primary" text="Submit" disabled={isSubmitting} />
                                </DialogActions>
                            </form>
                        )
                    }
                }
            </Formik>
        </Dialog>
    )
}