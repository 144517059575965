import React, { useState, useRef, lazy, Suspense } from "react"
import * as Yup from "yup"
import { v4 as uuidv4 } from 'uuid'
import { useQuery, useMutation } from "react-query"
import { Button } from "../../components/button";
import { FieldArray, Formik } from "formik"
import { Container, Grid } from '@material-ui/core';
import { fetchExperts, fetchCustomers, createProject, fetchCurrenceis, fetchVideoTypes, fetchPlatforms, fetchNiches, fetchProductDelivering, fetchToneOfVoices, fetchCountries, fetchLanguages, fetchContentTypes, fetchVideoFormats } from "../../services"
import Select from "react-select"
import { Editor } from '@tinymce/tinymce-react'

function AddUGCProject() {
  const formikRef = useRef(null)
  const [expertOptions, setExpertOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const genders = [
    { value: 'm', label: 'Male' },
    { value: 'f', label: 'Female' },
  ]
  const ages = [
    [0, 18],
    [18, 30],
    [30, 0],
  ].map(e => ({ value: e, label: `${!e[0] ? `Less than ${e[1]}` : !e[1] ? `More than ${e[0]}` : `${e.join('-')}`} Years` }))

  const places = [
    { value: 'in', label: 'Indoor' },
    { value: 'out', label: 'Outdoor' },
  ]

  const emptyVideoObject = {
    expert_id: null,
    type_id: null,
  }

  const { data: currencyOptions = [], isLoading: isCurrenciesLoading } = useQuery(['currencies'], () => fetchCurrenceis(), {
    select: res => res.currencies?.map(e => ({ label: `${e.name} - ${e.code}`, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const paymentMethods = [
    {
      label: 'Stripe',
      value: process.env.REACT_APP_PAYMENT_METHOD_STRIPE,
    },
    {
      label: 'Bank Transfer',
      value: process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER,
    },
  ]

  const { data: experts = [], isLoading: isExpertsLoading } = useQuery(["experts"], () => fetchExperts(165, 3), {
    select: (data) => data.experts,
    onSuccess: experts => {
      setExpertOptions(experts.map(e => ({ label: e.name, value: e.expert_id })))
    },
    refetchOnWindowFocus: false,
  })

  const { isLoading: isCustomersLoading, data: customers } = useQuery("customers", fetchCustomers, {
    select: (data) => data.customers.map(c => {
      return { label: `${c.name} - ${c.email}`, value: c.id }
    }),
    refetchOnWindowFocus: false,
  })

  const { data: videoTypes = [], isLoading: isVideoTypesLoading } = useQuery(["video-types"], () => fetchVideoTypes({ currency_id: 3 }), {
    select: (data) => data.plans,
    onSuccess: types => {
      setTypeOptions(types.map(e => ({ label: e.name, value: e.id })))
    },
    refetchOnWindowFocus: false,
  })

  const { data: platforms = [], isLoading: isPlatformsLoading } = useQuery(["platforms"], () => fetchPlatforms(), {
    select: (data) => data.platforms.map(e => ({ label: e.name, value: e.id, slug: e.slug })),
    refetchOnWindowFocus: false,
  })

  const { data: niches = [], isLoading: isNichesLoading } = useQuery(["niches"], () => fetchNiches(), {
    select: (data) => data.niches.map(e => ({ label: e.name, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const { data: delivering = [], isLoading: isDeliveringLoading } = useQuery(["delivering"], () => fetchProductDelivering(), {
    select: (data) => data.proudct_delivering.map(e => ({ label: e.name, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const { data: tones = [], isLoading: isTonesLoading } = useQuery(["tones"], () => fetchToneOfVoices(), {
    select: (data) => data.tones.map(e => ({ label: e.name, value: e.id, slug: e.slug })),
    refetchOnWindowFocus: false,
  })

  const { data: countries = [], isLoading: isCountriesLoading } = useQuery(["countries"], () => fetchCountries(), {
    select: (data) => data.countries.map(e => ({ label: e.name, value: e.id, code: e.code })),
    refetchOnWindowFocus: false,
  })

  const { data: languages = [], isLoading: isLanguagesLoading } = useQuery(["languages"], () => fetchLanguages(), {
    select: (data) => data.languages.map(e => ({ label: e.name, value: e.id, code: e.code })),
    refetchOnWindowFocus: false,
  })

  const { data: contentTypes = [], isLoading: isContentTypeLoading } = useQuery(["content-types"], () => fetchContentTypes(), {
    select: (data) => data.content_types.map(e => ({ label: e.name, value: e.id, slug: e.slug })),
    refetchOnWindowFocus: false,
  })

  const { data: formats = [], isLoading: isFormatsLoading } = useQuery(["video-formats"], () => fetchVideoFormats(), {
    select: (data) => data.video_formats.map(e => ({ label: e.name, value: e.id, slug: e.slug, ratio: e.ratio })),
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation(newProject => createProject(newProject), {
    onSuccess: () => {
      alert("Project successfully added !")
      window.location.href = `${process.env.REACT_APP_STLLR_ADMIN_URL}all-projects`
    },
    onError: error => {
      alert(error.data?.message)
    },
    refetchOnWindowFocus: false,
  })


  const requireNotExistingOwner = (msg) => {
    return ['new_owner', {
      is: true,
      then: Yup.string().required(msg),
    }]
  }

  const constcutBriefJSON = (data) => {
    const { platform, niche, product_delivery, country, language, tone, content_type, video_format, instructions, reference } = data
    const brief = {}

    const insertItem = (key, value, source) => {
      const item = source.find(e => e.value === value)
      console.log(key, value, item)
      if (item) {
        brief[key] = {
          value: item.value,
          label: item.label,
        }
      }
    }

    // insertItem('platform', platform, platforms)
    insertItem('niche', niche, niches)
    insertItem('product_delivering', product_delivery, delivering)
    insertItem('country', country, countries)
    // insertItem('language', language, languages)
    // insertItem('toneOfVoice', tone, tones)
    // insertItem('contentType', content_type, contentTypes)
    // insertItem('format', video_format, formats)

    // brief.instructions = instructions
    // brief.reference = reference

    return JSON.stringify(brief)
  }

  const calculateCost = (video, videos, formatted = true) => {
    const type = videoTypes.find(e => e.id === video.type_id)
    const number_of_videos = videos.length
    if (type) {
      if(number_of_videos >= 10) {
        return formatted ? type.discount_price_formatted : type.discount_price_converted
      }
      else {
        return formatted ? type.price_formatted : type.price_converted
      }
    }
    return null
  }

  const getPrice = (video, formatted = true) => {
    const type = videoTypes.find(e => e.id === video.type_id)
    if (type) {
      return formatted ? type.price_formatted : type.price_converted
    }
    return null
  }

  const calculatePayout = (video, index, videos = [], formatted = true) => {
    const type = videoTypes.find(e => e.id === video.type_id)
    if (type) {
      if (videos.filter(e => e.expert_id && e.expert_id === video.expert_id).length > 1) {
        return formatted ? type.softened_earning_formatted : type.softened_earning_converted
      }
      return formatted ? type.earning_formatted : type.earning_converted
    }
    return null
  }


  return (
    <div className="content-wrapper mb-55">
      <Container spacing={1}>
        <Grid item lg={12}>
          <div className="is-flex aic jcsb">
            <h2 className="mt-0 mb-5 mt-20">New UGC project</h2>
          </div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              title: "",
              description: "",
              owner_id: "",
              account_manager_id: null,
              new_owner: false,
              is_enterprise: false,
              enterprise_fees: 0,
              currency: 3,
              videos: [],
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Please enter title for this project"),
              goal: Yup.string().required("Please enter description for this project"),
              owner_id: Yup.number().when('new_owner', {
                is: false,
                then: Yup.number().required("Please select an owner for this project")
              }),
              owner_name: Yup.string().when(...requireNotExistingOwner('Please specify owner name')),
              owner_email: Yup.string().when(...requireNotExistingOwner('Please specify owner email')),
              company_name: Yup.string().when(...requireNotExistingOwner('Please specify company name')),
              payment_method: Yup.string().required("Please specify a payment method for this project"),
              currency: Yup.string().required("Please specify a currency for this project"),
              videos: Yup.array().of(Yup.object().shape({
                // expert_id: Yup.number().nullable().required("Please select a creator"),
                type_id: Yup.number().nullable().required("Please select a video type"),
              })).min(1, 'Please insert at least one video'),
              enterprise_fees: Yup.number().when('is_enterprise', {
                is: true,
                then: Yup.number().min(1).required("Please set the enterprise fees")
              }),
            })}
            onSubmit={(values, { setSubmitting, errors }) => {
              const data = values
              setSubmitting(false)

              data.projectVideos = data.videos.map((v, i) => {
                const cost = calculateCost(v, data.videos, false)
                const price = getPrice(v, false)
                const discount = price - cost
                const payout = calculatePayout(v, i, data.videos, false)
                return {
                  ...v,
                  price,
                  cost,
                  discount,
                  payout,
                }
              })
              console.log(data)

              // add uuid for new owner creation
              if (data.new_owner) data.uuid = uuidv4();

              data.service_id = 165

              data.brief = constcutBriefJSON(values)

              data.price = data.projectVideos.reduce((a, c) => a + (c.cost || 0), 0) + Number(data.is_enterprise ? data.enterprise_fees : 0)

              mutation.mutate(data)
            }}
          >
            {
              props => {
                const {
                  values,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props

                return (
                  <form className="is-flex jcsb fdc" onSubmit={handleSubmit}>
                    <div>
                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">PROJECT INFORMATION</h4>
                      </div>

                      {/* Project Title */}
                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="name">Project title</label>
                            <input type="text" name="name" onChange={handleChange} className="stllr-input" placeholder="Enter project title" />
                            {errors.name && touched.name ? (
                              <span className="v-error">{errors.name}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Project Description */}
                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="goal">Project Description</label>
                            <Editor
                              apiKey={process.env.REACT_APP_TINY_API_KEY}
                              init={{
                                width: '100%',
                                height: 200,
                                menubar: false,
                                placeholder: 'Enter project description',
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount', 'textcolor'
                                ],
                                toolbar: ' bullist numlist ',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              }}
                              onEditorChange={value => setFieldValue('goal', value)}
                              value={values.goal}
                            />
                            {/* <textarea
                              name="goal"
                              onChange={handleChange}
                              className="stllr-input"
                              placeholder="Enter project description"
                            ></textarea> */}
                            {errors.goal && touched.goal ? (
                              <span className="v-error">{errors.goal}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Project Create Owner? */}
                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label>
                              <input
                                type="checkbox"
                                name="new_owner"
                                checked={values.owner_exists}
                                onChange={e => setFieldValue('new_owner', e.target.checked)}
                              />{' '}
                              Create new owner?
                            </label>
                          </Grid>
                        </Grid>
                      </div>

                      {/* Project Project Owner */}
                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          {values.new_owner ? (<>
                            <Grid item lg={3}>
                              <label htmlFor="owner_name">Project owner name</label>
                              <input type="text" name="owner_name" value={values.owner_name} onChange={handleChange} className="stllr-input" placeholder="Enter project owner name" />
                              {errors.owner_name && touched.owner_name ? (
                                <span className="v-error">{errors.owner_name}</span>
                              ) : null}
                            </Grid>
                            <Grid item lg={3}>
                              <label htmlFor="company_name">Project company name</label>
                              <input type="text" name="company_name" value={values.company_name} onChange={handleChange} className="stllr-input" placeholder="Enter project company name" />
                              {errors.company_name && touched.company_name ? (
                                <span className="v-error">{errors.company_name}</span>
                              ) : null}
                            </Grid>
                            <Grid item lg={6} />
                            <Grid item lg={3}>
                              <label htmlFor="owner_email">Project owner email</label>
                              <input type="email" name="owner_email" value={values.owner_email} onChange={handleChange} className="stllr-input" placeholder="Enter project owner email" />
                              {errors.owner_email && touched.owner_email ? (
                                <span className="v-error">{errors.owner_email}</span>
                              ) : null}
                            </Grid>
                          </>) : (
                            <Grid item lg={3}>
                              <label htmlFor="owner_id">Project owner</label>
                              <Select
                                options={customers}
                                isLoading={isCustomersLoading}
                                value={customers && customers.find(c => c.value === values.owner_id)}
                                name="owner_id"
                                id="owner_id"
                                className="has-full-width"
                                onChange={(option) => setFieldValue("owner_id", option.value)}
                                styles={{
                                  control: base => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40
                                  })
                                }}
                              />
                              {errors.owner_id && touched.owner_id ? (
                                <span className="v-error">{errors.owner_id}</span>
                              ) : null}
                            </Grid>
                          )}
                        </Grid>
                      </div>

                      <div className="sep mb-18" />

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label>
                              <input
                                type="checkbox"
                                name="is_enterprise"
                                checked={values.is_enterprise}
                                onChange={e => setFieldValue('is_enterprise', e.target.checked)
                                }
                              />{' '}
                              Is this an Enterprise project?
                            </label>
                          </Grid>

                          <Grid item lg={4}>
                            <label htmlFor="name">Extra enterprise fees</label>
                            <input
                              type="number"
                              name="enterprise_fees"
                              value={values.enterprise_fees}
                              disabled={!values.is_enterprise}
                              onChange={e => setFieldValue('enterprise_fees', e.target.value)
                              }
                              className="stllr-input mb-10"
                              placeholder="Enter extra fees"
                            />
                            {errors.enterprise_fees && touched.enterprise_fees ? (
                              <span className="v-error">{errors.enterprise_fees}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="sep mb-18" />

                      {/* Project payment method/currency */}
                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Payment Method</label>
                            <Select
                              name='payment_method'
                              options={paymentMethods}
                              placeholder="Select payment method"
                              onChange={({ value }) => setFieldValue('payment_method', value)}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                            {errors.payment_method && touched.payment_method ? (
                              <span className="v-error">{errors.payment_method}</span>
                            ) : null}
                          </Grid>

                          <Grid item lg={3}>
                            <label htmlFor="name">Project Currency</label>
                            <Select
                              name='currency'
                              options={currencyOptions}
                              value={currencyOptions?.find?.(e => e.value === 3)}
                              isLoading={isCurrenciesLoading}
                              isDisabled={true}
                              placeholder="Select currency"
                              // onChange={({ value }) => {
                              //   setFieldValue('currency', value)
                              //   setCurrentCurrency(currencies.find(e => e.id === value))
                              // }}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                            {errors.currency ? (
                              <span className="v-error">{errors.currency}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Account Manager */}
                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Account Manager</label>
                            <Select
                              options={customers}
                              name="account_manager_id"
                              onChange={({ value }) => setFieldValue("account_manager_id", value)}
                            />
                            {errors.account_manager_id && touched.account_manager_id ? (
                              <span className="v-error">{errors.account_manager_id}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="sep mb-18" />

                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">Project Videos</h4>
                      </div>

                      {/* Project Videos */}
                      <Grid container direction="row" spacing={2}>
                        {/* <Grid item lg={2}>
                          <label htmlFor="name">Creator</label>
                        </Grid> */}
                        <Grid item lg={2}>
                          <label htmlFor="name">Video Type</label>
                        </Grid>
                        {/* <Grid item lg={2}>
                          Additional Attributes
                        </Grid> */}
                        <Grid item lg={2}>
                          <label htmlFor="name">Payout</label>
                        </Grid>
                        <Grid item lg={2}>
                          <label htmlFor="name">Price</label>
                        </Grid>
                        <Grid item lg={2}>
                          <label htmlFor="name">Actions</label>
                        </Grid>
                      </Grid>
                      <hr />
                      <FieldArray
                        name="videos"
                        render={helper => (
                          <div>
                            {values.videos && values.videos.length > 0 ? values.videos.map((video, index) => {
                              const fieldName = (name) => `videos.${index}.${name}`
                              const fieldErrors = errors.videos?.[index] || {}
                              const fieldTouched = touched.videos?.[index] || {}
                              const type = typeOptions.find(e => e.id = video.type_id)
                              const isSponsored = video?.type_id === 2 // second video type is customized (static line)
                              const isCustom = video?.type_id === 3 // third video type is customized (static line)

                              return (<>
                                <Grid container direction="row" spacing={2}>
                                  {/* <Grid item lg={2}>
                                    <Select
                                      options={expertOptions}
                                      isLoading={isExpertsLoading}
                                      name={`${fieldName('expert_id')}`}
                                      placeholder="Select creator"
                                      value={expertOptions.find(e => e.value === video.expert_id)}
                                      onChange={({ value }) => setFieldValue(fieldName('expert_id'), value)}
                                    />
                                    {fieldErrors.expert_id && fieldTouched.expert_id ? (
                                      <span className="v-error">{fieldErrors.expert_id}</span>
                                    ) : null}
                                  </Grid> */}
                                  <Grid item lg={2}>
                                    <Select
                                      options={typeOptions}
                                      isLoading={isVideoTypesLoading}
                                      name={`${fieldName('type_id')}`}
                                      placeholder="Select video type"
                                      value={typeOptions.find(e => e.value === video.type_id)}
                                      onChange={({ value }) => setFieldValue(fieldName('type_id'), value)}
                                    />
                                    {fieldErrors.type_id && fieldTouched.type_id ? (
                                      <span className="v-error">{fieldErrors.type_id}</span>
                                    ) : null}
                                  </Grid>
                                  {/* <Grid item lg={2}>
                                    {isCustom ? (<div>
                                      <Select
                                        options={genders}
                                        name={`${fieldName('gender')}`}
                                        placeholder="Select gender"
                                        value={typeOptions.find(e => e.value === video.gender)}
                                        onChange={({ value }) => setFieldValue(fieldName('gender'), value)}
                                        className='mb-10'
                                      />
                                      <Select
                                        options={ages}
                                        name={`${fieldName('age')}`}
                                        placeholder="Select age"
                                        value={typeOptions.find(e => e.value === video.age)}
                                        onChange={({ value }) => setFieldValue(fieldName('age'), value)}
                                        className='mb-10'
                                      />
                                      <Select
                                        options={places}
                                        name={`${fieldName('place')}`}
                                        placeholder="Select place"
                                        value={typeOptions.find(e => e.value === video.place)}
                                        onChange={({ value }) => setFieldValue(fieldName('place'), value)}
                                      />
                                    </div>) : isSponsored ? (<div>
                                      <Select
                                        options={genders}
                                        name={`${fieldName('gender')}`}
                                        placeholder="Select gender"
                                        value={typeOptions.find(e => e.value === video.gender)}
                                        onChange={({ value }) => setFieldValue(fieldName('gender'), value)}
                                      />
                                    </div>) : 'None'}
                                  </Grid> */}
                                  <Grid item lg={2}>
                                    {calculatePayout(video, index, values.videos)}
                                  </Grid>
                                  <Grid item lg={2}>
                                    {calculateCost(video, values.videos)}
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Button type="button" className="stllr-btn btn-sm" text="Remove" onClick={() => helper.remove(index)} />
                                  </Grid>
                                </Grid>
                                <hr />
                              </>)
                            }) : <div className="is-flex jcc">no videos added</div>}

                            <div className="is-flex jcfe mt-20">
                              <Button type="button" className="stllr-btn primary btn-md" text="Add video" onClick={() => helper.push(emptyVideoObject)} />
                            </div>
                          </div>
                        )}
                      />

                    </div>

                    <div className="sep mb-18" />
                    <div className="is-flex aic mt-0 mb-10">
                      <h4 className="mt-15 mr-15 dimmed">BRIEF DETAILS</h4>
                    </div>

                    <div className="group">
                      <Grid container direction="row" spacing={2}>
                        {/* <Grid item lg={3}>
                          <Select
                            name='platform'
                            options={platforms}
                            placeholder="Select platform"
                            onChange={({ value }) => setFieldValue('platform', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        <Grid item lg={3}>
                          <Select
                            name='niche'
                            options={niches}
                            placeholder="Select niche"
                            onChange={({ value }) => setFieldValue('niche', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <Select
                            name='product_delivery'
                            options={delivering}
                            placeholder="Select product delivery"
                            onChange={({ value }) => setFieldValue('product_delivery', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid>
                        {/* <Grid item lg={3}>
                          <Select
                            name='tone'
                            options={tones}
                            placeholder="Select tone of voice"
                            onChange={({ value }) => setFieldValue('tone', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        <Grid item lg={3}>
                          <Select
                            name='country'
                            options={countries}
                            placeholder="Select country"
                            onChange={({ value }) => setFieldValue('country', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid>
                        {/* <Grid item lg={3}>
                          <Select
                            name='language'
                            options={languages}
                            placeholder="Select language"
                            onChange={({ value }) => setFieldValue('language', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item lg={3}>
                          <Select
                            name='content_type'
                            options={contentTypes}
                            placeholder="Select content type"
                            onChange={({ value }) => setFieldValue('content_type', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item lg={3}>
                          <Select
                            name='video_format'
                            options={formats}
                            placeholder="Select video format"
                            onChange={({ value }) => setFieldValue('video_format', value)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item lg={12}>
                          <textarea
                            name="instructions"
                            onChange={handleChange}
                            className="stllr-input"
                            placeholder="Enter instructions for creators"
                          ></textarea>
                        </Grid>
                        <Grid item lg={12}>
                          <input
                            type="text"
                            name="reference"
                            onChange={handleChange}
                            className="stllr-input"
                            placeholder="Enter reference url"
                          />
                        </Grid> */}
                      </Grid>
                    </div>

                    <div className="sep mb-18" />

                    <input
                      type="submit"
                      className="stllr-btn primary btn-lg"
                      disabled={mutation.isLoading}
                      value="Create project"
                    />
                    {Object.keys(errors).length ? <span className="mt-10 v-error">Please check for wrong/missing fields [{Object.keys(errors).join(', ')}]</span> : ``}
                  </form>
                )
              }
            }
          </Formik>
        </Grid>
      </Container>
    </div>
  )
}

export default AddUGCProject