import React, { useState, useMemo, useCallback } from "react"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router";
import { fetchPageActivities, fetchUser } from "../services"
import { Grid, Container, Avatar } from "@material-ui/core"
import TableContainerServerSide from "../components/TableContainerServerSide"
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import moment from "moment";

function PageActivites() {
  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [pageCount, setPageCount] = useState(1)
  const [data, setData] = useState([])

  const columns = useMemo(() => [
    {
      accessor: 'name',
      Header: 'name',
      Cell: ({ row }) => {
        const { name, avatar } = row.original
        return <div className="is-flex fdr aic">
          <Avatar src={avatar} alt={name} className="mr-10" style={{ zIndex: -1 }} />
          <span>{name}</span>
        </div>
      },
      Filter: DefaultFilter,
    },
    {
      accessor: 'company_name',
      Header: 'Company',
      Filter: DefaultFilter
    },
    {
      accessor: 'user_type',
      Header: 'Role',
      Filter: SelectColumnFilter,
      FilterOptions: ['creator', 'brand'],
    },
    {
      accessor: 'url',
      Header: 'URL',
      Cell: ({ row }) => {
        const { url } = row.original
        if (!url) return ''
        // const regex = /^https?:\/\/[^#?\/]+/i
        // if (url.includes('/package') || url.includes('/packages') || url.includes('/profile') || url.includes('/experts')) {
        //   return <a href={`${url.replace(regex, 'https://www.stllr.network').replace(/package(s?)/gi, 'discover').replace('profile', 'expert')}`}>{url.replace(regex, '')}</a>
        // } else {
        //   // const [domain] = url?.match(regex)
        //   return url.replace(regex, '')
        // }
        if (url.includes('/settings') || url.includes('/projects')) {
          return url
        } else {
          return <a href={`${url}`}>{url}</a>
        }
      },
      Filter: DefaultFilter
    },
    {
      accessor: 'date_add',
      Header: 'date',
      Cell: ({ row }) => {
        const { date_add } = row.original
        return moment(date_add).format('lll')
      },
      Filter: DefaultFilter,
      FilterPlaceholder: 'YYYY-MM-DD'
    },
  ]);

  const { mutate: fetch, isLoading } = useMutation(params => fetchPageActivities(params), {
    onSuccess: ({ activities }) => {
      console.log(activities)
      setData(activities.data || [])
      setTotal(activities.total)
      if (activities.pageCount !== pageCount) {
        setPageCount(activities.pageCount)
      }
    }
  })

  const handleRefetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      fetch({
        pageIndex,
        pageSize,
        search,
      })
    },
    []
  )

  return (
    <Container spacing={1} maxWidth='xl'>
      <Grid item lg={12}>
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Page activities (<i>{total}</i>)</h2>
        </div>

        <TableContainerServerSide
          name='activities'
          paging={true}
          columns={columns}
          hiddenColumns={[]}
          data={data}
          hasFilters={true}
          loading={isLoading}
          pageCount={pageCount}
          fetchData={handleRefetchData}
        />
      </Grid>
    </Container>
  )
}

export default PageActivites