import React, { useState, useMemo, useEffect, useCallback } from "react"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router";
import { Button } from "../components/button";
import { fetchMarketeerProjects, fetchUser } from "../services"
import { Grid, Container, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core"
import TableContainerServerSide from "../components/TableContainerServerSide"
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import slugify from 'slugify';
import moment from "moment";
import { RatingModal } from "../components/RatingModal";
import { useAuth } from "../utils/Auth";

function MarketeerProjects () {
    const history = useHistory()
    const { user } = useAuth()
    const [reviewData, setReviewData] = useState(null)
    const [total, setTotal] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [projects, setProjects] = useState([])
  
    // const { data: user, isLoading: userLoading } = useQuery("user", fetchUser, {
    //   select: (data) => data.data,
    // })

    const columns = useMemo(() => [
      {
        accessor: 'company_name',
        Header: 'Company',
        Filter: DefaultFilter
      },
      // {
      //   accessor: 'name',
      //   Header: 'Project name',
      //   Filter: DefaultFilter,
      // },
      {
        accessor: 'project_status',
        Header: 'Status',
        Cell: ({ row }) => {
          const { status_id, project_status } = row.original
          let statusClass = ``

          switch (Number(status_id)) {
            case Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT):
              statusClass=`-red`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PENDING_BRIEF):
              statusClass=`-orange`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PENDING_MILESTONES):
              statusClass=`-blue`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING):
              statusClass=`-gold`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PAUSED_BY_CUSTOMER):
              statusClass=`-purple`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_PAUSED):
              statusClass=`-yellow`
              break;

            case Number(process.env.REACT_APP_PROJECT_STATUS_CANCELLED):
              statusClass=`-grey`
              break;

            default:
              statusClass=`-green`
          }

          return <Chip size="small" className={`chip${statusClass}`} label={project_status} />
        },
        Filter: SelectColumnFilter,
        FilterOptions: [
          'Awaiting payment',
          'Pending Brief',
          'Pending milestones',
          'Ongoing',
          'Paused by Customer',
          'Paused',
          'Cancelled',
          'Finished',
          'Payment Failed',
          'Expert Replaced',
          'Pending Offer',
          'Offer Rejected',
          'Offer Submitted',
        ]
      },
      {
        accessor: `duration`,
        Header: `Duration`,
        Filter: DefaultFilter,        
        Cell: ({ row }) => {
          const { duration } = row.original
          return <span>{duration} Months</span>
        }
      },
      {
        Header: 'Consumed',
        accessor: "consumed_duration",
        disableFilters: true,
        Cell: ({ row }) => {
          const { consumed_duration_days } = row.original
          return <span>{(consumed_duration_days / 30).toFixed(2)} Months</span>
        }
      },
      {
        accessor: 'cost',
        Header: 'Cost',
        disableFilters: true
      },
      {
        accessor: 'n_experts',
        Header: '#Experts',
        disableFilters: true,
        Cell: ({ row }) => {
          const { experts } = row.original
          return <span>{experts.length}</span>
        }
      },
      {
        accessor: 'type',
        Header: 'Type',
        disableFilters: true,
        Cell: ({ row }) => {
          const { creator_role_id, name } = row.original
          // console.log(creator_role_id + " - " + name)
          return <span>{creator_role_id == process.env.REACT_APP_ADMIN_ROLE_ID ? `Team` : `Self-Checkout`}</span>
        }
      },
      {
        accessor: 'commission',
        Header: 'Comm.%',
        disableFilters: true
      },
      {
        Header: 'Created date',
        accessor: "date_add",
        Filter: DefaultFilter,
        FilterPlacehodler: 'YYYY-MM-DD',
        Cell: ({ row }) => {
          const { date_add } = row.original
          return moment(date_add).format('ll')
        }
      },
      {
        Header: '1st Payment',
        accessor: "date_ongoing",
        disableFilters: true,
        Cell: ({ row }) => {
          const { date_ongoing } = row.original
          return date_ongoing && moment(date_ongoing).format('ll')
        }
      },
      {
        accessor: 'price',
        Header: 'Price',
        disableFilters: true
      },
      {
        accessor: `k_status`,
        Header: `K Status`,
        Filter: DefaultFilter,
        Cell: ({ row }) => {
          const { k_status } = row.original
          return <span>{k_status}</span>
        }
      },
      {
        accessor: `k_description`,
        Header: `K Description`,
        Filter: DefaultFilter,
        Cell: ({ row }) => {
          const { k_description } = row.original
          return <span>{k_description}</span>
        }
      },
    //   {
    //     Header: "Actions",
    //     Cell: ({ row }) => {
    //       const { id, owner_id, status_id, experts } = row.original
    //       console.log(row.original)
    //       return user?.email == process.env.REACT_APP_FINANCE_ADMIN_EMAIL ? (
    //         <div className="is-flex aic">
    //           <button onClick={() => window.location.href = `/project-payments/${id}`} className="stllr-btn btn-sm primary bordered ml-10">PAYMENTS</button>
    //         </div>            
    //       ) : (<div className="is-flex aic">

    //         {/* RATE EXPERTS BUTTON */}
    //         {status_id == process.env.REACT_APP_PROJECT_STATUS_FINISHED && experts.some(e => !e.is_reviewed) && <button
    //             className="stllr-btn btn-sm primary bordered mr-10"
    //             onClick={() => {
    //               const data = {
    //                 projectId: id,
    //                 experts: experts.filter(e => !e.is_reviewed).map(e => {
    //                   return { name: e.expert_name, id: e.expert_id, avatar: e.expert_avatar }
    //                 })
    //               }
    //               setReviewData(data)
    //             }}
    //         >Rate Experts</button>}

    //         <button onClick={() => window.location.href = `/edit-project/${id}`} className="stllr-btn btn-sm primary bordered">EDIT</button>
    //         <button onClick={() => window.location.href = `/company-brief/${owner_id}`} className="stllr-btn btn-sm primary bordered ml-10">BRIEF</button>
    //         <button onClick={() => window.location.href = `/project-history/${id}`} className="stllr-btn btn-sm primary bordered ml-10">HISTORY</button>
    //         <button onClick={() => window.location.href = `/chats/${slugify(row.original.name || row.original.company_name)}/${id}`} className="stllr-btn btn-sm primary bordered ml-10">CHAT</button>
    //         <a href={`${process.env.REACT_APP_STLLR_URL}project/${slugify(row.original.name || row.original.company_name)}/${id}`} className="stllr-btn btn-sm primary bordered ml-10" target="_blank" rel="noopener noreferrer">VIEW</a>
    //         {user.email === process.env.REACT_APP_ADMIN_EMAIL || (user.email === process.env.REACT_APP_AMIRA_EMAIL) ? <button onClick={() => window.location.href = `/pulses/${id}`} className="stllr-btn btn-sm primary bordered ml-10">Pulses</button> : null}
    //       </div>
    //       )
    //     }
    // },
  ]);
  const [open, setOpen] = useState(true)
  // const { data: projects, isLoading, refetch } = useQuery("projects", () => fetchMarketeerProjects(null), {
  //   select: ({ projects }) => projects,
  //   enabled: false
  // })
  const { mutate: fetch, isLoading } = useMutation(params => fetchMarketeerProjects(params), {
    onSuccess: ({ projects }) => {
      setProjects(projects.data || [])
      setTotal(projects.total)
      if (projects.pageCount !== pageCount) {
        setPageCount(projects.pageCount)
      } 
    }
  })
  
  const handleRefetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      fetch({
        pageIndex,
        pageSize,
        search,
      })
    },
    []
  )

  // useEffect(() => {
  //   console.log(user)
  //   if (user && user.email) {
  //     refetch()
  //   }
  // }, [user])

  // if (userLoading) return <div>Loading....</div>

  return (
    <Container spacing={1} maxWidth='xl'>
      <Grid item lg={12}>
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Projects (<i>{total}</i>)</h2>
          {/* {user?.email != process.env.REACT_APP_FINANCE_ADMIN_EMAIL && <a className="stllr-btn primary btn-lg" href="/new-project">Create project</a>} */}
        </div>

        <TableContainerServerSide
          paging={true}
          columns={columns}
          hiddenColumns={user.email === process.env.REACT_APP_ADMIN_EMAIL ? [""] : ["k_status", "k_description"]}
          data={projects}
          hasFilters={true}
          loading={isLoading}
          pageCount={pageCount}
          fetchData={handleRefetchData}
        />
      </Grid>

      <RatingModal
        data={reviewData}
        isOpen={Boolean(reviewData)}
        onClose={() => setReviewData(null)}
      />

    </Container>
  )
}

export default MarketeerProjects