import axios from "axios"
import Cookies from "js-cookie"
import { getToken } from "../utils"
const baseUrl = process.env.REACT_APP_STLLR_API_URL

var client = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
})

client.interceptors.response.use(response => {
    return response
}, (error) => {
    if (401 === error.response.status) {
        Cookies.remove("token")
        window.location.href = `/login`;
    }
    else return Promise.reject(error)
})

export const request = async (options, isAuth=false) => {
    const onSuccess = (response) => {
        return response.data
    }

    const onError = (error) => {
        if (undefined !== error.response && error.response.status === 401) {
            Cookies.remove("token")
            window.location.href = `/login`;
        }
        return Promise.reject(error.response || error.message);
    }

    if (isAuth) {
        options.headers = {
            "Authorization": `Bearer ${getToken()}`
        }
    }

    return client(options).then(onSuccess).catch(onError)
}